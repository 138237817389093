/* Типы */
import { NavigationGuard, RouteLocationNormalized } from 'vue-router'

/* Хранилище */
import { UserStore } from '@/store/user'

/* Типы */
import { RoutePaths, RouterItemMeta } from '@/router/Types'

/* Помощники */
import { SetLayoutParamsAccessWithoutAuth } from '@/middlewares/helpers/Auth'

/** Промежуточное ПО авторизации */
export const AuthMiddleware: NavigationGuard = (to: RouteLocationNormalized, from, next) => {
  const userState = UserStore()
  const { token, isAuth, isValidDateToken } = userState
  const isStartRouterPath = to.path === RoutePaths.index
    || to.path === RoutePaths.quiz
    || to.path === RoutePaths.login
  const meta = to.meta as RouterItemMeta

  if (isAuth()) {
    if (isStartRouterPath) {
      next({ path: RoutePaths.main })
      return
    }

    next()
  }

  if (meta.isSkipAuth && !isAuth()) {
    SetLayoutParamsAccessWithoutAuth(meta)
    next()
    return
  }

  if (Boolean(token) && !isValidDateToken() && to.path !== RoutePaths.login) {
    if (isStartRouterPath) {
      next()
      return
    }

    next({ path: RoutePaths.login })
  } else if (!isAuth()) {
    if (isStartRouterPath) {
      next()
      return
    }

    next({ path: RoutePaths.login })
  }
}
