/* Функции */
import {
  defineComponent, HTMLAttributes, PropType, VNode,
} from 'vue'

/* Компоненты */
import VTextHeadingPagePromo from '@/components/VText/VTextHeadingPagePromo/VTextHeadingPagePromo'
import VPage from '@/components/VPage/VPage'
import VContent from '@/components/VContent/VContent'
import VContentItem from '@/components/VContent/VContentItem/VContentItem'
import VIcon from '@/components/VIcon/VIcon'

/* Стили */
import '@/components/BasePageAction/BasePageAction.scss'

export default defineComponent({
  name: 'BasePageAction',

  data() {
    return {
      className: 'page-action-include',
    }
  },

  props: {
    /** Цвет фона */
    color: {
      type: String as PropType<string>,
      default: '#48DF80',
    },
    /** Заголовок */
    title: {
      type: String as PropType<string>,
      default: '',
    },
    /** Смещение градиента на фоне в градусах */
    backgroundDeg: {
      type: Number as PropType<number>,
      default: 180,
    },
    /** Заполнение градиента на фоне в процентах */
    backgroundPercent: {
      type: Number as PropType<number>,
      default: 25,
    },
    /** Отображать ли кнопку закрытия */
    isCloseButton: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    /** Показывать ли контент */
    isShow: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    /** Клик  */
    onClick: {
      type: Function as PropType<HTMLAttributes['onClick']>,
    },
  },

  provide() {
    return {
      colorValue: this.color,
    }
  },

  computed: {
    styles() {
      return {
        background: `linear-gradient(${this.backgroundDeg}deg, ${this.color} 0%, rgba(255,255,255,0) ${this.backgroundPercent}%)`,
      }
    },
  },

  render(): VNode | null {
    return (
      this.isShow
        ? <VPage class={this.className} style={this.styles}>
          {
            this.isCloseButton
              ? <VIcon
                onClick={this.onClick}
                class={'page-action-include__close'}
                imageName={'close'}
              />
              : null
          }

          <VContent>
            <VContentItem>
              <VTextHeadingPagePromo>{this.title}</VTextHeadingPagePromo>
            </VContentItem>

            <VContentItem>
              {this.$slots.default?.()}
            </VContentItem>
          </VContent>
        </VPage> : null
    )
  },
})
