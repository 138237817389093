/* Функции */
import { defineComponent, VNode } from 'vue'

/* Компоненты */
import VFullScreen from '@/components/VFullScreen/VFullScreen'
import VContent from '@/components/VContent/VContent'
import VLogo from '@/components/VLogo/VLogo'
import VRow from '@/components/VRow/VRow'
import VButton from '@/components/VButton/VButton'
import VTextHeading1 from '@/components/VText/VTextHeading1/VTextHeading1'
import VTextParagraph from '@/components/VText/VTextParagraph/VTextParagraph'
import VTextSmall from '@/components/VText/VTextSmall/VTextSmall'
import VSteps from '@/components/VSteps/VSteps'
import VTransition from '@/components/VTransition/VTransition'
import VProgressLine from '@/components/VProgressLine/VProgressLine'

/* Типы */
import { RoutePaths } from '@/router/Types'
import { Data } from './Types'

/* Стили */
import '@/views/Start/Start.scss'

export default defineComponent({
  name: 'StartView',

  data(): Data {
    return {
      currentStep: 0,
    }
  },

  render(): VNode {
    const stepContent = (node: VNode | VNode[], step: number) => (
      <VFullScreen imageName={`start_${step}`} key={`full-screen-${this.currentStep}`}>
        <VContent>
          {{
            top: () => [
              <VProgressLine
                class={'start_progress'}
                time={5000}
                onTimeOut={() => {
                  if (step === 3) {
                    this.$router.push(RoutePaths.quiz)
                  } else {
                    this.currentStep = step
                  }
                }}
              />,

              <VRow justify={'center'}><VLogo /></VRow>,
            ],
            bottom: () => <div class={'start-footer'}>
              <div>
                {node}
              </div>

              <div>
                <VButton
                  isSecondary
                  onClick={() => {
                    if (step === 3) {
                      this.$router.push(RoutePaths.quiz)
                    } else {
                      this.currentStep = step
                    }
                  }}
                >
                  {step === 3 ? 'Начать' : 'Продолжить'}
                </VButton>

                <VRow justify={'center'}>
                  <VTextSmall
                    onClick={() => {
                      this.$router.push(RoutePaths.login)
                    }}
                    cursor={'pointer'}
                  >
                    {'У меня уже есть учетная запись'}
                  </VTextSmall>
                </VRow>
              </div>
            </div>,
          }}
        </VContent>
      </VFullScreen>
    )

    const steps = () => {
      switch (this.currentStep) {
        case 0:
          return stepContent(
            <div class="step" key="step-1">
              <VTextHeading1>{'Получи лучший опыт из мира спорта'}</VTextHeading1>

              <VTextParagraph>{'Мы взяли за основу методики тренировок профессиональных спортсменов, адаптировали их для домашних условий'}</VTextParagraph>
            </div>,
            1,
          )
        case 1:
          return stepContent(
            <div class="step" key="step-2">
              <VTextHeading1>{'Адаптируй тренировочный план под себя'}</VTextHeading1>

              <VTextParagraph>{'Скорректируй план тренировок на день, неделю или месяц под свой темп жизни'}</VTextParagraph>
            </div>,

            2,
          )
        case 2:
          return stepContent(
            <div class="step" key="step-3">
              <VTextHeading1>{'Для начала давайте познакомимся'}</VTextHeading1>

              <VTextParagraph>{'BodyBuddy - это приложение, которое составит профессиональные домашние тренировки в единой системе специально для тебя!'}</VTextParagraph>
            </div>,
            3,
          )
      }

      return null
    }

    return (
      <VSteps
        modelValue={this.currentStep}
        onUpdate:modelValue={(step) => { this.currentStep = step }}
      >
        <VTransition>
          {steps()}
        </VTransition>
      </VSteps>
    )
  },
})
