export enum BannerMode {
  default = 'default',
  top = 'top'
}

export enum ShowOn {
  mobile = 'mobile',
  desktop = 'desktop'
}

export enum BannerBackgroundColor {
  primary = '#334155',
  secondary = 'linear-gradient(90deg, #22C55E 0%, #16A34A 100%)'
}

export interface Data {
  isMobile: boolean
}

/** Размер окна браузера для десктопа (Аналогичен переменной scss $media-point-desktop-2) */
export const WindowSizeDesktop = 900
