/* Функции */
import { defineComponent, VNode } from 'vue'

/* Компоненты */
import VPage from '@/components/VPage/VPage'
import BaseWorkoutHeader from '@/components/BaseWorkoutHeader/BaseWorkoutHeader'
import BaseWorkoutBlock from '@/components/BaseWorkoutBlock/BaseWorkoutBlock'
import VContentItem from '@/components/VContent/VContentItem/VContentItem'
import BaseWorkoutStep from '@/components/BaseWorkoutStep/BaseWorkoutStep'
import VButtonFixes from '@/components/VButton/VButtonIcon/VButtonFixes/VButtonFixes'
import VIcon from '@/components/VIcon/VIcon'
import VRow from '@/components/VRow/VRow'
import VTextButton from '@/components/VText/VTextButton/VTextButton'

/* Стили */
import '@/views/Workout/Workout.scss'

/* Типы */
import { RequestAPI } from '@/classes/Request'
import {
  AddPaymentResponse, SubscriptionResponse, WorkoutDetail, WorkoutStep,
} from '@/types/API'
import { Data } from '@/views/Workout/Types'
import { RoutePaths } from '@/router/Types'

/* Помощник */
import { getDurationPeriod } from '@/helpers/common'

/* Хранилище */
import { LayoutStore } from '@/store/layout'
import { UserStore } from '@/store/user'

export default defineComponent({
  name: 'WorkoutDetailView',

  data(): Data {
    return {
      id: null,
      workoutData: null,
      isLoading: true,
      isBlocked: false,
    }
  },

  computed: {
    /** Инвентарь */
    equipments(): string[] {
      return this.workoutData?.equipment_items.reduce<string[]>((equipments, equipment) => {
        if (equipment.title) {
          equipments.push(equipment.title)
        }
        return equipments
      }, []) ?? []
    },
    /** Картинки при неудачной загрузке */
    failRandomImage() {
      return `target_default_${Math.floor(Math.random() * 3) + 1}.png`
    },
  },

  methods: {
    /**
     * Получение данных по тренировки
     * @param id - идентификатор тренировки
     * @returns данные
     */
    async getDataWorkout(id: string, onError?: (error: unknown) => void) {
      try {
        const data = await RequestAPI.get<WorkoutDetail>(`/workouts/${id}`)

        if (data) {
          return data.data.content
        }
      } catch (error) {
        if (typeof onError === 'function') {
          onError(error)
        } else {
          console.error(error)
        }
      }
      return null
    },

    /** Получение подписок */
    async getSubscriptions() {
      const response = await RequestAPI.errorHandler<SubscriptionResponse>(RequestAPI.get, '/subscriptions')

      if (response) {
        const { data } = response
        return data.content.subscriptions
      }

      return []
    },

    /** Добавление метода платежного */
    async addNewPaymentMethod() {
      const response = await RequestAPI.errorHandler<AddPaymentResponse>(RequestAPI.post, '/payments/saved_methods')

      if (!response) {
        return
      }

      const { data } = response

      if (data.content.payment_link) {
        window.location.href = data.content.payment_link
      }
    },

    /** Метод нажатия на кнопку */
    async onClickButton() {
      if (this.isBlocked) {
        const subscriptions = await this.getSubscriptions()

        if (!subscriptions.length) {
          await this.addNewPaymentMethod()
        } else {
          this.$router.push({ path: RoutePaths.subscriptions })
        }
      } else {
        window.location.href = `https://app.b-b.fit/v3/workouts/${this.id}?autostart=1`
      }
    },
  },

  async created() {
    LayoutStore().setLoading(true)
    this.id = Array.isArray(this.$route.params.id) ? this.$route.params.id[0] : this.$route.params.id
    this.workoutData = await this.getDataWorkout(this.id, () => {
      this.$router.push(RoutePaths.login)
    })
    this.isBlocked = this.workoutData?.state === 'blocked'
    LayoutStore().setLoading(false)
  },

  render(): VNode {
    const getSteps = (steps: WorkoutStep[]) => steps.reduce<VNode[]>((steps, step) => {
      if (step.type === 'exercise' && step.title) {
        const caption = step.caption ?? ''
        const duration = typeof step.duration === 'number' ? `${step.duration} ${this.$t('pages.WorkoutDetail.postfixDurationStep')}` : caption

        steps.push(<BaseWorkoutStep
          src={step.thumb_image_url ?? ''}
          title={step.title}
          duration={duration}
        />)
      }

      return steps
    }, []) ?? []

    return (
      <VPage class={'workout-detail'} key={'WorkoutDetailView'}>
        {
          this.workoutData ? [
            <BaseWorkoutHeader
              src={this.workoutData?.image ?? ''}
              failLoadSrc={this.failRandomImage}
              title={this.workoutData?.title ?? ''}
              subTitle={this.workoutData?.tags.join(', ') ?? ''}
              duration={this.workoutData?.duration ?? 0}
              key={`target-detail-${this.workoutData?.id}`}
              equipment={this.equipments}
              height={468}
              description={this.workoutData?.description ?? ''}
              postfixDuration={this.$t('pages.WorkoutDetail.postfixDuration')}
              isShowBackButton={!UserStore().isAuth()}
            />,

            <VContentItem isPaddingX={true} isPaddingY={false} margin={'0'}>
              {this.workoutData?.blocks.map((workout) => {
                const durationMin = workout.duration_min ? Math.ceil(workout.duration_min / 60) : 0
                const durationMax = workout.duration_max ? Math.ceil(workout.duration_max / 60) : 0
                const duration = getDurationPeriod(durationMin, durationMax)

                return (
                  <BaseWorkoutBlock
                    title={workout.title ?? ''}
                    duration={duration}
                    durationPostfixText={this.$t('pages.WorkoutDetail.postfixDuration')}
                  >
                    {getSteps(workout.steps)}
                  </BaseWorkoutBlock>
                )
              })}
            </VContentItem>,

            <VButtonFixes
              onClick={this.onClickButton}
              imageName={this.isBlocked ? 'pro' : ''}
              isFivefold={this.isBlocked}
            >
              <VRow alignItems={'center'}>
                <VTextButton>{
                  this.isBlocked
                    ? this.$t('pages.WorkoutDetail.buttonPlan', { label: '' })
                    : this.$t('pages.WorkoutDetail.button')
                }</VTextButton>

                {this.isBlocked ? null : <VIcon imageName={'play'} />}
              </VRow>
            </VButtonFixes>,
          ] : null
        }
      </VPage>
    )
  },
})
