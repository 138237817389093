/* Функции */
import { defineComponent, VNode } from 'vue'

/* Компоненты */
import VTopBackground from '@/components/VTopBackground/VTopBackground'
import TheAuth from '@/components/TheAuth/TheAuth'
import VRow from '@/components/VRow/VRow'
import VTextHeading1 from '@/components/VText/VTextHeading1/VTextHeading1'
import VTextInput from '@/components/VInput/VTextInput/VTextInput'
import VFullScreen from '@/components/VFullScreen/VFullScreen'
import VContent from '@/components/VContent/VContent'
import VButton from '@/components/VButton/VButton'
import VTextSmall from '@/components/VText/VTextSmall/VTextSmall'
import BaseAuthModeToggle from '@/components/BaseAuthModeToggle/BaseAuthModeToggle'

/* Стили */
import '@/views/SignUp/SignUp.scss'

/* Типы */
import { RoutePaths } from '@/router/Types'
import { RequestAPI } from '@/classes/Request'
import { Data, ResponseSingUp } from '@/views/SignUp/Types'
import { AuthMode } from '@/components/TheAuth/Types'
import { MaskPhone } from '@/types/constants/common'

/* Функции */
import { validEmail, validPhone } from '@/components/Validation/Rules'

/* Хранилище */
import { UserStore } from '@/store/user'

export default defineComponent({
  name: 'SignUpView',

  data(): Data {
    return {
      /** Показ кода авторизации */
      isShowCodeModal: false,
      /** Имя */
      name: '',
      /** Электронная почта */
      email: '',
      /** Телефон */
      phone: '',
      /** Маршрут после авторизации */
      afterAuthRoutePath: RoutePaths.index,
      /** Режим входа */
      authMode: AuthMode.phone,
    }
  },

  methods: {
    /** Регистрация */
    async registration() {
      const userState = UserStore()

      const preparationData: { referrer: string, email?: string, phone?: string, name: string } = {
        referrer: userState.referrer ?? '',
        name: this.name,
      }

      switch (this.authMode) {
        case AuthMode.email:
          preparationData.email = this.email
          break
        case AuthMode.phone:
          preparationData.phone = this.phone
      }

      try {
        const response = await RequestAPI.errorHandler<ResponseSingUp>(RequestAPI.post, '/athletes', preparationData)

        if (response?.data?.response.status === 'ok') {
          this.afterAuthRoutePath = response.data.content.start_page_path
          this.isShowCodeModal = true
        }
      } catch (error) {
        console.error(error)
      }
    },
  },

  computed: {
    /** Признак блокировки кнопки */
    isDisabledButton(): boolean {
      if (!this.name) {
        return true
      }

      switch (this.authMode) {
        case AuthMode.email:
          return !(Boolean(this.email) && Boolean(validEmail()(this.email)))

        case AuthMode.phone:
          return !(Boolean(this.phone) && Boolean(validPhone()(this.phone)))
      }

      return true
    },
  },

  render(): VNode {
    const content = () => (
      <div key={'registration'} class={'registration'}>
        <VTopBackground isShowDesktopImg={true} imageName={'registration'} zIndex={0} />

        <VRow>
          <VTextHeading1>{this.$t('registration.title')}</VTextHeading1>
        </VRow>

        <BaseAuthModeToggle
          phoneText={this.$t('login.toggle.phone')}
          emailText={this.$t('login.toggle.email')}
          onUpdate:modelValue={(value) => { this.authMode = value }}
        />

        <VRow>
          <VTextInput
            label={this.$t('registration.fields.name.label')}
            autofocus={true}
            onUpdate:modelValue={(value) => { this.name = value }}
            onEnter={() => {
              if (!this.isDisabledButton) { this.registration() }
            }}
            modelValue={this.name}
          />
        </VRow>

        <VRow>

          {this.authMode === AuthMode.phone
            ? <VTextInput
              key={'phone'}
              subtextAlign={'left'}
              label={this.$t('login.fields.phone.label')}
              onUpdate:modelValue={(value) => { this.phone = value }}
              modelValue={this.phone}
              mask={MaskPhone}
              onEnter={() => {
                if (!this.isDisabledButton) { this.registration() }
              }}
              subtext={this.$t('login.fields.phone.subtext')}
            />
            : <VTextInput
              key={'email'}
              subtextAlign={'left'}
              label={this.$t('login.fields.email.label')}
              onUpdate:modelValue={(value) => { this.email = value }}
              modelValue={this.email}
              onEnter={() => {
                if (!this.isDisabledButton) { this.registration() }
              }}
              subtext={this.$t('login.fields.email.subtext')}
            />
          }
        </VRow>
      </div>
    )

    return (
      <VFullScreen class={'registration-view'}>
        <TheAuth
          isSecondaryModal={true}
          isShow={this.isShowCodeModal}
          onOnClose={(value) => { this.isShowCodeModal = value }}
          afterAuthRoutePath={this.afterAuthRoutePath}
          authMode={this.authMode}
          email={this.email}
          phone={this.phone}
        />

        <VContent>
          {{
            top: () => [
              content(),
            ],

            bottom: () => [
              <VButton
                isDisabled={this.isDisabledButton}
                onClick={this.registration}
              >
                {this.$t('login.buttons.sendLink')}
              </VButton>,

              <VRow justify={'center'}>
                <VTextSmall
                  onClick={() => {
                    this.$router.push(RoutePaths.login)
                  }}
                  cursor={'pointer'}
                >
                  {this.$t('registration.login')}
                </VTextSmall>
              </VRow>,
            ],
          }}
        </VContent>
      </VFullScreen>
    )
  },
})
