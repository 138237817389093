/* Функции */
import { defineComponent, PropType, VNode } from 'vue'

/* Компоненты */

/* Стили */
import '@/components/VFullScreen/VFullScreen.scss'

export default defineComponent({
  name: 'VFullScreen',

  props: {
    /** Фоновая картинка */
    backgroundImage: {
      type: String as PropType<string>,
      default: '',
    },

    /** Признак является ли картинка локальной */
    isLocal: {
      type: Boolean as PropType<boolean>,
      default: true,
    },

    /** Имя файла */
    imageName: {
      type: String as PropType<string>,
      default: '',
    },
  },

  computed: {
    /** Путь к картинке */
    imageSrc(): string {
      if (this.isLocal) {
        try {
          return require(`@/assets/img/pictures/${this.imageName}.png`)
        } catch (error) {
          return ''
        }
      } else {
        return this.imageName
      }
    },
  },

  render(): VNode {
    const styles = {
      'background-image': `url(${this.imageSrc})`,
    }

    return (
      <div class={'full-screen'} style={styles}>
        {this.$slots.default?.()}
      </div>
    )
  },
})
