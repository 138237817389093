import { NavigationGuard, RouteRecordRaw } from 'vue-router'
import { LayoutValues, PersonalAccountParams } from '@/layouts'
import { RoutePathsProfile } from '@/views/Profile/Types'

/* Middleware */
export type Middleware = NavigationGuard[] | readonly NavigationGuard[]

export type RouterItemMeta = {
  /* Промежуточное ПО */
  middleware: Middleware,
  /* Шаблон */
  layout: LayoutValues
  /* Параметры шаблона */
  layoutParams?: PersonalAccountParams
  /* Параметр пропуска авторизации. Важно помнить, что на этой странице нужно сделать обработку авторизации */
  isSkipAuth?: boolean
  /** Показывать индикатор загрузки */
  isShowPreloader?: boolean
  /** Заголовок страницы */
  pageTitle?: string
} & Record<string, unknown>

/**
 * Пути маршрутов.
 * Тут используются маршруты для импортов в другие файлы.
 */
export enum RoutePaths {
  /* Стартовая страницы */
  index = '/',
  /* Вход */
  login = '/login',
  /* Анкета */
  quiz = '/quiz',
  /* Главная */
  main = '/main',
  /* Планы */
  goals = '/goals',
  /* Избранное */
  like = '/like',
  /* Профиль */
  profile = '/profile',
  /* План детальная */
  goalsDetail = '/goals/:id',
  /* Тренировка детальная */
  workoutDetail = '/workouts/:id',
  /* Планы пользователя */
  myGoals = '/my-goals',
  /* План пользователя */
  myGoalsDetail = '/my-goals/:id',
  /* План пользователя завершение */
  myGoalsDetailClose = '/my-goals/close/:id',
  /* Выбор плана */
  plans = '/plans/:id',
  /* Подписки */
  subscriptions = '/subscriptions',
  /* Способы оплаты */
  paymentMethods = '/payments/payment_methods',
  /* Страница проверки оплаты */
  afterPayments = '/payments/after_payments',
  /* Регистрация без анкеты */
  signUp = '/signup',
}

/** Элемент маршрутизатора */
export type RouterItem = RouteRecordRaw & {
  meta: RouterItemMeta
  children?: RouterItem[]
  path: RoutePaths | RoutePathsProfile
}
