import { LayoutValues, Layouts, PersonalAccountParams } from '@/layouts'
import { defineStore } from 'pinia'

export interface LayoutState {
  /* Имя шаблона */
  name: LayoutValues,
  layoutParams: PersonalAccountParams | null,
  isLoading: boolean
}

export const LayoutStore = defineStore('layout', {
  state: (): LayoutState => ({
    name: Layouts.Default,
    layoutParams: null,
    isLoading: false,
  }),

  actions: {
    setLayout(name: LayoutValues) {
      this.name = name
    },

    setLayoutParams(params: PersonalAccountParams | null) {
      this.layoutParams = params
    },

    setLoading(status: boolean) {
      this.isLoading = status
    },
  },
})
