/* Функции */
import { defineComponent, VNode } from 'vue'

/* Компоненты */
import VModal from '@/components/VModal/VModal'
import VButton from '@/components/VButton/VButton'
import VRow from '@/components/VRow/VRow'
import VText from '@/components/VText/VText/VText'

/* Стили */
import '@/components/TheUpdateModalPWA/TheUpdateModalPWA.scss'

/* Типы */
import { Data } from './Types'

export default defineComponent({
  name: 'TheUpdateModalPWA',

  data(): Data {
    return {
      refreshing: false,
      registration: null,
      updateExists: false,
    }
  },

  methods: {
    updateAvailable(event: CustomEvent<ServiceWorkerRegistration>) {
      this.registration = event.detail ?? null

      if (('serviceWorker' in navigator && window.navigator.standalone) || window.matchMedia('(display-mode: standalone)').matches) {
        this.updateExists = true
      }
    },

    refreshApp() {
      this.updateExists = false

      if (!this.registration || !this.registration.waiting) {
        return
      }

      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
    },
  },

  created() {
    document?.addEventListener('swUpdated', this.updateAvailable)

    navigator.serviceWorker?.addEventListener('controllerchange', () => {
      if (this.refreshing) {
        return
      }

      window.location.reload()
    })
  },

  render(): VNode | null {
    return (
      this.updateExists ? <VModal
        isSecondary={true}
        isShow={this.updateExists}
        onOnClose={() => { this.updateExists = false }}
      >
        <VRow>
          <VText>{this.$t('common.TheUpdateModalPWA.title')}</VText>
        </VRow>

        <VButton
          onClick={this.refreshApp}
        >
          {this.$t('common.TheUpdateModalPWA.buttons.success')}
        </VButton>

        <VButton
          isSecondary={true}
          onClick={() => { this.updateExists = false }}
        >
          {this.$t('common.TheUpdateModalPWA.buttons.cancel')}
        </VButton>
      </VModal> : null
    )
  },
})
