/* Функции */
import { defineComponent, PropType, VNode } from 'vue'

/* Компоненты */
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Navigation } from 'swiper/modules'

/* Стили */
import 'swiper/css'
import '@/components/VSlider/VSlider.scss'

export default defineComponent({
  name: 'VSlider',

  emits: {
    slideChange: (value: typeof Swiper) => typeof value === 'object',
  },

  props: {
    widthSlide: {
      type: [String, null] as PropType<string | null>,
      default: null,
    },
    spaceBetween: {
      type: Number as PropType<number>,
      default: 12,
    },

  },

  render(): VNode {
    const children = this.$slots.default?.().map(((slot) => (
      <SwiperSlide
        style={{ width: this.widthSlide }}
        class={'slider-item'}
      >{slot}</SwiperSlide>
    )))

    return (
      <Swiper
        class={'slider'}
        slidesPerView={'auto'}
        spaceBetween={this.spaceBetween}
        navigation={true}
        modules={[Navigation]}
        onSlideChange={(value) => { this.$emit('slideChange', value as unknown as typeof Swiper) }}
      >
        {children}
      </Swiper>
    )
  },
})
