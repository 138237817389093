/* Функции */
import {
  defineComponent, HTMLAttributes, PropType, VNode,
} from 'vue'

/* Стили */
import '@/components/VIcon/VIcon.scss'

/* Типы */
import { Cursor } from '@/components/VIcon/Types'

export default defineComponent({
  name: 'VIcon',

  props: {
    /** Имя файла */
    imageName: {
      type: String as PropType<string>,
      default: '',
      required: true,
    },
    /** Признак является ли иконка локальной */
    isLocalIcon: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    /** Тег */
    tag: {
      type: String as PropType<string>,
      default: 'span',
    },
    /** Заголовок при наведении */
    title: {
      type: String as PropType<string>,
      default: '',
    },
    /** Вставлять ли svg в HTML */
    isIncludeSvgInHtml: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    /** Клик */
    onClick: {
      type: Function as PropType<HTMLAttributes['onClick']>,
    },
    /** Курсор */
    cursor: {
      type: String as PropType<Cursor>,
      default: undefined,
    },
    /** Фиксирована ли ширина */
    isFixWidth: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
  },

  computed: {
    /** Путь к картинке */
    imageSrc(): string {
      if (this.isLocalIcon) {
        try {
          return require(`@/assets/img/icons/${this.imageName}.svg`)
        } catch (error) {
          return ''
        }
      } else {
        return this.imageName
      }
    },

    /** Стили */
    styles() {
      return {
        cursor: this.cursor,
      }
    },

    classes() {
      return {
        icon: true,
        icon__width: this.isFixWidth,
      }
    },
  },

  render(): VNode {
    if (!this.imageSrc) {
      console.error(`Неизвестное имя иконки. Иконки "${this.imageName}" нет в assets.`)
    }

    const nameComponent = this.tag

    return (
      <nameComponent
        class={this.classes}
        onClick={this.onClick}
        style={this.styles}
      >
        {this.isIncludeSvgInHtml && this.imageSrc
          ? <img v-svg-inline data-src={this.imageSrc} />
          : <img src={this.imageSrc} />}
      </nameComponent>
    )
  },
})
