/* Функции */
import { defineComponent, PropType, VNode } from 'vue'

/* Компоненты */
import VTextHeading1 from '@/components/VText/VTextHeading1/VTextHeading1'
import VRow from '@/components/VRow/VRow'
import VLabel from '@/components/VLabel/VLabel'

/* Стили */
import '@/components/BaseWorkoutBlock/BaseWorkoutBlock.scss'

export default defineComponent({
  name: 'BaseWorkoutBlock',

  props: {
    /** Заголовок */
    title: {
      type: String as PropType<string>,
      default: '',
    },

    /** Постфикс после продолжительности */
    durationPostfixText: {
      type: String as PropType<string>,
      default: '',
    },

    /** Продолжительность */
    duration: {
      type: String as PropType<string>,
      required: true,
    },
  },

  render(): VNode {
    return (
      <div class={'workout-block'}>
        <VRow class={'workout-block__top'}>
          <VTextHeading1>{this.title}</VTextHeading1>

          <VLabel isSecondary={true}>
            {`${this.duration} ${this.durationPostfixText}`}
          </VLabel>
        </VRow>

        <div class={'workout-block__content'}>
          {this.$slots.default?.()}
        </div>
      </div>
    )
  },
})
