/* Типы */
import { Middleware } from '@/router/Types'
import { NavigationGuard, NavigationGuardNext, RouteLocationRaw } from 'vue-router'
import { Context } from '../types/common'

/** Метод запуска цепочки промежуточного ПО */
export const PipelineMiddleware = (context: Context, middleware: Middleware, index: number) => {
  const nextMiddleware: NavigationGuard = middleware[index]

  if (!nextMiddleware) {
    return context.next
  }

  return (params?: unknown) => {
    if (params) {
      context.next(params as RouteLocationRaw)
    } else {
      const nextPipeline: NavigationGuardNext = PipelineMiddleware(context, middleware, index + 1)

      return nextMiddleware(context.to, context.from, nextPipeline)
    }
  }
}
