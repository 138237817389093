/* Функции */
import { defineComponent, VNode } from 'vue'

/* Компоненты */
import VPage from '@/components/VPage/VPage'
import BaseMyTargetCard from '@/components/BaseMyTargetCard/BaseMyTargetCard'
import VContentItem from '@/components/VContent/VContentItem/VContentItem'
import VTextParagraph from '@/components/VText/VTextParagraph/VTextParagraph'
import VButton from '@/components/VButton/VButton'
import VRow from '@/components/VRow/VRow'

/* Стили */
import '@/views/MyGoals/MyGoals.scss'

/* Типы */
import { RequestAPI } from '@/classes/Request'
import { ResponseMyGoals } from '@/types/API'
import { RoutePaths } from '@/router/Types'
import { LayoutStore } from '@/store/layout'
import { Data } from './Types'

/* Хранилище */

export default defineComponent({
  name: 'MyGoalsPage',

  data(): Data {
    return {
      goals: [],
      categories: [],
      isLoadData: false,
    }
  },

  computed: {
    isDisabledButton(): boolean {
      return this.goals.length >= 3
    },
  },

  async created() {
    LayoutStore().setLoading(true)

    const response = await RequestAPI.errorHandler<ResponseMyGoals>(RequestAPI.get, '/athletes/goals')

    if (response) {
      const data = response.data.content

      this.goals = data.goals ?? []
      this.categories = data.goal_categories
    }

    LayoutStore().setLoading(false)
    this.isLoadData = true
  },

  render(): VNode {
    const goals = this.goals.map((goal) => (
      <BaseMyTargetCard
        cycles={goal.cycles}
        id={goal.id}
        duration={goal.duration ?? 0}
        description={goal.description ?? ''}
        title={goal.title ?? ''}
        subTitle={this.categories.find((category) => category.id === goal.goal_category_id)?.title ?? ''}
        cyclesTitle={this.$t('pages.MyGoalsPage.cyclesTitle')}
      />
    ))

    return (
      <VPage class={'my-goals'} key={'MyGoalsPage'}>
        <VContentItem isPaddingX={true} isPaddingY={false} isShow={this.isLoadData}>
          {
            goals.length ? goals : <VRow justify={'center'}>
              <VTextParagraph opacity={1}>{this.$t('pages.MyGoalsPage.noCycle')}</VTextParagraph>
            </VRow>
          }
        </VContentItem>

        <VContentItem isPaddingX={true} isPaddingY={false} isShow={this.isLoadData}>
          <VRow justify={'center'}>
            <VButton
              onClick={() => { this.$router.push(RoutePaths.goals) }}
              isDisabled={this.isDisabledButton}
              class={'my-goals__button'}
            >{this.$t('pages.MyGoalsPage.button', this.goals.length ? 1 : 0)}</VButton>
          </VRow>
        </VContentItem>
      </VPage>
    )
  },
})
