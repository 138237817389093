/* Функции */
import {
  defineComponent, HTMLAttributes, PropType, VNode,
} from 'vue'

/* Стили */
import '@/components/VInput/VInputLabel/VInputLabel.scss'

export default defineComponent({
  name: 'VInputLabel',

  props: {
    /** Клик на элемент */
    onClick: {
      type: Function as PropType<HTMLAttributes['onClick']>,
    },
  },

  render(): VNode | null {
    return (
      this.$slots.default?.().length
        ? <label
          onClick={this.onClick}
          class={'input-label'}
        >
          {this.$slots.default()}
        </label>
        : null)
  },
})
