/* Функции */
import { defineComponent, PropType, VNode } from 'vue'

/* Компоненты */
import VRow from '@/components/VRow/VRow'
import VTabs from '@/components/VTabs/VTabs/VTabs'
import VTabButton from '@/components/VTabs/VTabButton/VTabButton'

/* Стили */
import '@/components/BaseAuthModeToggle/BaseAuthModeToggle.scss'

/* Типы */
import { Data } from '@/components/BaseAuthModeToggle/Types'
import { AuthMode } from '@/components/TheAuth/Types'

export default defineComponent({
  name: 'BaseAuthModeToggle',

  emits: {
    'update:modelValue': (value: AuthMode) => value === AuthMode.phone || value === AuthMode.email,
  },

  props: {
    /** Текст мобильного телефона (кнопка) */
    phoneText: {
      type: String as PropType<string>,
      default: '',
    },
    /** Текст электронной почты (кнопка) */
    emailText: {
      type: String as PropType<string>,
      default: '',
    },
  },

  data(): Data {
    return {
      authMode: AuthMode.phone,
    }
  },

  methods: {
    /**
     * Метод смены режима отображения авторизации
     * @param authMode - Режим отображения
     */
    onChangeMode(authMode: AuthMode) {
      this.authMode = authMode
      this.$emit('update:modelValue', this.authMode)
    },
  },

  render(): VNode {
    return (
      <VRow class={'auth-mode-toggle'} justify={'space-between'}>
        <VTabs
          modelValue={this.authMode}
          onUpdate:modelValue={(value) => {
            if (value === AuthMode.email || value === AuthMode.phone) {
              this.onChangeMode(value)
            }
          }}
        >
          <VTabButton value={AuthMode.phone}>{this.phoneText}</VTabButton>

          <VTabButton value={AuthMode.email}>{this.emailText}</VTabButton>
        </VTabs>
      </VRow>)
  },
})
