/* Функции */
import { defineComponent, PropType, VNode } from 'vue'

/* Компоненты */
import VRow from '@/components/VRow/VRow'
import VIcon from '@/components/VIcon/VIcon'
import VTextHeading2 from '@/components/VText/VTextHeading2/VTextHeading2'
import VTransition from '@/components/VTransition/VTransition'

/* Хранилище */
import { LayoutStore } from '@/store/layout'
import { UserStore } from '@/store/user'

/* Стили */
import '@/components/TheHeader/TheHeader.scss'

/* Типы */
import { RoutePaths } from '@/router/Types'

export default defineComponent({
  name: 'TheHeader',

  props: {
    /** Признак принудительного отображения компонента */
    isForcedShow: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },

  computed: {

    /** Заголовок */
    title(): string {
      return LayoutStore().layoutParams?.title ?? ''
    },

    /** Признак отображения */
    isShow(): boolean {
      return this.isForcedShow || Boolean(!LayoutStore().layoutParams?.isHideHeader)
    },

    /** Признак отображения кнопки назад */
    isShowBackButton() {
      return LayoutStore().layoutParams?.isShowBackButton ?? true
    },

    /** Признак кнопки выход */
    isShowLogout() {
      return Boolean(LayoutStore().layoutParams?.isShowLogout)
    },

    /** Кастомные классы */
    customClasses() {
      return (LayoutStore().layoutParams?.classes) ?? {}
    },
  },

  methods: {
    /** Переход назад */
    onClickBack() {
      this.$router.back()
    },
    /** Выход */
    onClickLogout() {
      UserStore().logout()
      this.$router.push({ path: RoutePaths.index })
    },
  },

  render(): VNode | null {
    const backButton = this.isShowBackButton ? <VIcon
      class={'main-header__icon'}
      imageName={'arrow_left'}
      cursor={'pointer'}
      onClick={this.onClickBack}
    /> : null
    const logoutButton = this.isShowLogout ? <VIcon
      key={`header-${this.title}-${this.isShowBackButton}-${this.isShowLogout}`}
      class={'main-header__icon'}
      imageName={'logout'}
      cursor={'pointer'}
      onClick={this.onClickLogout}
    /> : null

    const left = <div class={'main-header__left'}>
      {
        this.$slots.left?.().length
          ? this.$slots.left?.()
          : backButton
      }
    </div>
    const center = <div class={'main-header__center'}>
      {
        this.$slots.default?.().length
          ? this.$slots.default?.()
          : <VTextHeading2
            key={`header-${this.title}-${this.isShowBackButton}-${this.isShowLogout}`}
            class={'main-header__title'}
          >
            {this.title}
          </VTextHeading2>
      }
    </div>
    const right = <div class={'main-header__right'}>
      {
        this.$slots.right?.().length
          ? this.$slots.right?.()
          : <VTransition>{logoutButton}</VTransition>
      }
    </div>

    return (
      <VTransition>
        {this.isShow
          ? <VRow
            alignItems={'center'}
            class={{ 'main-header': true, ...this.customClasses }}
            justify={'space-between'}

          >
            {left}

            <VTransition>{center}</VTransition>

            {right}
          </VRow>
          : null}
      </VTransition>
    )
  },
})
