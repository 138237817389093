/* Типы */
import { NavigationGuard, RouteLocationNormalized } from 'vue-router'
import { RouterItem } from '@/router/Types'

/* Хранилище */
import { LayoutStore } from '@/store/layout'

/** Промежуточное ПО установки параметров шаблона */
export const SetLayoutParamsMiddleware:
  NavigationGuard = (to: RouteLocationNormalized, from, next) => {
    const layoutStore = LayoutStore()
    const { layoutParams } = to.meta as RouterItem['meta']

    if (!layoutParams) {
      layoutStore.setLayoutParams(null)
      next()
      return
    }

    if (typeof layoutParams === 'object') {
      layoutStore.setLayoutParams(layoutParams)
    }

    next()
  }
