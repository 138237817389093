/* Функции */
import { defineComponent, PropType, VNode } from 'vue'

/* Компоненты */
import VRow from '@/components/VRow/VRow'
import VTextParagraph from '@/components/VText/VTextParagraph/VTextParagraph'

/* Стили */
import '@/components/VPreloader/VPreloader.scss'

export default defineComponent({
  name: 'VPreloader',

  props: {
    isShow: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },

  computed: {
    logoSrc() {
      return require('@/assets/img/icons/preloader_logo.svg')
    },

    iconSrc() {
      return require('@/assets/img/icons/preloader_icon.svg')
    },
  },

  render(): VNode | null {
    return (
      this.isShow
        ? <VRow
          class={'preloader'}
          direction={'column'}
          alignContent={'center'}
          justify={'center'}
          alignItems={'center'}
        >
          <img class={'preloader__logo'} src={this.logoSrc} alt="" />

          <img class={'preloader__icon'} src={this.iconSrc} alt="" />

          <VTextParagraph opacity={1} class={'preloader__text'}>{this.$slots.default?.() ?? this.$t('common.loading')}</VTextParagraph>
        </VRow> : null
    )
  },
})
