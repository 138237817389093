/* Функции */
import { defineComponent, VNode } from 'vue'

/* Компоненты */
import TheMainMenu from '@/components/TheMainMenu/TheMainMenu'
import TheHeader from '@/components/TheHeader/TheHeader'
import TheShowBanners from '@/components/TheShowBanners/TheShowBanners'
import VTransition from '@/components/VTransition/VTransition'
import VPreloader from '@/components/VPreloader/VPreloader'

/* Хранилище */
import { LayoutStore } from '@/store/layout'

/* Стили */
import '@/layouts/PersonalAccount/PersonalAccount.scss'

export default defineComponent({
  name: 'PersonalAccountLayout',

  computed: {
    /** Признак показа меню */
    isShowMenu(): boolean {
      const { layoutParams } = LayoutStore()

      return (typeof layoutParams?.isShowMenu !== 'boolean') ? true : layoutParams.isShowMenu
    },

    /** Показывать ли индикатор загрузки. */
    isShowPreloader() {
      return Boolean(LayoutStore().layoutParams?.isShowPreloader && LayoutStore().isLoading)
    },

    /** CSS классы */
    classes(): Record<string, boolean> {
      return {
        'personal-account-layout': true,
        layout: true,
        'is-hide-show-menu': !this.isShowMenu,
      }
    },
  },

  render(): VNode {
    return (
      <div class={'personal-account-layout__wrap'}>
        <TheShowBanners />

        <div class={this.classes}>

          <div class={'personal-account-layout__content'}>
            <TheHeader />

            <div class="personal-account-layout__view-area">
              {this.$slots.default?.()}
            </div>

            <VTransition>
              {this.isShowPreloader ? <VPreloader key={'main-preloader'} isShow={true}>{this.$t('common.loading')}</VPreloader> : null}
            </VTransition>

            {this.isShowMenu ? <TheMainMenu /> : null}
          </div>
        </div>
      </div>
    )
  },
})
