/* Функции */
import { defineComponent, VNode } from 'vue'

/* Стили */
import '@/components/VContent/VContent.scss'

export default defineComponent({
  name: 'VContent',

  render(): VNode {
    const classes = {
      content: true,
      multi: !this.$slots.default?.().length,
    }

    return (
      <div class={classes}>
        {
          this.$slots.default?.().length
            ? this.$slots.default()
            : [
              <div class={'content__top'}>
                {this.$slots.top?.()}
              </div>,

              <div class={'content__bottom'}>
                {this.$slots.bottom?.()}
              </div>,
            ]
        }
      </div>
    )
  },
})
