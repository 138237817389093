/* Функции */
import {
  defineComponent, HTMLAttributes, PropType, VNode,
} from 'vue'

/* Компоненты */
import VIcon from '@/components/VIcon/VIcon'
import VTextSubButton from '@/components/VText/VTextSubButton/VTextSubButton'

/* Стили */
import '@/components/VMainMenuItem/VMainMenuItem.scss'

export default defineComponent({
  name: 'VMainMenuItem',

  props: {
    /** Имя файла */
    imageName: {
      type: String as PropType<string>,
      default: '',
      required: true,
    },
    /** Признак является ли иконка локальной */
    isLocalIcon: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    /** Тег */
    tagIcon: {
      type: String as PropType<string>,
      default: 'span',
    },
    /** Заголовок при наведении */
    titleIcon: {
      type: String as PropType<string>,
      default: '',
    },
    /** Вставлять ли svg в HTML */
    isIncludeSvgInHtml: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    /** Клик */
    onClickIcon: {
      type: Function as PropType<HTMLAttributes['onClick']>,
    },
    /** Текст */
    text: {
      type: String as PropType<string>,
      default: '',
    },
    path: {
      type: String as PropType<string>,
      default: '',
    },
    isIncludeActive: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },

  computed: {
    classes() {
      return {
        'main-menu-item': true,
        active: this.isIncludeActive ? this.$route.path.includes(this.path) : false,
      }
    },
  },

  render(): VNode {
    return (
      <router-link class={this.classes} to={this.path}>
        <VIcon
          class={'main-menu-item__icon'}
          imageName={this.imageName}
          isLocalIcon={this.isLocalIcon}
          tag={this.tagIcon}
          title={this.titleIcon}
          isIncludeSvgInHtml={this.isIncludeSvgInHtml}
          onClick={this.onClickIcon}
        />

        <VTextSubButton class={'main-menu-item__text'}>
          {this.text}
        </VTextSubButton>
      </router-link>
    )
  },
})
