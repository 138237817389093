import { HTMLAttributes, PropType } from 'vue'

export type GlobalValues = 'inherit' | 'initial' | 'revert' | 'revert-layer' | 'unset'

export type TextAlign = 'start'
  | 'end'
  | 'left'
  | 'right'
  | 'center'
  | 'justify'
  | 'justify-all'
  | 'match-parent'

export type Cursor = 'auto'
  | 'default'
  | 'none'
  | 'context-menu'
  | 'help'
  | 'pointer'
  | 'progress'
  | 'wait'
  | 'cell'
  | 'crosshair'
  | 'text'
  | 'vertical-text'
  | 'alias'
  | 'copy'
  | 'move'
  | 'no-drop'
  | 'not-allowed'
  | 'grab'
  | 'grabbing'
  | 'e-resize'
  | 'n-resize'
  | 'ne-resize'
  | 'nw-resize'
  | 's-resize'
  | 'se-resize'
  | 'sw-resize'
  | 'w-resize'
  | 'ew-resize'
  | 'ns-resize'
  | 'nesw-resize'
  | 'nwse-resize'
  | 'col-resize'
  | 'row-resize'
  | 'all-scroll'
  | 'zoom-in'
  | 'zoom-out'

export type FontWeight = 100
  | 200
  | 300
  | 400
  | 500
  | 600
  | 700
  | 800
  | 900
  | 'lighter'
  | 'bolder'
  | 'inherit'
  | 'initial'
  | 'revert'
  | 'revert-layer'
  | 'unset'

export type DefaultProps = {
  /** Нажатие мышкой */
  onClick: {
    type: PropType<HTMLAttributes['onClick']>
  },

  /** Выравнивание текста */
  textAlign: {
    type: PropType<TextAlign>,
    default: ''
  },

  /** Курсор */
  cursor: {
    type: PropType<Cursor>,
    default: undefined,
  }

  /** Прозрачность */
  opacity: {
    type: PropType<number | null>,
    default: number,
  },

  /** Прозрачность */
  fontWeight: {
    type: PropType<FontWeight | null>,
    default: FontWeight | null,
  }

  /** Цвет текста */
  colorText: {
    type: PropType<string | null>,
    default: string | null,
  },

  /** Признак отсутствия отступов у следующего элемента */
  isNotMargin: {
    type: PropType<boolean>,
    default: boolean,
  }
}

export type DefaultValue = {
  defaultOpacity?: number
  fontWeight?: FontWeight
  colorText?: null
}

export const defaultProps = (value?: DefaultValue): DefaultProps => ({
  /** Нажатие мышкой */
  onClick: {
    type: Function as PropType<HTMLAttributes['onClick']>,
  },

  /** Выравнивание текста */
  textAlign: {
    type: String as PropType<TextAlign>,
    default: '',
  },

  /** Курсор */
  cursor: {
    type: String as PropType<Cursor>,
    default: undefined,
  },

  /** Курсор */
  opacity: {
    type: Number as PropType<number>,
    default: value?.defaultOpacity ?? 1,
  },

  /** Курсор */
  fontWeight: {
    type: [Number, String, null] as PropType<FontWeight | null>,
    default: value?.fontWeight ?? null,
  },

  /** Цвет текста */
  colorText: {
    type: String as PropType<string>,
    default: value?.colorText ?? null,
  },

  /** Признак отсутствия отступов у следующего элемента */
  isNotMargin: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
})

export function defaultStyles(this: any) {
  return {
    cursor: this.cursor,
    opacity: this.opacity,
    color: this.colorText,
    'font-weight': this.fontWeight,
    'text-align': this.textAlign,
  }
}

export function defaultClasses(this: any): Record<string, boolean> {
  return {
    text: true,
    'no-margin': this.isNotMargin as boolean,
  }
}
