/* Функции */
import { defineComponent, VNode } from 'vue'

/* Стили */
import '@/views/Like/Like.scss'

export default defineComponent({
  name: 'LikeView',

  render(): VNode | null {
    return (null)
  },
})
