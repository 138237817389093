/* Функции */
import { defineComponent, PropType, VNode } from 'vue'

/* Компоненты */
import VRow from '@/components/VRow/VRow'
import TheWorkoutCalendarItem from '@/components/TheWorkoutCalendar/TheWorkoutCalendarItem/TheWorkoutCalendarItem'
import VSlider from '@/components/VSlider/VSlider'

/* Стили */
import '@/components/TheWorkoutCalendar/TheWorkoutCalendar.scss'

/* Типы */
import {
  CurrentAllWorkoutValue,
  Data,
  DayNamesByDate, DaysWeeksView, FormatDate, WorkoutByDate,
} from '@/components/TheWorkoutCalendar/Types'

/* Помощники */
import dayjs from 'dayjs'

export default defineComponent({
  name: 'TheWorkoutCalendar',

  emits: {
    'update:modelValue': (value: string) => typeof value === 'string',
    'update:week': (value: number) => typeof value === 'number',
  },

  data(): Data {
    return {
      currentWeek: 0,
      weeksNamesByDate: [],
    }
  },

  props: {
    /** Количество отображаемых дней в неделе */
    daysWeeksView: {
      type: Number as PropType<number>,
      default: DaysWeeksView,
      validation: (value: number) => !(value % 7),
    },
    /** Номер недели */
    week: {
      type: Number as PropType<number>,
      default: 0,
    },
    /** Тренировки по датам */
    workoutByDate: {
      type: Object as PropType<WorkoutByDate>,
      default: () => ({}),
    },
    /** Текст кнопки показа всех тренировок на неделе */
    textAll: {
      type: String as PropType<string>,
      default: '',
    },
    /** Значение для связывания */
    modelValue: {
      type: String as PropType<string>,
      default: '',
    },
  },

  computed: {
    /** Количество отображаемых недель */
    weeksCount() {
      return DaysWeeksView / 7
    },
  },

  created() {
    this.currentWeek = this.week
    this.weeksNamesByDate = this.getWeeksNamesByDate()
  },

  methods: {
    /** Названия дней по датам */
    getWeeksNamesByDate(): DayNamesByDate[] {
      const result: DayNamesByDate[] = []
      const startDate = dayjs().weekday(0)

      for (let index = 0; index < 7 * this.weeksCount; index += 1) {
        const date = startDate.set('day', index + 1).format(FormatDate)

        result.push({
          name: startDate.set('day', index + 1).format('dd'),
          date,
          count: this.workoutByDate[date]?.all ?? 0,
          completed: this.workoutByDate[date]?.completed ?? 0,
        })
      }
      return result
    },

    onClickAllWeek() {
      this.$emit('update:week', this.currentWeek)
      this.$emit('update:modelValue', CurrentAllWorkoutValue)
    },
  },

  watch: {
    workoutByDate() {
      this.weeksNamesByDate = this.getWeeksNamesByDate()
    },

    week() {
      this.currentWeek = this.week
    },
  },

  render(): VNode {
    const slides = () => {
      const result: VNode[] = []

      for (let index = 0; index < Math.ceil(this.daysWeeksView / 7); index += 1) {
        result.push(
          <VRow class={'workout-calendar__slide'} alignItems={'stretch'}>
            {this.weeksNamesByDate.reduce<VNode[]>(
              (nodes, week, indexIn) => {
                if (7 * (index + 1) > indexIn && indexIn >= 7 * (index + 1) - 7) {
                  const dateWeek = dayjs(week.date).hour(0).minute(0).second(0)
                    .millisecond(0)
                    .unix()
                  const today = dayjs().hour(0).minute(0).second(0)
                    .millisecond(0)
                    .unix()
                  const isPast = today > dateWeek
                  const isCurrentDay = today === dateWeek

                  nodes.push(
                    <TheWorkoutCalendarItem
                      day={week.name}
                      count={week.count}
                      countCompleted={week.completed}
                      isDisabled={!week.count && !isCurrentDay}
                      isPast={isPast}
                      isCurrent={this.modelValue === week.date}
                      onClick={() => {
                        this.$emit('update:modelValue', week.date)
                      }}
                    />,
                  )
                }

                return nodes
              },

              [],
            )}
          </VRow>,
        )
      }
      return result
    }

    return (
      <VRow class={'workout-calendar'} alignItems={'stretch'}>
        <VRow
        alignItems={'center'}
          class={{
            'workout-calendar__all': true,
            'is-current': this.modelValue === CurrentAllWorkoutValue,
          }}
          onClick={this.onClickAllWeek}
        >
          {Object.keys(this.workoutByDate).length ? this.textAll : null}
        </VRow>

        <VRow class={'workout-calendar__slider'}>
          {
            Object.keys(this.workoutByDate).length
              ? <VSlider
                onSlideChange={(value) => {
                  this.currentWeek = value.activeIndex
                  setTimeout(() => {
                    this.$emit('update:week', this.currentWeek)
                  }, 200)
                }}
                spaceBetween={0}
              >
                {{
                  default: () => slides(),
                }}
              </VSlider> : null
          }
        </VRow>
      </VRow>
    )
  },
})
