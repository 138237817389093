/* Типы */
import { NavigationGuard, RouteLocationNormalized } from 'vue-router'
import { RouterItem } from '@/router/Types'
import { Layouts } from '@/layouts'

/* Хранилище */
import { LayoutStore } from '@/store/layout'

/** Промежуточное ПО установки шаблонов */
export const SetLayoutMiddleware: NavigationGuard = (to: RouteLocationNormalized, from, next) => {
  const layoutStore = LayoutStore()
  const { layout } = to.meta as RouterItem['meta']

  if (layout) {
    layoutStore.setLayout(layout)
  } else {
    layoutStore.setLayout(Layouts.Default)
  }

  next()
}
