/* Функции */
import { defineComponent, VNode } from 'vue'

/* Компоненты */
import VButtonFixes from '@/components/VButton/VButtonIcon/VButtonFixes/VButtonFixes'
import VContentItem from '@/components/VContent/VContentItem/VContentItem'
import VInputButton from '@/components/VInput/VInputButton/VInputButton'
import VPage from '@/components/VPage/VPage'
import VRow from '@/components/VRow/VRow'
import VTextButton from '@/components/VText/VTextButton/VTextButton'
import VTextSmall from '@/components/VText/VTextSmall/VTextSmall'
import VTransition from '@/components/VTransition/VTransition'

/* Типы */
import { InputType } from '@/components/VInput/VInputButton/Types'
import { RequestAPI } from '@/classes/Request'
import { Profile } from '@/types/API'
import { Data } from '@/views/Profile/Equipment/Types'

/* Хранилище */
import { LayoutStore } from '@/store/layout'

export default defineComponent({
  name: 'EquipmentPage',

  data(): Data {
    return {
      equipment: [],
      startEquipment: [],
      data: null,
      isLoadData: false,
      isSaved: false,
    }
  },

  computed: {
    isChange(): boolean {
      return Boolean(this.startEquipment.filter((x) => !this.equipment.includes(x))
        .concat(this.equipment.filter((x) => !this.startEquipment.includes(x))).length)
    },
  },

  methods: {
    updateStartData() {
      this.startEquipment = this.equipment.map((id) => id)
    },

    async save() {
      LayoutStore().setLoading(true)
      const {
        equipment,
      } = this

      this.isSaved = true

      await RequestAPI.errorHandler<Profile>(RequestAPI.put, '/profile', {
        athlete: {
          equipment_item_ids: equipment,
        },
      })

      this.updateStartData()

      LayoutStore().setLoading(false)
      this.isSaved = false
    },

    setValue(value: number) {
      const isFind = this.equipment.find((equipment) => value === equipment)

      if (isFind) {
        this.equipment = this.equipment.filter((equipment) => equipment !== value)
      } else {
        this.equipment.push(value)
      }
    },
  },

  async created() {
    LayoutStore().setLoading(true)
    const response = await RequestAPI.errorHandler<Profile>(RequestAPI.get, '/profile')

    if (response) {
      this.equipment = response.data.content.athlete.equipment_items.map((equipment) => equipment.id)

      this.data = response.data.content
      this.updateStartData()
    }

    LayoutStore().setLoading(false)
    this.isLoadData = true
  },

  render(): VNode {
    const isChecked = (value: number) => Boolean(this.equipment.find((equipment) => value === equipment))
    const equipmentItems = () => this.data?.equipment_items
      .map(({ id, title }, index) => (
        <VInputButton
          key={`equipment-items-${index}`}
          name=''
          type={InputType.checkbox}
          value={id}
          isCheckboxValue
          onUpdate:checkboxValue={
            (value) => {
              this.setValue(Number(value.value))
            }
          }
          modelValue={isChecked(id)}
          isShowImage={false}
          isInline={true}
        >
          {title}
        </VInputButton>
      ))

    return (
      <VPage>
        <VContentItem isPaddingX={true} isShow={this.isLoadData}>
          <VRow>
            <VTextSmall textAlign={'left'} class={'text-input__subtext'}>{this.$t('pages.Profile.equipment.description')}</VTextSmall>
          </VRow>

          {equipmentItems()}
        </VContentItem>

        <VTransition>
          {
            this.isChange
              ? <VButtonFixes
                isFullWidth={true}
                isDisabled={this.isSaved}
                onClick={this.save}>
                <VTextButton>{this.$t('pages.Profile.personalInformation.button')}</VTextButton>
              </VButtonFixes>
              : null
          }
        </VTransition>
      </VPage>)
  },
})
