/* Функции */
import { defineComponent, VNode } from 'vue'

/* Компоненты */
import VPage from '@/components/VPage/VPage'
import BaseTargetHeader from '@/components/BaseTargetHeader/BaseTargetHeader'
import VContentItem from '@/components/VContent/VContentItem/VContentItem'
import VMoreText from '@/components/VMoreText/VMoreText'
import VSlideUpSlideDown from '@/components/VSlideUpSlideDown/VSlideUpSlideDown'
import BaseTargetCycle from '@/components/BaseTargetCycle/BaseTargetCycle'
import VButton from '@/components/VButton/VButton'

/* Стили */
import '@/views/MyGoals/MyGoalsDetail/MyGoalsDetail.scss'

/* Классы */
import { RequestAPI } from '@/classes/Request'
import dayjs from 'dayjs'

/* Типы */
import { Cycle, ResponseGoals, ResponseMyGoalDetail } from '@/types/API'
import { Data } from '@/views/MyGoals/MyGoalsDetail/Types'
import { RoutePaths } from '@/router/Types'
import { Status } from '@/components/BaseTargetCycle/Types'

/* Хранилище */
import { LayoutStore } from '@/store/layout'

export default defineComponent({
  name: 'MyGoalsDetailView',

  data(): Data {
    return {
      target: null,
      category: null,
    }
  },

  methods: {
    /** Получение данных по плану */
    async getPlans() {
      const response = await RequestAPI.errorHandler<ResponseMyGoalDetail>(RequestAPI.get, `/athletes/goals/${this.$route.params.id}`)

      if (response) {
        return response.data.content
      }

      return null
    },

    /** Установка данных */
    async setData() {
      const data = await this.getPlans()

      if (data) {
        const { goal, goal_categories } = data
        const currentCategory = goal_categories?.find(({ id }) => id === goal?.goal_category_id)

        this.target = goal ?? null
        this.category = currentCategory ?? null
      }
    },

    async show() {
      if (this.target) {
        const data = await RequestAPI.errorHandler<ResponseGoals>(RequestAPI.post, `/goals/${this.target.id}`)

        if (data && data.data?.response?.status === 'ok') {
          this.$router.push({ path: RoutePaths.main })
        }
      }
    },

    getCycleState(cycle: Cycle): Status {
      if (dayjs(cycle.end_date) <= dayjs()) {
        return 'done'
      }

      if (dayjs(cycle.start_date) <= dayjs()) {
        return 'current'
      }

      return 'unavailable'
    },
  },

  computed: {
    /** Отображаемая дата */
    showDate(): string {
      const dates: number[] = []

      this.target?.cycles.forEach(({ start_date, end_date }) => {
        dates.push(new Date(start_date).getTime())
        dates.push(new Date(end_date).getTime())
      })
      const maxDate = new Date(Math.max.apply(null, dates))
      const minDate = new Date(Math.min.apply(null, dates))

      return `${dayjs(minDate).format('DD MMMM')} - ${dayjs(maxDate).format('DD MMMM')}`
    },

    /** Ссылка */
    to(): string {
      return RoutePaths.myGoalsDetailClose.replace(':id', String(this.target?.id ?? ''))
    },
  },

  async created() {
    LayoutStore().setLoading(true)

    await this.setData()

    LayoutStore().setLoading(false)
  },

  render(): VNode | null {
    const cycles = this.target?.cycles.map((cycle, index) => (
      <BaseTargetCycle
        title={cycle.title ?? ''}
        startDate={cycle.start_date}
        endDate={cycle.end_date}
        description={cycle.description ?? ''}
        workoutsCount={cycle.workouts_count ?? 0}
        status={this.getCycleState(cycle)}
        id={`${this.target?.id}-cycle-${index}`}
      />
    ))

    return (
      this.target
        ? <VPage class={'plan-detail'} key={'MyGoalsDetailView'}>
          <BaseTargetHeader
            src={this.target?.images.show ?? ''}
            failLoadSrc={''}
            title={this.target?.title ?? ''}
            subTitle={this.category?.title ?? ''}
            cycle={this.target?.cycles.length ?? 0}
            duration={this.target?.duration ?? 0}
            key={`target-detail-${this.target?.id}`}
            showDate={this.showDate}
          />

          <VContentItem isPaddingX={true} margin={'20px 0'}>
            <VMoreText opacity={1}>{this.target?.description ?? ''}</VMoreText>

            <VSlideUpSlideDown
              title={this.$t('pages.MyGoalsDetailPage.result')}
              isShow={true}
              id={'result-target'}
            >
              {cycles}
            </VSlideUpSlideDown>
          </VContentItem>

          <VContentItem isPaddingX={true} margin={'20px 0'}>
            <VButton
              isQuaternary={true}
              onClick={() => { this.$router.push(this.to) }}
            >
              {this.$t('pages.MyGoalsDetailPage.button')}
            </VButton>
          </VContentItem>
        </VPage>
        : <VPage class={'plan-detail'} key={'MyGoalsDetailViewEmpty'}> </VPage>
    )
  },
})
