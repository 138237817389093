/* Функции */
import { defineComponent, VNode } from 'vue'

/* Компоненты */
import VBanner from '@/components/VBanner/VBanner'
import VButton from '@/components/VButton/VButton'

/* Стили */
import '@/components/TheShowBanners/TheShowBanners.scss'

/* Типы */
import { Banners, Data } from '@/components/TheShowBanners/Types'
import { RoutePaths } from '@/router/Types'
import { Profile } from '@/types/API'
import { ShowOn, WindowSizeDesktop } from '@/components/VBanner/Types'

/* Хранилище */
import { mapStores } from 'pinia'
import { UserStore } from '@/store/user'

/* Классы */
import { RequestAPI } from '@/classes/Request'

export default defineComponent({
  name: 'TheShowBanners',

  data(): Data {
    return {
      isShow: false,
      isMobile: true,
      isTelegramActive: false,
      telegramToken: null,
    }
  },

  computed: {
    ...mapStores(UserStore),

    isShowComponent(): boolean {
      return this.isShow && this.$route.path === RoutePaths.main && !this.userStore.isActiveTelegram && !this.isTelegramActive
    },

    countShowTelegramBanners(): number {
      return this.userStore.countShowTelegramBanners ?? 0
    },

    showOn(): ShowOn {
      return this.isMobile ? ShowOn.mobile : ShowOn.desktop
    },

    linkTelegram(): string {
      return `https://t.me/SportAppyBot?start=${this.telegramToken}`
    },
  },

  methods: {
    onClickBanner() {
      this.isShow = false
      window.open(this.linkTelegram)
    },

    onClickClose() {
      this.isShow = false
    },

    setShowStatus() {
      this.isMobile = window.innerWidth < WindowSizeDesktop
    },

    setBannerCount() {
      const count = this.countShowTelegramBanners >= Banners.length - 1 ? 0 : this.countShowTelegramBanners + 1
      this.userStore.setCountTelegram(count)
    },

    async getTelegramInfo() {
      if (!UserStore().isAuth()) {
        return
      }

      const response = await RequestAPI.errorHandler<Profile>(RequestAPI.get, '/profile')

      if (response) {
        const { data } = response

        this.isTelegramActive = Boolean(data.content.athlete.telegram_id)
        this.telegramToken = data.content.athlete.telegram_token
      }
    },
  },

  watch: {
    '$route.path': function routePathChange() {
      if (this.isShowComponent) {
        this.setBannerCount()
      }
    },
  },

  async created() {
    this.setBannerCount()
    if (!this.userStore.isActiveTelegram) {
      await this.getTelegramInfo()
    }
    this.isShow = true
    this.setShowStatus()
    window.addEventListener('resize', this.setShowStatus)
  },

  unmounted() {
    window.removeEventListener('resize', this.setShowStatus)
  },

  render(): VNode | null {
    const banner = () => {
      const banner = Banners[this.countShowTelegramBanners]

      if (!banner) {
        return null
      }

      switch (this.showOn) {
        case ShowOn.desktop:
          return <VBanner
            imageName={banner.desktop.iconName}
            showOn={this.showOn}
            backgroundColor={banner.desktop.background}
            key={`banner-${this.countShowTelegramBanners}`}
            onClose={this.onClickClose}
          >
            {{
              default: () => <ul class={'banner-ul'}>{banner.desktop.text.map((text) => <li>{text}</li>)}</ul>,
              title: () => banner.desktop.titleIcon,
              button: () => <VButton
                onClick={this.onClickBanner}
                imageName={banner.desktop.iconButton}
                {...banner.desktop.typeButtons}
              >
                {banner.desktop.button}
              </VButton>,
            }}
          </VBanner>
        case ShowOn.mobile:
          return <VBanner
            imageName={banner.mobile.iconName}
            backgroundColor={banner.mobile.background}
            mode={banner.mobile.mode}
            showOn={this.showOn}
            onClose={this.onClickClose}
          >
            {{
              default: () => banner.mobile.text,
              subtitle: () => <VButton
                onClick={this.onClickBanner}
                {...banner.mobile.typeButtons}
              >
                {banner.mobile.button}
              </VButton>,
            }}
          </VBanner>
      }
    }
    return (
      this.isShowComponent
        ? banner()
        : null
    )
  },
})
