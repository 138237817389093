/**
 * Функция проверки на мобильное устройство.
 *
 * @param {Object<string|any>} window - Глобальный объект
 * @returns Boolean
 */
export function isMobile(window: Window) {
  return /Mobile|webOS|BlackBerry|IEMobile|MeeGo|mini|Fennec|Windows Phone|Android|iP(ad|od|hone)/i.test(window.navigator.userAgent)
}

/**
 * Функция проверки на мобильное устройство на ОС IOS.
 *
 * @param {Object<string|any>} window - Глобальный объект
 * @returns Boolean
 */
export function isIOs(window: Window) {
  return /iP(hone|od|ad)/.test(window.navigator.userAgent)
}

/**
 * Делает первый символ большим
 * @param worlds Строка
 * @returns Строка с большим первым символом
 */
export function toCapitalized(worlds: string): string {
  const list = worlds.toLocaleLowerCase()

  if (!list.length) {
    return ''
  }

  return list.charAt(0).toUpperCase() + list.slice(1)
}

/**
 * Метод получения диапазона продолжительности
 * @param min - Минимальное значение
 * @param max - Максимальное значение
 * @returns Период
 */
export function getDurationPeriod(min: number, max: number): string {
  if (min < max) {
    return `${min} - ${max}`
  }

  return String(min)
}

export function isRussianPhone(value: string) {
  const reg = /^(\+7|7|8)?[\s-]?\(?[489][0-9]{2}\)?[\s-]?[0-9]{3}[\s-]?[0-9]{2}[\s-]?[0-9]{2}$/gm
  return reg.test(value)
}
