/* Функции */
import {
  defineComponent, inject, PropType, VNode,
} from 'vue'

/* Компоненты */
import VRow from '@/components/VRow/VRow'
import VTextParagraph from '@/components/VText/VTextParagraph/VTextParagraph'
import VIcon from '@/components/VIcon/VIcon'

/* Стили */
import '@/components/VListIcons/VListIcons.scss'

/* Типы */
import { Data } from './Types'

export default defineComponent({
  name: 'VListIcons',

  props: {
    /** Элементы списка */
    items: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    /** Иконка */
    imageName: {
      type: String as PropType<string>,
      default: 'check_circle',
    },
    /** Цвет */
    color: {
      type: String as PropType<string>,
      default: '#48DF80',
    },

    /** Для задания пунктов через html */
    html: {
      type: String as PropType<string>,
      default: '',
    },
  },

  data(): Data {
    return {
      className: 'list-icons',
    }
  },

  setup() {
    const colorValue = inject<{ colorValue: string } | null>('colorValue', null)

    return {
      colorValue,
    }
  },

  computed: {
    fill() {
      return this.colorValue ?? this.color
    },
  },

  render(): VNode {
    const lists = () => this.items.map((list) => (
      <VRow class={'list-icons__item'}>
        <VIcon imageName={this.imageName} />

        <VTextParagraph>{list}</VTextParagraph>
      </VRow>
    ))

    const listByHtml = () => {
      const searchRegexp = /<li>.+?<\/li>/gm
      const result = this.html.match(searchRegexp) ?? []

      return result.map((list) => {
        const li = list.replace(/<li>|<\/li>/g, '')

        return (<VRow class={'list-icons__item'}>
          <VIcon imageName={this.imageName} />

          <VTextParagraph>{li}</VTextParagraph>
        </VRow>)
      })
    }

    return (
      <div class={this.className}>
        <style>
          {`
            .${this.className} [fill]{
              fill: ${this.fill}
            }
          `}
        </style>

        {lists().length ? lists() : listByHtml()}
      </div>
    )
  },
})
