/* Функции */
import { defineComponent, PropType, VNode } from 'vue'

/* Компоненты */
import VLabelIcon from '@/components/VLabelIcon/VLabelIcon'

/* Стили */
import '@/components/BasePlanLabel/BasePlanLabel.scss'

export default defineComponent({
  name: 'BasePlanLabel',

  props: {
    label: {
      type: String as PropType<string>,
      default: '',
    },

    position: {
      type: String as PropType<'left' | 'right'>,
      default: 'right',
    },
  },

  computed: {
    classes() {
      return {
        'plan-label': true,
        [this.position]: true,
      }
    },
  },

  render(): VNode {
    const labelComponent = () => {
      if (this.label.toLocaleLowerCase() === 'pro') {
        return <VLabelIcon
          class={this.classes}
          isSecondary
          imageName='pro'
        >
          {this.label}
        </VLabelIcon>
      }

      return <VLabelIcon
        class={this.classes}
        isTertiary
      >
        {this.label}
      </VLabelIcon>
    }

    return (
      labelComponent()
    )
  },
})
