import { createRouter, createWebHistory } from 'vue-router'

/* VIES */
import QuizView from '@/views/Quiz/Quiz'
import StartView from '@/views/Start/Start'
import LoginView from '@/views/Login/Login'
import MainView from '@/views/Main/Main'
import GoalsView from '@/views/Goals/Goals'
import GoalsDetailView from '@/views/Goals/GoalsDetail/GoalsDetail'
import ProfileView from '@/views/Profile/Profile'
import TelegramBotView from '@/views/Profile/TelegramBot/TelegramBot'
import PersonalInformationView from '@/views/Profile/PersonalInformation/PersonalInformation'
import MyDataView from '@/views/Profile/MyData/MyData'
import EquipmentView from '@/views/Profile/Equipment/Equipment'
import LikeView from '@/views/Like/Like'
import WorkoutDetailView from '@/views/Workout/Workout'
import myGoalsView from '@/views/MyGoals/MyGoals'
import MyGoalsDetailView from '@/views/MyGoals/MyGoalsDetail/MyGoalsDetail'
import MyGoalsDetailCloseView from '@/views/MyGoals/MyGoalsDetailClose/MyGoalsDetailClose'
import PlansDetailView from '@/views/Plans/PlansDetail/PlansDetail'
import SubscriptionsView from '@/views/Subscriptions/Subscriptions'
import PaymentMethodsView from '@/views/Payments/PaymentMethods/PaymentMethods'
import AfterPaymentsView from '@/views/Payments/AfterPayments/AfterPayments'
import SignUpView from '@/views/SignUp/SignUp'

/* Типы */
import { RoutePaths, RouterItem } from '@/router/Types'

/* Обязательное промежуточное ПО */
import { RequiredMiddleware } from '@/middlewares/index'
import { PipelineMiddleware } from '@/middlewares/helpers/Pipeline'
import { Layouts } from '@/layouts'
import { RoutePathsProfile } from '@/views/Profile/Types'

/* Классы */
import i18n from '@/locales/index'

const routes: Array<RouterItem> = [
  {
    path: RoutePaths.index,
    name: 'start',
    component: StartView,
    meta: {
      layout: Layouts.Default,
      middleware: RequiredMiddleware,
    },
  },
  {
    path: RoutePaths.login,
    name: 'login',
    component: LoginView,
    meta: {
      layout: Layouts.Default,
      pageTitle: i18n.global.t('login.title'),
      middleware: RequiredMiddleware,
    },
  },
  {
    path: RoutePaths.quiz,
    name: 'quiz',
    component: QuizView,
    meta: {
      layout: Layouts.Default,
      pageTitle: i18n.global.t('pages.QuizView.title'),
      middleware: RequiredMiddleware,
    },
  },
  {
    path: RoutePaths.main,
    name: 'main',
    component: MainView,
    meta: {
      layout: Layouts.PersonalAccount,
      middleware: RequiredMiddleware,
      pageTitle: i18n.global.t('pages.MainView.title'),
      layoutParams: {
        isShowPreloader: true,
        isHideHeader: true,
      },
    },
  },
  {
    path: RoutePaths.goals,
    name: 'goals',
    meta: {
      layout: Layouts.PersonalAccount,
      middleware: RequiredMiddleware,
      pageTitle: i18n.global.t('pages.GoalsView.title'),
      layoutParams: {
        title: i18n.global.t('pages.GoalsView.title'),
        isShowPreloader: true,
      },
    },
    component: GoalsView,
  },
  {
    path: RoutePaths.goalsDetail,
    name: 'goalsDetail',
    meta: {
      layout: Layouts.PersonalAccount,
      middleware: RequiredMiddleware,
      layoutParams: {
        isHideHeader: false,
        isShowPreloader: true,
        classes: {
          'hide-background-color': true,
        },
      },
    },
    component: GoalsDetailView,
  },
  {
    path: RoutePaths.profile,
    name: 'profile',
    component: ProfileView,
    meta: {
      layout: Layouts.PersonalAccount,
      middleware: RequiredMiddleware,
      pageTitle: i18n.global.t('pages.Profile.title'),
      layoutParams: {
        title: i18n.global.t('pages.Profile.title'),
        isShowLogout: true,
        isShowPreloader: true,
      },
    },
  },
  {
    path: RoutePathsProfile.telegramBot,
    name: '',
    component: TelegramBotView,
    meta: {
      layout: Layouts.PersonalAccount,
      middleware: RequiredMiddleware,
      layoutParams: {
        isHideHeader: true,
        isShowPreloader: true,
      },
    },
  },
  {
    path: RoutePathsProfile.personalInformation,
    name: '',
    component: PersonalInformationView,
    meta: {
      layout: Layouts.PersonalAccount,
      middleware: RequiredMiddleware,
      pageTitle: i18n.global.t('pages.Profile.personalInformation.title'),
      layoutParams: {
        title: i18n.global.t('pages.Profile.personalInformation.title'),
        isShowPreloader: true,
      },
    },
  },
  {
    path: RoutePathsProfile.myData,
    name: '',
    component: MyDataView,
    meta: {
      layout: Layouts.PersonalAccount,
      middleware: RequiredMiddleware,
      pageTitle: i18n.global.t('pages.Profile.myData.title'),
      layoutParams: {
        title: i18n.global.t('pages.Profile.myData.title'),
        isShowPreloader: true,
      },
    },
  },
  {
    path: RoutePathsProfile.equipment,
    name: '',
    component: EquipmentView,
    meta: {
      layout: Layouts.PersonalAccount,
      middleware: RequiredMiddleware,
      pageTitle: i18n.global.t('pages.Profile.equipment.title'),
      layoutParams: {
        title: i18n.global.t('pages.Profile.equipment.title'),
        isShowPreloader: true,
      },
    },
  },
  {
    path: RoutePaths.like,
    name: 'like',
    component: LikeView,
    meta: {
      layout: Layouts.PersonalAccount,
      middleware: RequiredMiddleware,
      pageTitle: i18n.global.t('pages.LikeView.title'),
      layoutParams: {
        title: i18n.global.t('pages.LikeView.title'),
        isShowPreloader: true,
      },
    },
  },
  {
    path: RoutePaths.workoutDetail,
    name: 'workout',
    component: WorkoutDetailView,
    meta: {
      layout: Layouts.PersonalAccount,
      middleware: RequiredMiddleware,
      isSkipAuth: true,
      layoutParams: {
        title: ' ',
        classes: {
          'hide-background-color': true,
        },
        isShowPreloader: true,
      },
    },
  },
  {
    path: RoutePaths.myGoals,
    name: 'my-goals',
    component: myGoalsView,
    meta: {
      layout: Layouts.PersonalAccount,
      middleware: RequiredMiddleware,
      pageTitle: i18n.global.t('pages.MyGoalsPage.title'),
      layoutParams: {
        isHideHeader: true,
        isShowPreloader: true,
      },
    },
  },
  {
    path: RoutePaths.myGoalsDetail,
    name: 'my-goals-detail',
    component: MyGoalsDetailView,
    meta: {
      layout: Layouts.PersonalAccount,
      middleware: RequiredMiddleware,
      layoutParams: {
        isHideHeader: false,
        isShowPreloader: true,
        classes: {
          'hide-background-color': true,
        },
      },
    },
  },
  {
    path: RoutePaths.myGoalsDetailClose,
    name: 'my-goals-detail-close',
    component: MyGoalsDetailCloseView,
    meta: {
      layout: Layouts.PersonalAccount,
      middleware: RequiredMiddleware,
      pageTitle: i18n.global.t('pages.MyGoalsDetailCloseView.title'),
      layoutParams: {
        title: i18n.global.t('pages.MyGoalsDetailCloseView.title'),
        isShowPreloader: true,
      },
    },
  },
  {
    path: RoutePaths.plans,
    name: 'plans',
    component: PlansDetailView,
    meta: {
      layout: Layouts.PersonalAccount,
      middleware: RequiredMiddleware,
      layoutParams: {
        isHideHeader: true,
        isShowPreloader: true,
      },
    },
  },
  {
    path: RoutePaths.subscriptions,
    name: 'subscriptions',
    component: SubscriptionsView,
    meta: {
      layout: Layouts.PersonalAccount,
      middleware: RequiredMiddleware,
      pageTitle: i18n.global.t('pages.Subscriptions.title'),
      layoutParams: {
        title: i18n.global.t('pages.Subscriptions.title'),
        isShowPreloader: true,
      },
    },
  },
  {
    path: RoutePaths.paymentMethods,
    name: 'paymentMethods',
    component: PaymentMethodsView,
    meta: {
      layout: Layouts.PersonalAccount,
      middleware: RequiredMiddleware,
      pageTitle: i18n.global.t('pages.PaymentMethods.title'),
      layoutParams: {
        title: i18n.global.t('pages.PaymentMethods.title'),
        isShowPreloader: true,
      },
    },
  },
  {
    path: RoutePaths.afterPayments,
    name: 'afterPayments',
    component: AfterPaymentsView,
    meta: {
      layout: Layouts.PersonalAccount,
      middleware: RequiredMiddleware,
      pageTitle: i18n.global.t('pages.AfterPayments.title'),
      layoutParams: {
        title: i18n.global.t('pages.AfterPayments.title'),
        isShowBackButton: false,
      },
    },
  },
  {
    path: RoutePaths.signUp,
    name: 'signUp',
    component: SignUpView,
    meta: {
      layout: Layouts.Default,
      middleware: RequiredMiddleware,
      isSkipAuth: true,
      pageTitle: i18n.global.t('registration.title'),
      layoutParams: {
        title: i18n.global.t('registration.title'),
        isShowBackButton: false,
      },
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  const context = { to, from, next }
  const meta: RouterItem['meta'] = to.meta as RouterItem['meta']
  const middleware = meta.middleware ?? []

  if (!middleware.length) {
    return next()
  }

  return PipelineMiddleware(context, middleware, 0)()
})

export default router
