import { Response } from '@/types/API'
import { AuthMode } from '@/components/TheAuth/Types'

/* Классы */
import i18n from '@/locales/index'

export const HeaderData = [
  {
    title: i18n.global.t('pages.QuizView.headerData.step_1.title'),
    description: i18n.global.t('pages.QuizView.headerData.step_1.description'),
  },
  {
    title: i18n.global.t('pages.QuizView.headerData.step_2.title'),
    description: i18n.global.t('pages.QuizView.headerData.step_2.description'),
  },
  {
    title: i18n.global.t('pages.QuizView.headerData.step_3.title'),
    description: null,
  },
  {
    title: i18n.global.t('pages.QuizView.headerData.step_4.title'),
    description: i18n.global.t('pages.QuizView.headerData.step_4.description'),
  },
  {
    title: i18n.global.t('pages.QuizView.headerData.step_5.title'),
    description: null,
  },
  {
    title: i18n.global.t('pages.QuizView.headerData.step_6.title'),
    description: null,
  },
]

export type Item = {
  id: number,
  title: string,
  caption?: string | null
}

export interface Data {
  currentStep: number,
  disabledButton: boolean,
  isShowCodeModal: boolean,
  authMode: AuthMode
  disabledFields: {
    enterMode: boolean,
    loseWeigh: boolean,
    uploadProblem: boolean,
    keepFit: boolean,
    improvePerformance: boolean,
    other: boolean,
  },
  dataForQuestionnaire: {
    equipment_items: Item[]
    goals: Item[],
    levels: Item[],
    sports: Item[],
    workout_company: Item[],
  },
  formData: Record<string, number | number[] | string | null>
}

export type DataResponse = Response<{
  form: {
    equipment_items: Item[],
    goals: Item[],
    levels: Item[],
    sports: Item[],
    workout_company: Item[],
  }
}>

export type SendData = {
  referrer: string,
  email?: string,
  phone?: string,
  sex: string,
  age: number,
  growth: number,
  weight: number,
  level: number,
  workout_company: number,
  equipment_item_ids: number[],
  sports: number[],
  goals: number[],
  name: string,
}
