/* Функции */
import { defineComponent, PropType, VNode } from 'vue'

/* Компоненты */
import VLabel from '@/components/VLabel/VLabel'
import VIcon from '@/components/VIcon/VIcon'

/* Стили */
import '@/components/VLabelIcon/VLabelIcon.scss'

/* Типы */
import { defaultProps } from '@/components/VText/Types'

export default defineComponent({
  name: 'VLabelIcon',

  props: {
    ...defaultProps({
      defaultOpacity: 1,
      fontWeight: 700,
    }),

    /** Признак второстепенного отображения */
    isSecondary: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    /** Признак третьего отображения */
    isTertiary: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    /** Имя файла */
    imageName: {
      type: String as PropType<string>,
      default: '',
    },

    /** Цвет текста */
    color: {
      type: String as PropType<string>,
      default: '',
    },

    /** Цвет фона */
    background: {
      type: String as PropType<string>,
      default: '',
    },
  },

  computed: {
    styles() {
      const { color, background } = this

      return {
        color,
        background,
      }
    },
  },

  render(): VNode {
    return (
      <VLabel {...this.$props} style={this.styles} class="label-item-icon">
        {this.imageName ? <VIcon imageName={this.imageName} /> : null}

        {this.$slots.default?.()}
      </VLabel>
    )
  },
})
