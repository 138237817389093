/* Функции */
import {
  defineComponent, PropType, Transition, VNode, withModifiers,
} from 'vue'

/* Компоненты */
import VTextInputButton from '@/components/VText/VTextInputButton/VTextInputButton'
import VTextSmall from '@/components/VText/VTextSmall/VTextSmall'
import VIcon from '@/components/VIcon/VIcon'

/* Типы */
import { Data, InputType } from '@/components/VInput/VInputButton/Types'

/* Стили */
import '@/components/VInput/VInputButton/VInputButton.scss'

export default defineComponent({
  name: 'VInputButton',

  emits: {
    /** Событие изменения выбора */
    'update:checked': (value: string | null | number) => typeof value === 'string' || typeof value === 'number' || value === null,
    'update:checkbox': (value: boolean) => typeof value === 'boolean',
    'update:checkboxValue': (value: {value: string | null | number, status: boolean}) => (typeof value.value === 'string' || typeof value.value === 'number' || value.value === null) && typeof value.status === 'boolean',
  },

  data(): Data {
    return {
      valueCheckbox: false,
    }
  },

  props: {
    /** Имя */
    name: {
      type: String as PropType<string>,
      required: true,
    },
    /** Тип */
    type: {
      type: String as PropType<InputType>,
      default: InputType.checkbox,
    },
    /** Признак показывать ли иконку */
    isShowImage: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    /** Имя файла */
    imageName: {
      type: String as PropType<string>,
      default: '',
    },
    /** Имя файла */
    checkedImageName: {
      type: String as PropType<string>,
      default: '',
    },
    /** Описание (подпись) */
    description: {
      type: String as PropType<string>,
      default: '',
    },
    /** Значение для связывания */
    modelValue: {
      type: [String, null, Boolean, Number] as PropType<string | null | boolean | number>,
      default: null,
    },
    /** Значение */
    value: {
      type: [String, Number] as PropType<string | number>,
      default: '',
    },
    /** Отключение поля */
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    /** Вставлять ли svg в HTML */
    isIncludeSvgInHtml: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    /** Инлайновая кнопка */
    isInline: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    /** Отдавать значение, переданное в value.
     * По умолчанию поле работает с логическим типом.
     * Если передать значение, то будет активно событие checkboxValue
     */
    isCheckboxValue: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },

  computed: {
    /** Список классов */
    classes() {
      return {
        'input-button': true,
        'input-radio': this.type === InputType.radio,
        'input-checkbox': this.type === InputType.checkbox,
        disabled: this.disabled,
        checked: this.checked,
        inline: this.isInline,
      }
    },
    /** Выбран ли текущий пункт */
    checked(): boolean {
      switch (this.type) {
        case InputType.radio:
          return this.value === this.modelValue
      }

      return this.valueCheckbox
    },
  },

  methods: {
    /** Обработчик изменений */
    onChange() {
      switch (this.type) {
        case InputType.checkbox:
          this.valueCheckbox = !this.valueCheckbox
          this.$emit('update:checkbox', this.valueCheckbox)
          if (this.isCheckboxValue) {
            this.$emit('update:checkboxValue', {
              value: this.value,
              status: this.valueCheckbox,
            })
          }
          break
        default:
          this.$emit('update:checked', this.value)
      }
    },
  },

  watch: {
    /** Наблюдает за изменением значений для связывания */
    modelValue() {
      if (typeof this.modelValue === 'boolean') {
        this.valueCheckbox = this.modelValue
      }
    },
  },

  created() {
    if (typeof this.modelValue === 'boolean') {
      this.valueCheckbox = this.modelValue
    }
  },

  render(): VNode {
    const checkedImage = () => (this.checkedImageName ? <VIcon
      imageName={this.checkedImageName}
      isIncludeSvgInHtml={this.isIncludeSvgInHtml}
    /> : null)

    return (
      <label class={this.classes}>
        <input
          class={'input-button__field'}
          type={this.type}
          name={this.name}
          disabled={this.disabled}
          onInput={withModifiers(() => undefined, ['prevent'])}
          onChange={this.onChange}
          checked={this.checked}
        />

        <div class={'input-button__container'}>
          <div class={'input-button__container-left'}>
            <VTextInputButton>{this.$slots.default?.()}</VTextInputButton>

            <VTextSmall>{this.description}</VTextSmall>
          </div>

          {
            this.isShowImage
              ? <div class={'input-button__container-right'}>
                <Transition name={'fade'} mode={'out-in'}>
                  {
                    !this.checked || !this.checkedImageName
                      ? <VIcon
                        imageName={this.imageName}
                        isIncludeSvgInHtml={this.isIncludeSvgInHtml}
                      />
                      : checkedImage()
                  }
                </Transition>
              </div>
              : null
          }
        </div>
      </label>
    )
  },
})
