import Default from '@/layouts/Default/Default'
import PersonalAccount from '@/layouts/PersonalAccount/PersonalAccount'

import { Component } from 'vue'

export enum Layouts {
  Default = 'Default',
  PersonalAccount = 'PersonalAccount',
}

export type LayoutValues = `${Layouts}`

export const LayoutList: Record<LayoutValues, Component> = Object.freeze({
  Default,
  PersonalAccount,
})

export type PersonalAccountParams = {
  title?: string,
  isShowBackButton?: boolean
  isShowLogout?: boolean
  classes?: Record<string, boolean>
  isShowMenu?: boolean,
  isShowPreloader?: boolean
  isHideHeader?: boolean
}
