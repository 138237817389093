import i18n from '@/locales'
import { BannerBackgroundColor, BannerMode } from '../VBanner/Types'
import { TypeButtons } from '../VButton/Types'

export interface Data {
  isShow: boolean,
  isMobile: boolean,
  isTelegramActive: boolean,
  telegramToken: string | null,
}

export const Banners = [
  {
    mobile: {
      text: i18n.global.t('banners.bannersMobile.1.text'),
      button: i18n.global.t('banners.bannersMobile.1.buttons.active'),
      iconName: 'banner_telegram',
      background: BannerBackgroundColor.primary,
      mode: BannerMode.default,
      typeButtons: { [TypeButtons.Small]: true, [TypeButtons.Tertiary]: true },

    },
    desktop: {
      titleIcon: i18n.global.t('banners.bannersDesktop.1.titleIcon'),
      iconButton: 'telegram-black',
      iconName: 'banner_telegram',
      text: [
        i18n.global.t('banners.bannersDesktop.1.texts.1'),
        i18n.global.t('banners.bannersDesktop.1.texts.2'),
        i18n.global.t('banners.bannersDesktop.1.texts.3'),
        i18n.global.t('banners.bannersDesktop.1.texts.4'),
      ],
      button: i18n.global.t('banners.bannersDesktop.1.buttons.active'),
      background: BannerBackgroundColor.primary,
      mode: BannerMode.top,
      typeButtons: { },
    },
  },
  {
    mobile: {
      text: i18n.global.t('banners.bannersMobile.2.text'),
      button: i18n.global.t('banners.bannersMobile.2.buttons.active'),
      iconName: 'banner_telegram',
      background: BannerBackgroundColor.primary,
      mode: BannerMode.default,
      typeButtons: { [TypeButtons.Small]: true, [TypeButtons.Tertiary]: true },
    },
    desktop: {
      titleIcon: i18n.global.t('banners.bannersDesktop.2.titleIcon'),
      iconButton: 'telegram-black',
      iconName: 'banner_telegram',
      text: [
        i18n.global.t('banners.bannersDesktop.2.texts.1'),
        i18n.global.t('banners.bannersDesktop.2.texts.2'),
        i18n.global.t('banners.bannersDesktop.2.texts.3'),
        i18n.global.t('banners.bannersDesktop.2.texts.4'),
      ],
      button: i18n.global.t('banners.bannersDesktop.2.buttons.active'),
      background: BannerBackgroundColor.secondary,
      mode: BannerMode.top,
      typeButtons: { [TypeButtons.Sixfold]: true },
    },
  },
  {
    mobile: {
      text: i18n.global.t('banners.bannersMobile.3.text'),
      button: i18n.global.t('banners.bannersMobile.3.buttons.active'),
      iconName: 'banner_telegram',
      background: BannerBackgroundColor.secondary,
      mode: BannerMode.top,
      typeButtons: { [TypeButtons.Small]: true, [TypeButtons.Sixfold]: true },
    },
    desktop: {
      titleIcon: i18n.global.t('banners.bannersDesktop.1.titleIcon'),
      iconButton: 'telegram-black',
      iconName: 'banner_telegram',
      text: [
        i18n.global.t('banners.bannersDesktop.1.texts.1'),
        i18n.global.t('banners.bannersDesktop.1.texts.2'),
        i18n.global.t('banners.bannersDesktop.1.texts.3'),
        i18n.global.t('banners.bannersDesktop.1.texts.4'),
      ],
      button: i18n.global.t('banners.bannersDesktop.1.buttons.active'),
      background: BannerBackgroundColor.primary,
      mode: BannerMode.top,
      typeButtons: { },
    },
  },
  {
    mobile: {
      text: i18n.global.t('banners.bannersMobile.4.text'),
      button: i18n.global.t('banners.bannersMobile.4.buttons.active'),
      iconName: 'banner_telegram',
      background: BannerBackgroundColor.primary,
      mode: BannerMode.default,
      typeButtons: { [TypeButtons.Small]: true, [TypeButtons.Tertiary]: true },
    },
    desktop: {
      titleIcon: i18n.global.t('banners.bannersDesktop.2.titleIcon'),
      iconButton: 'telegram-black',
      iconName: 'banner_telegram',
      text: [
        i18n.global.t('banners.bannersDesktop.2.texts.1'),
        i18n.global.t('banners.bannersDesktop.2.texts.2'),
        i18n.global.t('banners.bannersDesktop.2.texts.3'),
        i18n.global.t('banners.bannersDesktop.2.texts.4'),
      ],
      button: i18n.global.t('banners.bannersDesktop.2.buttons.active'),
      background: BannerBackgroundColor.secondary,
      mode: BannerMode.top,
      typeButtons: { [TypeButtons.Sixfold]: true },
    },
  },
]
