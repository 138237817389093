/* Функции */
import { defineComponent, PropType, VNode } from 'vue'

/* Компоненты */
import VRow from '@/components/VRow/VRow'

/* Стили */
import '@/components/BaseButtonMenuInline/BaseButtonMenuInline.scss'
import VTextButton from '../VText/VTextButton/VTextButton'
import VIcon from '../VIcon/VIcon'
import VTextSubButton from '../VText/VTextSubButton/VTextSubButton'

export default defineComponent({
  name: 'BaseButtonMenuInline',

  props: {
    title: {
      type: String as PropType<string>,
      default: '',
    },
    link: {
      type: String as PropType<string>,
      default: '',
    },
    description: {
      type: String as PropType<string>,
      default: '',
    },
    imageName: {
      type: String as PropType<string>,
      default: 'just_arrow_right',
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },

  render(): VNode {
    const content = [
      <VRow justify={'space-between'}>
        <VRow class={'button-menu-inline__text'} justify={'space-between'}>
          <VTextButton>{!this.title ? this.$slots.default?.() : this.title}</VTextButton>

          {this.$slots.afterTitle?.().length ? this.$slots.afterTitle() : null}
        </VRow>

        <VIcon
          class={'button-menu-inline__icon'}
          imageName={this.imageName}
        />
      </VRow>,

      this.description
        ? <VTextSubButton opacity={0.7} class={'button-menu-inline-bottom'}>{this.description}</VTextSubButton>
        : null,
    ]
    return (
      this.disabled
        ? <div class={'button-menu-inline disabled'}>{content}</div>
        : <router-link class={'button-menu-inline'} to={this.link} disabled={this.disabled}>
          {content}
        </router-link>
    )
  },
})
