/* Функции */
import { defineComponent, PropType, VNode } from 'vue'

/* Типы */
import { Data } from '@/components/VSteps/Types'

/* Стили */
import '@/components/VSteps/VSteps.scss'

export default defineComponent({
  name: 'VSteps',

  emits: {
    /** Событие обновления значения */
    'update:modelValue': (value: number) => typeof value === 'number',
  },

  data(): Data {
    return {
      /** Выбранный номер шага */
      currentStep: 0,
    }
  },

  props: {
    /** Имя параметра */
    queryParamName: {
      type: String as PropType<string>,
      default: 'step',
    },
    /** Значение связывания */
    modelValue: {
      type: Number as PropType<number>,
      required: true,
    },

    /** Признак возврата в начало; Используется при перезагрузке страницы */
    isGoToStartAfterReboot: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },

  computed: {
    /** GET параметры */
    query() {
      return this.$route.query
    },
  },

  methods: {
    /**
     * Метод установки шагов
     * @param step - Номер шага
     */
    setStep(step: number) {
      const query: Record<string, string> = {}

      if (step) {
        query.step = String(step)
      }

      this.currentStep = step ?? 0

      this.$emit('update:modelValue', this.currentStep)

      this.$router.push({
        path: this.$router.currentRoute.value.path,
        query,
      })
    },

    /** Начальная установка параметров */
    startQuery() {
      const { query } = this.$route
      const step = query?.[this.queryParamName]

      if (!step) {
        this.setStep(0)
        return
      }

      this.setStep(Number(step))
    },
  },

  created() {
    if (this.isGoToStartAfterReboot) {
      this.currentStep = 0
      this.$emit('update:modelValue', this.currentStep)
      this.$router.push({
        path: this.$router.currentRoute.value.path,
      })
    } else {
      this.startQuery()
      this.currentStep = this.modelValue
    }
  },

  watch: {
    query() {
      this.startQuery()
    },

    modelValue() {
      this.setStep(this.modelValue)
    },
  },

  render(): VNode {
    return (
      <div class={'steps'}>
        {this.$slots.default?.().length ? this.$slots.default() : null}
      </div>
    )
  },
})
