/* Функции */
import {
  defineComponent, InputTypeHTMLAttribute, PropType, ref, VNode,
} from 'vue'

/* Компоненты */
import VText from '@/components/VText/VText/VText'
import VTextPlaceholder from '@/components/VText/VTextPlaceholder/VTextPlaceholder'

/* Типы */
import { ValidationResult, ValidationRule } from '@/components/Validation/Validation'
import { Data, InputMode } from './Types'

/* Стили */
import '@/components/VInput/VInputArea/VInputArea.scss'

export default defineComponent({
  name: 'VInputArea',

  emits: {
    /** Событие обновления значения */
    'update:modelValue': (value: { value: string, event: Event }) => typeof value.value === 'string',
    /** Событие изменения валидности */
    'change:valid': (value: boolean) => typeof value === 'boolean',
  },

  data(): Data {
    return {
      value: '',
      focus: false,
      isValid: false,
      errorMessage: '',
    }
  },

  props: {
    /** Подпись */
    title: {
      type: String as PropType<string>,
      default: '',
    },

    /** Заполнитель */
    placeholder: {
      type: String as PropType<string>,
      default: '',
    },

    /** Значение связывания */
    modelValue: {
      type: String as PropType<string>,
      default: '',
    },

    /** Тип */
    type: {
      type: String as PropType<InputTypeHTMLAttribute>,
      default: 'text',
    },

    /** Отключение поля */
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    /** Скрывать Заполнитель */
    isHidePlaceholder: {
      type: Boolean as PropType<boolean>,
      default: true,
    },

    /** Стандартный шаблон ввода данных */
    pattern: {
      type: String as PropType<string>,
      default: '',
    },

    /** Правила валидации */
    rules: {
      type: Array as PropType<ValidationRule[]>,
      default: () => [],
    },

    /** Режим ввода */
    inputmode: {
      type: String as PropType<InputMode>,
      default: undefined,
    },

    isSecondary: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    postfix: {
      type: String as PropType<string>,
      default: '',
    },
  },

  setup() {
    const inputField = ref<HTMLInputElement>()

    return {
      inputField,
    }
  },

  computed: {
    classes() {
      return {
        'is-secondary': this.isSecondary,
        'input-area': true,
      }
    },
  },

  methods: {
    onChange(event: Event) {
      const target = event.target as HTMLInputElement
      const value = target?.value ?? ''
      this.value = value

      this.$emit('update:modelValue', { value, event })
    },
  },

  watch: {
    /** Наблюдает за изменением значения */
    modelValue: {
      async handler() {
        const checkValidationResult = (
          result: ValidationResult | PromiseLike<ValidationResult>,
        ) => {
          if (typeof result === 'string') {
            this.isValid = false
            this.errorMessage = result
          } else if (result === false) {
            this.isValid = false
            this.errorMessage = ''
          } else if (result === true) {
            this.isValid = true
            this.errorMessage = ''
          }
        }

        for (let index = 0; index < this.rules.length; index += 1) {
          const rule = this.rules[index]

          if (typeof rule === 'function') {
            if (rule instanceof Promise) {
              const result = await rule(this.modelValue)
              checkValidationResult(result)
            } else {
              checkValidationResult(rule(this.modelValue))
            }
          } else {
            checkValidationResult(rule)
          }
        }

        if (this.inputField) {
          this.inputField.value = this.modelValue
        }
      },
    },
    /** Наблюдает за изменением валидности. Генерирует событие */
    isValid() {
      this.$emit('change:valid', this.isValid)
    },
  },

  render(): VNode {
    const isShowPlaceholder = this.isHidePlaceholder ? (!this.focus && !this.modelValue) : true

    return (
      <label class={this.classes}>
        <VText>{this.title}</VText>

        <input
          ref={'inputField'}
          class={'input-area__field'}
          value={this.modelValue}
          disabled={this.disabled}
          onInput={this.onChange}
          onFocus={() => { this.focus = true }}
          onBlur={() => {
            this.focus = false
            if (this.inputField) {
              this.inputField.value = this.modelValue
            }
          }}
          type={this.type}
          pattern={this.pattern}
          inputmode={this.inputmode}
        />

        {isShowPlaceholder && <VTextPlaceholder>{this.placeholder}</VTextPlaceholder>}
      </label>
    )
  },
})
