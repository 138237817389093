/* Функции */
import { defineComponent, PropType, VNode } from 'vue'

/* Компоненты */
import VTextSubTitle from '@/components/VText/VTextSubTitle/VTextSubTitle'
import VTextHeading1 from '@/components/VText/VTextHeading1/VTextHeading1'
import VRow from '@/components/VRow/VRow'
import VIcon from '@/components/VIcon/VIcon'
import VTextParagraph from '@/components/VText/VTextParagraph/VTextParagraph'
import VImg from '@/components/VImg/VImg'
import BasePlanLabel from '@/components/BasePlanLabel/BasePlanLabel'

/* Стили */
import '@/components/BaseTargetHeader/BaseTargetHeader.scss'

/* Помощники */
import dayjs from 'dayjs'
import { toCapitalized } from '@/helpers/common'

export default defineComponent({
  name: 'BaseTargetHeader',

  props: {
    /** Путь до изображения. Указывается без хоста */
    src: {
      type: String as PropType<string>,
      required: true,
    },
    /** Путь к изображению при ошибки загрузки. Относительно пути @/assets/img/pictures/ */
    failLoadSrc: {
      type: String as PropType<string | null>,
      default: null,
    },
    /** Продолжительность */
    duration: {
      type: Number as PropType<number>,
      default: 0,
    },
    /** Циклы */
    cycle: {
      type: Number as PropType<number>,
      default: 0,
    },
    /** Подпись */
    subTitle: {
      type: String as PropType<string>,
      default: '',
    },
    /** Заголовок */
    title: {
      type: String as PropType<string>,
      default: '',
    },
    /** Инвентарь */
    equipment: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    /** Альтернативное описание */
    alt: {
      type: String as PropType<string>,
      default: '',
    },
    /** Высота */
    height: {
      type: Number as PropType<number>,
      default: 375,
    },
    /** Отображаемая дата. Заменяет считаемую дату по-умолчанию. Тут уже должны быть отформатированные данные */
    showDate: {
      type: String as PropType<string>,
      default: '',
    },
    /** Список меток */
    labels: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },

  computed: {
    /** Конечная дата продолжительность */
    startDate() {
      return dayjs().format('D MMMM')
    },
    /** Конечная дата продолжительность */
    endDate() {
      return dayjs().add(this.duration, 'day').format('D MMMM')
    },
    /** Отображаемая продолжительность */
    showDuration() {
      if (this.duration) {
        return `${this.startDate} - ${this.endDate}`
      }

      return ''
    },
    /** Отображаемый инвентарь */
    showEquipment() {
      return toCapitalized(this.equipment.join(', ').toLocaleLowerCase())
    },
    /** Отображать ли блок с инвентарем */
    isShowEquipment() {
      return Boolean(this.equipment.length)
    },

    /** Высота */
    styles() {
      return {
        height: `${this.height}px`,
      }
    },
  },

  render(): VNode {
    const {
      title,
      subTitle,
      isShowEquipment,
      showEquipment,
      showDuration,
      showDate,
      cycle,
      failLoadSrc,
      src,
      alt,
      styles,
      labels,
    } = this
    const equipment = isShowEquipment ? (
      <VRow class={'plan-card__equipment'} alignItems={'center'}>
        <VIcon
          class={'plan-card__equipment_icon'}
          imageName={'equipment'}
        />

        <VTextParagraph opacity={0.7}>{showEquipment}</VTextParagraph>
      </VRow>
    ) : null

    return (
      <VRow
        tag='article'
        direction={'column'}
        justify={'end'}
        class={'target-header'}
        style={styles}
      >
        <VImg
          key={`${src}-${alt}-${failLoadSrc}`}
          class={'target-header__img'}
          src={src}
          alt={alt}
          failLoadSrc={failLoadSrc}
        />

        <VRow class={'target-header__labels'} direction={'column'}>
          {labels.map((label) => <BasePlanLabel label={label} position={'left'}/>)}
        </VRow>

        <VRow class={'target-header__top'} direction={'column'}>
          <VTextSubTitle class={'target-header__subtitle'}>{`[ ${subTitle} ]`}</VTextSubTitle>

          <VTextHeading1 class={'target-header__title'}>{title}</VTextHeading1>

          {this.cycle || this.duration ? <VRow justify={'space-between'}>
            <VTextParagraph opacity={1} class={'target-header__duration'}>{showDate || showDuration}</VTextParagraph>

            <VTextParagraph opacity={1} class={'target-header__cycle'}>
              {this.$t('targets.cycles', { cycle }, cycle)}
            </VTextParagraph>
          </VRow> : null
          }

          {equipment}
        </VRow>
      </VRow>)
  },
})
