/* Функции */
import { defineComponent, PropType, VNode } from 'vue'

/* Компоненты */
import VRow from '@/components/VRow/VRow'
import VSlideUpSlideDown from '@/components/VSlideUpSlideDown/VSlideUpSlideDown'
import VText from '@/components/VText/VText/VText'
import VIcon from '@/components/VIcon/VIcon'
import VMoreText from '@/components/VMoreText/VMoreText'

/* Стили */
import '@/components/BaseTargetCycle/BaseTargetCycle.scss'

/* Помощники */
import dayjs from 'dayjs'

/* Типы */
import { Status } from './Types'

export default defineComponent({
  name: 'BaseTargetCycle',

  props: {
    /** Описание */
    description: {
      type: String as PropType<string>,
      default: '',
    },
    /** Заголовок */
    title: {
      type: String as PropType<string>,
      default: '',
    },
    /** Подходы */
    workoutsCount: {
      type: Number as PropType<number>,
      default: 0,
    },
    /** Дата начала */
    startDate: {
      type: String as PropType<string>,
      default: '',
    },
    /** Дата конца */
    endDate: {
      type: String as PropType<string>,
      default: '',
    },
    /** Статус */
    status: {
      type: String as PropType<Status>,
      default: 'unavailable',
    },
    /** Идентификатор */
    id: {
      type: String as PropType<string>,
      default: '',
    },
  },

  computed: {
    /** Недели */
    weeks() {
      const startDate = dayjs(this.startDate)
      const endDate = dayjs(this.endDate)

      const diff = endDate.diff(startDate, 'days')

      return Math.ceil(diff / 7)
    },
    /** Отображаемая дата */
    showDate() {
      return `${dayjs(this.startDate).format('DD MMMM')} - ${dayjs(this.endDate).format('DD MMMM')}`
    },
    /** CSS классы */
    classes() {
      return {
        'target-cycle': true,
        [this.status]: true,
      }
    },
  },

  render(): VNode {
    return (
      <VRow class={this.classes} key={this.id}>
        <div class={'target-cycle__left'}>
          <VIcon class={'target-cycle__icon'} imageName={this.status === 'done' ? 'current' : 'circle'} />

        </div>

        <div class={'target-cycle__right'}>
          <VSlideUpSlideDown
            title={this.title}
            key={`target-cycle-transition-${this.id}`}
            id={this.id}
            isShow={this.status !== 'done'}
          >
            <VText tag={'div'} class={'target-cycle__title'}>
              {`${this.$t('targets.cyclePeriod', { week: this.weeks }, this.weeks)} `}

              <VText class={'target-cycle__period'}>{`[${this.showDate}]`}</VText>
            </VText>

            <VText tag={'div'} class={'target-cycle__description'}>{`${this.$t('targets.cycleWorkoutWeek')} ${this.workoutsCount}`}</VText>

            <VMoreText>{this.description}</VMoreText>
          </VSlideUpSlideDown>
        </div>
      </VRow>
    )
  },
})
