/* Функции */
import {
  defineComponent, HTMLAttributes, inject, PropType, VNode,
} from 'vue'

/* Компоненты */
import VButton from '@/components/VButton/VButton'

/* Стили */
import '@/components/VButton/VButtonIcon/VButtonFixes/VButtonFixes.scss'

export default defineComponent({
  name: 'VFixesButton',

  props: {
    /** Тег */
    tag: {
      type: String as PropType<string>,
      default: 'button',
    },

    /** Имя файла */
    imageName: {
      type: String as PropType<string>,
      default: '',
    },

    /** Вставлять ли svg в HTML */
    isIncludeSvgInHtml: {
      type: Boolean as PropType<boolean>,
      default: true,
    },

    /** Признак второй кнопки */
    isSecondary: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    /** Признак третьей кнопки */
    isTertiary: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    /** Признак четвертой кнопки */
    isQuaternary: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    /** Признак пятой кнопки */
    isFivefold: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    /** Признак блокировки */
    isDisabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    /** Обработчик клика */
    onClick: {
      type: Function as PropType<HTMLAttributes['onClick']>,
    },

    /** Фон кнопки */
    backgroundColor: {
      type: String as PropType<string>,
      default: '',
    },

    /** Во всю ширину */
    isFullWidth: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    /** Цвет текста */
    colorText: {
      type: String as PropType<string>,
      default: null,
    },

    positionIcon: {
      type: String as PropType<'left' | 'right'>,
      default: 'right',
    },
  },

  setup() {
    const colorValue = inject<{ colorValue: string } | null>('colorValue', null)

    return {
      colorValue,
    }
  },

  computed: {
    styles() {
      return {
        background: this.backgroundColor || this.colorValue || null,
        color: this.colorText || null,
      }
    },

    classes() {
      return {
        'is-full-width': this.isFullWidth,
        'button-fixes': true,
      }
    },
  },

  created() {
    document.body.classList.add('find-fixed-button')
  },

  unmounted() {
    document.body.classList.remove('find-fixed-button')
  },

  render(): VNode {
    return (
      <VButton
        style={this.styles}
        class={this.classes}
        {...this.$props}
      >
        {this.$slots.default?.()}
      </VButton>
    )
  },
})
