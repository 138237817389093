/** Количество отображаемых дней */
export const DaysWeeksView = 14

export type DayNamesByDate = {
  name: string,
  date: string,
  count: number
  completed: number
}

export type WorkoutByDate = Record<string, {
  all: number,
  completed: number
}>

export const FormatDate = 'MM/DD/YYYY'

export interface Data {
  currentWeek: number,
  weeksNamesByDate: DayNamesByDate[]
}

export const CurrentAllWorkoutValue = 'current-week'
