/* Функции */
import {
  defineComponent, h, HTMLAttributes, PropType, VNode,
} from 'vue'

/* Компоненты */
import VRow from '@/components/VRow/VRow'
import VText from '@/components/VText/VText/VText'
import VTextParagraph from '@/components/VText/VTextParagraph/VTextParagraph'
import VImg from '@/components/VImg/VImg'

/* Стили */
import '@/components/BaseWorkoutStep/BaseWorkoutStep.scss'

export default defineComponent({
  name: 'BaseWorkoutStep',

  props: {
    /** Заголовок */
    title: {
      type: String as PropType<string>,
      default: '',
    },

    /** Путь к картинке */
    src: {
      type: String as PropType<string>,
      default: '',
    },

    /** Продолжительность */
    duration: {
      type: String as PropType<string>,
      required: true,
    },

    /** Клик на карточку целиком */
    onClick: {
      type: Function as PropType<HTMLAttributes['onClick']>,
    },
  },

  computed: {
    /** Картинки при неудачной загрузке */
    failRandomImage() {
      return `workout_default_${Math.floor(Math.random() * 3) + 1}.png`
    },
  },

  render(): VNode {
    return (
      <VRow class={'workout-step'} alignItems={'stretch'} justify={'space-between'}>
        <VRow class={'workout-step__left'} direction={'column'} justify={'space-between'}>
          {h(VText, { innerHTML: this.title })}

          <VTextParagraph opacity={0.7}>
            {this.duration}
          </VTextParagraph>
        </VRow>

        <VRow class={'workout-step__right'}>
          <VImg
            class={'workout-step__img'}
            failLoadSrc={this.failRandomImage}
            src={this.src}
            alt={' '}
          />
        </VRow>
      </VRow>
    )
  },
})
