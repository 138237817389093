import { UserLocalStorageNames } from '@/types/enums/user'
import { defineStore } from 'pinia'

import { jwtDecode } from 'jwt-decode'

export interface UserState {
  userId: number | null,
  expires: Date | null,
  token: string | null,
  isActiveTelegram: boolean,
  referrer: string | null,
  countShowTelegramBanners: number | null,
  telegramBannersIsClosedByToken: string | null,
}

export type DataJWT = {
  id: number,
  expires_at: string
}

export const UserStore = defineStore('user', {
  state: (): UserState => ({
    userId: null,
    expires: null,
    token: localStorage.getItem(UserLocalStorageNames.Token) ?? null,
    isActiveTelegram: Boolean(localStorage.getItem(UserLocalStorageNames.TelegramBotId) ?? null),
    countShowTelegramBanners: localStorage.getItem(UserLocalStorageNames.CountShowTelegramBanners) ? Number(localStorage.getItem(UserLocalStorageNames.CountShowTelegramBanners)) : null,
    telegramBannersIsClosedByToken: localStorage.getItem(UserLocalStorageNames.TelegramBannersIsClosedByToken) ?? null,
    referrer: null,
  }),

  getters: {
    /** Время жизни токена. Возвращаем функцию, чтобы отследить изменения в localStorage */
    expiresToken() {
      return () => {
        const expires = localStorage.getItem(UserLocalStorageNames.Expires)
        const expiresTime = expires ? new Date(expires) : null

        return expiresTime
      }
    },

    /** Авторизован ли пользователь */
    isAuth() {
      return () => {
        const { token, isValidDateToken } = this

        return Boolean(token) && isValidDateToken()
      }
    },

    /** Валиден ли токен */
    isValidDateToken() {
      return () => {
        const { expiresToken: expiresTokenFn } = this
        const expiresToken = expiresTokenFn()
        let isValidDateToken = false

        if (expiresToken && expiresToken instanceof Date) {
          isValidDateToken = new Date().getTime() <= expiresToken.getTime()
        }

        return isValidDateToken
      }
    },

    /** Ключ телеграм бота */
    telegramBotId() {
      return () => localStorage.getItem(UserLocalStorageNames.TelegramBotId)
    },
  },

  actions: {
    /**
     * Установка идентификатора пользователя
     * @param id - Идентификатор пользователя
     */
    setUserId(id: number) {
      this.userId = id
    },

    /**
     * Установка времени жизни токена.
     * Логика работы с временем:
     * Смотрим на время авторизации и на токен.
     * Если время вышло, то направляем на авторизацию.
     * @param expires - Время жизни токена
     */
    setExpiresToken(expires: string | null) {
      if (expires) {
        localStorage.setItem(UserLocalStorageNames.Expires, expires)
        this.expires = new Date(expires)
      }
    },

    /**
     * Устанавливает значение счетчика показа баннера
     * @param count Текущий баннер
     */
    setCountTelegram(count: number) {
      localStorage.setItem(UserLocalStorageNames.CountShowTelegramBanners, String(count))
      this.countShowTelegramBanners = count
    },

    /**
     * Установка токена
     * @param token - токен
     */
    setToken(token: string) {
      localStorage.setItem(UserLocalStorageNames.Token, token)
      this.token = token
    },

    /**
     * Вход по ключу. Устанавливает все параметры для дальнейшей аутентификации.
     * @param JWT - JSON токен
     */
    login(JWT: string) {
      const decoded = jwtDecode(JWT) as DataJWT

      this.setExpiresToken(decoded.expires_at)
      this.setUserId(decoded.id)
      this.setToken(JWT)
    },

    /** Выход */
    logout() {
      localStorage.clear()
      this.$reset()
    },

    /** Установка токена */
    setTelegramId(token: string | null) {
      if (!token) {
        return
      }

      localStorage.setItem(UserLocalStorageNames.TelegramBotId, token)
      this.isActiveTelegram = true
    },

    /** Установка параметров */
    setReferrer(referrer: string | null) {
      if (!referrer) {
        return
      }

      this.referrer = referrer
    },
  },
})
