import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { notify } from '@kyvg/vue3-notification'
import { Response } from '@/types/API'

import { UserStore } from '@/store/user'

export interface IConfigRequest {
  path: string
}

export interface Post {
  path: string
}

export interface IRequestAPI {
  path: string,
  getPath(url: string): string
  post<D = any, T = any>(url: string, data?: D, config?: AxiosRequestConfig<D>):
    Promise<AxiosResponse<T, any>>
  put<D = any, T = any>(url: string, data?: D, config?: AxiosRequestConfig<D>):
    Promise<AxiosResponse<T, any>>
  options<T = any>(url: string): Promise<AxiosResponse<T, any>>
  delete<T = any>(url: string): Promise<AxiosResponse<T, any>>
  <T = any>(url: string): Promise<AxiosResponse<T, any>>
  get<T = any>(url: string): Promise<AxiosResponse<T, any>>
}

export type IFunctionList = IRequestAPI['post'] | IRequestAPI['put'] | IRequestAPI['delete'] | IRequestAPI['get']

export class RequestAPI {
  private path = ''

  constructor(config: IConfigRequest) {
    this.path = config.path
  }

  private static getPath(url: string): string {
    const path = process.env.VUE_APP_URL
    return `${path}${url}`
  }

  public static async post<D = any, T = any>(url: string, data?: D, config?: AxiosRequestConfig<D>):
    Promise<AxiosResponse<T, any>> {
    const header: Record<string, any> = { headers: {} }
    const { token } = UserStore()

    if (token) {
      header.headers.Authorization = `${token}`
    }

    const result = await axios.post<T>(
      this.getPath(url),
      data,
      { ...header, ...config },
    )

    return result
  }

  public static async put<D = any, T = any>(url: string, data?: D, config?: AxiosRequestConfig<D>):
    Promise<AxiosResponse<T, any>> {
    const header: Record<string, any> = { headers: {} }
    const { token } = UserStore()

    if (token) {
      header.headers.Authorization = `${token}`
    }

    const result = await axios.put<T>(
      this.getPath(url),
      data,
      { ...header, ...config },
    )

    return result
  }

  public static async options<T = any>(url: string): Promise<AxiosResponse<T, any>> {
    const result = await axios.options<T>(
      this.getPath(url),
    )

    return result
  }

  public static async delete<T = any>(url: string): Promise<AxiosResponse<T, any>> {
    const header: Record<string, any> = { headers: {} }
    const { token } = UserStore()

    if (token) {
      header.headers.Authorization = `${token}`
    }

    const result = await axios.delete<T>(
      this.getPath(url),
      header,
    )

    return result
  }

  public static async get<T = any>(url: string): Promise<AxiosResponse<T, any>> {
    const header: Record<string, any> = { headers: {} }
    const { token } = UserStore()

    if (token) {
      header.headers.Authorization = `${token}`
    }

    const result = await axios.get<T>(
      this.getPath(url),
      header,
    )

    return result
  }

  public static isAxiosError = axios.isAxiosError

  public static async errorHandler<T = any, D = any>(
    fn: IFunctionList,
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>,
    textError?: string,
  ):
    Promise<AxiosResponse<T, any> | void> {
    try {
      if (typeof fn === 'function') {
        return await fn.call(this, url, data, config) as AxiosResponse<T, any>
      }
    } catch (error) {
      console.log(error)
      if (axios.isAxiosError(error)) {
        const { response } = error
        const data = response?.data as Response<T>

        if (data?.response?.errors?.length) {
          const firstErrorMessage = data.response.errors[0].message

          notify({
            title: 'Ошибка',
            text: textError ?? firstErrorMessage,
            type: 'error',
          })
        } else {
          notify({
            title: 'Ошибка',
            text: textError ?? 'Произошла неизвестная ошибка',
            type: 'error',
          })
        }
      }

      console.error(error)
    }
  }
}
