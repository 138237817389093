import {
  PaymentMethod, PaymentOptions, Plan, Subscription,
} from '@/types/API'

export interface Data {
  tab: number | string,
  subscriptions: Subscription[]
  savedMethods: PaymentMethod[]
  isLoadData: boolean
  isLoadPlan: boolean
  plans: Plan[]
  currentPayment: PaymentOptions['id'] | null
}

export enum TabValue {
  offers = 'offers',
  subscriptions= 'subscriptions'
}
