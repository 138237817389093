/* Функции */
import { defineComponent, PropType, VNode } from 'vue'

/* Стили */
import '@/components/VContent/VContentItem/VContentItem.scss'

export default defineComponent({
  name: 'VContentItem',

  props: {
    isPaddingX: {
      type: [Boolean] as PropType<boolean>,
      default: false,
    },
    isPaddingY: {
      type: [Boolean] as PropType<boolean>,
      default: false,
    },
    padding: {
      type: String as PropType<string>,
      default: null,
    },
    margin: {
      type: String as PropType<string>,
      default: null,
    },
    isShow: {
      type: [Boolean] as PropType<boolean>,
      default: null,
    },
  },

  computed: {
    classes() {
      const { isPaddingX, isPaddingY } = this
      return {
        'content-item': true,
        'is-padding-x': typeof isPaddingX === 'boolean' ? isPaddingX : false,
        'is-padding-y': typeof isPaddingY === 'boolean' ? isPaddingY : false,
      }
    },

    styles() {
      return {
        padding: this.padding,
        margin: this.margin,
      }
    },
  },

  render(): VNode {
    return (
      <div class={this.classes} style={this.styles}>
        {this.isShow === null || this.isShow ? this.$slots.default?.() : null}
      </div>
    )
  },
})
