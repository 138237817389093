/* Функции */
import { defineComponent, PropType, VNode } from 'vue'

/* Компоненты */
import VIcon from '@/components/VIcon/VIcon'
import VTextHeading1 from '@/components/VText/VTextHeading1/VTextHeading1'
import VRow from '@/components/VRow/VRow'
import VTextParagraph from '@/components/VText/VTextParagraph/VTextParagraph'

/* Стили */
import '@/components/TheProfileInfoCard/TheProfileInfoCard.scss'

/* Константы */
import { IconsByLevelSport } from '@/types/constants/common'
import { Level } from '@/types/API'

export default defineComponent({
  name: 'TheProfileInfoCard',

  props: {
    /** Имя */
    name: {
      type: String as PropType<string>,
      default: '',
    },
    /** Отчество */
    middleName: {
      type: String as PropType<string>,
      default: '',
    },
    /** Фамилия */
    lastName: {
      type: String as PropType<string>,
      default: '',
    },
    /** Сложность */
    difficulty: {
      type: [Number, null] as PropType<(Level['id']) | null>,
      default: null,
    },
    /** Текст сложности */
    difficultyText: {
      type: String as PropType<string>,
      default: '',
    },
    /** Текст цели */
    goalText: {
      type: String as PropType<string>,
      default: '',
    },
  },

  computed: {
    /** Отображаемое имя */
    showName() {
      const { name, middleName, lastName } = this

      if (middleName && name && lastName) {
        return `${middleName} ${name} ${lastName}`
      } if (name && (middleName || lastName)) {
        return `${name} ${middleName || lastName}`
      }

      return name
    },
  },

  render(): VNode {
    const {
      difficulty, showName, difficultyText, goalText,
    } = this

    const levelIcon = (
      difficulty
        ? <VIcon
          class={'profile_info_card__icon'}
          imageName={IconsByLevelSport[difficulty]}
        />
        : null
    )
    const level = (
      difficulty && difficultyText ? <VRow class={'profile_info_card__row'} alignItems={'center'}>
        {levelIcon}

        <VTextParagraph>{difficultyText}</VTextParagraph>
      </VRow> : null
    )
    const goal = (
      goalText ? <VRow class={'profile_info_card__row'} alignItems={'center'}>
        <VIcon
          class={'profile_info_card__icon'}
          imageName={'goal'}
        />

        <VTextParagraph>{goalText}</VTextParagraph>
      </VRow> : null
    )

    return (
      <div class={'profile_info_card'}>
        <VTextHeading1 class={'profile_info_card__name'}>{showName}</VTextHeading1>

        {level}

        {goal}
      </div>
    )
  },
})
