import { PaymentMethod } from '@/types/API'

export enum StatePage {
  pending= 'pending',
  success = 'success',
  fail = 'fail'
}

export interface Data {
  savedMethodId: number | null
  savedMethods: PaymentMethod[]
  state: StatePage
  attemptsCount: 0
}
