/* Функции */
import { defineComponent, PropType, VNode } from 'vue'

/* Компоненты */

/* Стили */
import '@/components/VTopBackground/VTopBackground.scss'

export default defineComponent({
  name: 'VTopBackground',

  props: {
    /** Фоновая картинка */
    backgroundImage: {
      type: String as PropType<string>,
      default: '',
    },

    /** Признак является ли картинка локальной */
    isLocal: {
      type: Boolean as PropType<boolean>,
      default: true,
    },

    /** Имя файла */
    imageName: {
      type: String as PropType<string>,
      default: '',
    },

    /** Позиция по оси Z */
    zIndex: {
      type: Number as PropType<number>,
      default: 0,
    },

    /** Показывать ли картинку для десктопа */
    isShowDesktopImg: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },

  computed: {
    /** Путь к картинке */
    imageSrc(): string {
      if (this.isLocal) {
        try {
          return require(`@/assets/img/pictures/${this.imageName}.png`)
        } catch (error) {
          return ''
        }
      } else {
        return this.imageName
      }
    },

    classes() {
      return {
        'is-show-desktop-img': this.isShowDesktopImg,
        'top-background': true,
      }
    },
  },

  render(): VNode {
    const styles = () => {
      const result: Record<string, string> = {}

      if (this.imageSrc) {
        result['background-image'] = `url(${this.imageSrc})`
      }

      if (this.zIndex) {
        result['z-index'] = String(this.zIndex)
      }

      return result
    }

    return (
      <div class={this.classes}>
        <div class={'top-background__img'} style={styles()}/>

        <div class={'top-background__img desktop'} style={styles()}/>

        {this.$slots.default?.()}
      </div>
    )
  },
})
