export enum TypeButtons {
  Secondary = 'isSecondary',
  Tertiary = 'isTertiary',
  Quaternary = 'isQuaternary',
  Fivefold = 'isFivefold',
  Sixfold = 'isSixfold',
  Disabled = 'isDisabled',
  FullWidth = 'isFullWidth',
  Small = 'isSmall',
}
