/* Функции */
import {
  defineComponent, PropType, VNode, withModifiers,
} from 'vue'

/* Компоненты */
import VIcon from '@/components/VIcon/VIcon'
import VText from '@/components/VText/VText/VText'

/* Стили */
import '@/components/VButton/VButtonIcon/VButtonIcon.scss'

/* Стили */
import { Data, IconType } from '@/components/VButton/VButtonIcon/Types'

export default defineComponent({
  name: 'VButtonIcon',

  emits: {
    /** Событие изменения выбора */
    'update:checked': (value: string | null) => typeof value === 'string' || value === null,
    'update:checkbox': (value: boolean) => typeof value === 'boolean',
  },

  data(): Data {
    return {
      valueCheckbox: false,
    }
  },

  props: {
    /** Имя */
    name: {
      type: String as PropType<string>,
      required: true,
    },

    /** Имя файла */
    imageName: {
      type: String as PropType<string>,
      default: '',
    },

    /** Вставлять ли svg в HTML */
    isIncludeSvgInHtml: {
      type: Boolean as PropType<boolean>,
      default: true,
    },

    /** Тип */
    type: {
      type: String as PropType<IconType>,
      default: IconType.radio,
    },

    /** Значение для связывания */
    modelValue: {
      type: [String, null, Boolean] as PropType<string | null | boolean>,
      default: null,
    },

    /** Отключение поля */
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    /** Значение */
    value: {
      type: String as PropType<string>,
      default: '',
    },
  },

  computed: {
    /** Список классов */
    classes() {
      return {
        'button-icon': true,
        'button-icon-radio': this.type === IconType.radio,
        'button-icon-checkbox': this.type === IconType.checkbox,
        disabled: this.disabled,
        checked: this.checked,
      }
    },

    /** Выбран ли текущий пункт */
    checked(): boolean {
      switch (this.type) {
        case IconType.radio:
          return this.value === this.modelValue
      }

      return this.valueCheckbox
    },
  },

  methods: {
    /** Обработчик изменений */
    onChange() {
      switch (this.type) {
        case IconType.checkbox:
          this.valueCheckbox = !this.valueCheckbox
          this.$emit('update:checkbox', this.valueCheckbox)
          break

        default:
          this.$emit('update:checked', this.value)
      }
    },
  },

  watch: {
    /** Наблюдает за изменением значений для связывания */
    modelValue() {
      if (typeof this.modelValue === 'boolean') {
        this.valueCheckbox = this.modelValue
      }
    },
  },

  render(): VNode {
    return (
      <label class={this.classes}>
        <input
          class={'button-icon__field'}
          name={this.name}
          onInput={withModifiers(() => undefined, ['prevent'])}
          onChange={this.onChange}
          disabled={this.disabled}
          checked={this.checked}
          type={this.type}
        />

        <VIcon
          class={'button-icon__icon'}
          imageName={this.imageName}
          isIncludeSvgInHtml={this.isIncludeSvgInHtml}
        />

        <VText class={'button-icon__text'}>{this.$slots.default?.()}</VText>
      </label>
    )
  },
})
