/* Функции */
import { defineComponent, PropType, VNode } from 'vue'

/* Компоненты */
import VTextSubTitle from '@/components/VText/VTextSubTitle/VTextSubTitle'
import VTextHeading1 from '@/components/VText/VTextHeading1/VTextHeading1'
import VRow from '@/components/VRow/VRow'
import VIcon from '@/components/VIcon/VIcon'
import VTextParagraph from '@/components/VText/VTextParagraph/VTextParagraph'
import VImg from '@/components/VImg/VImg'
import BaseTargetHeader from '@/components/BaseTargetHeader/BaseTargetHeader'
import VTextSmall from '@/components/VText/VTextSmall/VTextSmall'
import VMoreText from '@/components/VMoreText/VMoreText'

/* Стили */
import '@/components/BaseWorkoutHeader/BaseWorkoutHeader.scss'

/* Типы */
import { RoutePaths } from '@/router/Types'

export default defineComponent({
  name: 'BaseWorkoutHeader',

  props: {
    /** Постфикс длительности */
    postfixDuration: {
      type: String as PropType<string>,
      required: true,
    },
    /** Длительность */
    description: {
      type: String as PropType<string>,
      default: '',
    },
    /** Кнопка назад, используется в тех моментах, когда нет возможности покрыть логикой, реализованной в шаблоне. Смотреть Routes и Layouts */
    isShowBackButton: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },

  extends: BaseTargetHeader,

  methods: {
    /** Метод перехода к логину */
    onClickToLogin() {
      this.$router.push(RoutePaths.login)
    },
  },

  render(): VNode {
    const {
      title,
      subTitle,
      isShowEquipment,
      showEquipment,
      failLoadSrc,
      src,
      alt,
      styles,
      duration,
      postfixDuration,
      description,
    } = this
    const equipment = isShowEquipment ? (
      <VRow class={'plan-card__equipment'} alignItems={'center'}>
        <VIcon
          class={'plan-card__equipment_icon'}
          imageName={'equipment'}
        />

        <VTextParagraph opacity={0.7}>{showEquipment}</VTextParagraph>
      </VRow>
    ) : null

    return (
      <VRow
        tag='article'
        direction={'column'}
        class={'workout-header'}
        style={styles}
      >
        <VImg
          key={`${src}-${alt}-${failLoadSrc}`}
          class={'workout-header__img'}
          src={src}
          alt={alt}
          failLoadSrc={failLoadSrc}
        />

        {
          this.isShowBackButton
            ? <VIcon
              class={'workout-header__icon-back'}
              imageName={'arrow_left'}
              cursor={'pointer'}
              onClick={this.onClickToLogin}
            />
            : null
        }

        <VRow class={'workout-header__top'} direction={'column'}>
          <VTextSubTitle class={'workout-header__subtitle'}>{`[ ${subTitle} ]`}</VTextSubTitle>

          <VTextHeading1 class={'workout-header__title'}>{title}</VTextHeading1>

          {equipment}
        </VRow>

        <VRow class={'workout-header__bottom'}>
          <VRow
            class={'workout-header__duration'}
            direction={'column'}
            alignItems={'center'}
            alignContent={'center'}
            justify={'center'}
          >
            <VTextParagraph
              class={'workout-header__duration-value'}
              opacity={1}
              fontWeight={700}
            >
              {duration}
            </VTextParagraph>

            <VTextSmall
              class={'workout-header__duration-subtitle'}
              opacity={1}
              fontWeight={700}
            >
              {postfixDuration}
            </VTextSmall>
          </VRow>

          <VMoreText class={'workout-header__bottom-description'} opacity={1}>{description}</VMoreText>
        </VRow>
      </VRow>)
  },
})
