/* Функции */
import { defineComponent, VNode } from 'vue'

/* Компоненты */
import BaseTargetCard from '@/components/BaseTargetCard/BaseTargetCard'
import VMoreText from '@/components/VMoreText/VMoreText'
import VSlider from '@/components/VSlider/VSlider'
import VContentItem from '@/components/VContent/VContentItem/VContentItem'
import VTextHeading1 from '@/components/VText/VTextHeading1/VTextHeading1'
import VPage from '@/components/VPage/VPage'

/* Стили */
import '@/views/Goals/Goals.scss'

/* Классы */
import { RequestAPI } from '@/classes/Request'

/* Типы */
import { EquipmentItem, ResponseGoals } from '@/types/API'
import { Data, PlanData } from '@/views/Goals/Types'

/* Хранилище */
import { LayoutStore } from '@/store/layout'

export default defineComponent({
  name: 'GoalsView',

  data(): Data {
    return {
      goalCategories: [],
      goals: [],
      equipmentCollections: {},
      isLoadData: false,
    }
  },

  computed: {
    /** Данные плана */
    planData(): PlanData[] {
      const result = this.goalCategories.reduce<PlanData[]>((categories, category) => {
        const categoryGoals = this.goals.filter((goal) => goal.goal_category_id === category.id)

        if (categoryGoals.length) {
          categories.push({
            ...category,
            goals: categoryGoals,
          })
        }

        return categories
      }, [])

      return result
    },
  },

  methods: {
    /** Получение данных по плану */
    async getPlans() {
      const response = await RequestAPI.errorHandler<ResponseGoals>(RequestAPI.get, '/goals')

      if (response) {
        return response.data.content
      }

      return null
    },

    /** Установка данных */
    async setData() {
      LayoutStore().setLoading(true)
      const data = await this.getPlans()

      if (data) {
        const { goals, equipment_items, goal_categories } = data

        this.goals = goals
        this.goalCategories = goal_categories
        this.equipmentCollections = equipment_items.reduce<Record<EquipmentItem['id'], EquipmentItem>>((equipmentCollections, equipment) => {
          equipmentCollections[equipment.id] = equipment
          return equipmentCollections
        }, {})
      }

      LayoutStore().setLoading(false)
      this.isLoadData = true
    },
  },

  created() {
    this.setData()

    document.body.classList.add('hide-menu')
  },

  unmounted() {
    document.body.classList.remove('hide-menu')
  },

  render(): VNode | null {
    const plans = () => {
      const result: VNode[] = []

      for (const key in this.planData) {
        if (Object.prototype.hasOwnProperty.call(this.planData, key)) {
          const plan = this.planData[key]
          const title = <VTextHeading1>{plan.title}</VTextHeading1>
          const description = <VMoreText>{plan.description}</VMoreText>
          const targets = plan.goals.map((target) => {
            const equipments = target.equipment_item_ids.reduce<string[]>((equipmentsAcc, equipment) => {
              const findEquipment = this.equipmentCollections[equipment]

              if (findEquipment && findEquipment.title) {
                equipmentsAcc.push(findEquipment.title)
              }

              return equipmentsAcc
            }, [])

            return (
              <BaseTargetCard
                title={target.title ?? ''}
                id={target.id}
                duration={target.duration ?? 0}
                equipment={equipments}
                src={target.images.show ?? ''}
                subTitle={plan.title ?? ''}
                labels={target.subscription_plans.map((label) => label.label)}
              />
            )
          })

          result.push(
            <VContentItem isPaddingX={true}>
              {title}

              {description}

              <VSlider
                class={'goals-view__slider-mobile'}
                widthSlide={'148px'}
              >
                {{
                  default: () => targets,
                }}
              </VSlider>
            </VContentItem>,
          )
        }
      }

      return result
    }

    return (
      <VPage key={'GoalsView'}>
        {plans()}
      </VPage>
    )
  },
})
