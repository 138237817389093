/* Функции */
import { defineComponent, VNode } from 'vue'

/* Компоненты */
import VPage from '@/components/VPage/VPage'
import VContentItem from '@/components/VContent/VContentItem/VContentItem'
import VTextParagraph from '@/components/VText/VTextParagraph/VTextParagraph'
import VButton from '@/components/VButton/VButton'

/* Стили */
import '@/views/MyGoals/MyGoalsDetailClose/MyGoalsDetailClose.scss'

/* Типы */
import { RoutePaths } from '@/router/Types'
import { ResponseMyGoalDetail } from '@/types/API'
import { RequestAPI } from '@/classes/Request'

export default defineComponent({
  name: 'MyGoalsDetailClose',

  computed: {
    /** Ссылка */
    back(): string {
      return RoutePaths.myGoalsDetail.replace(':id', String(this.$route.params.id))
    },
  },

  methods: {
    async closePlan() {
      const response = await RequestAPI.errorHandler<ResponseMyGoalDetail>(RequestAPI.delete, `/athletes/goals/${this.$route.params.id}`)

      if (response) {
        this.$router.replace(RoutePaths.goals)
      }
    },
  },

  render(): VNode {
    return (
      <VPage class={'my-goals-detail-close'} key={'MyGoalsDetailClose'}>
        <VContentItem isPaddingX={true}>
          <VTextParagraph opacity={1}>{this.$t('pages.MyGoalsDetailClose.description')}</VTextParagraph>
        </VContentItem>

        <VContentItem isPaddingX={true}>
          <VButton isQuaternary={true} onClick={this.closePlan}>
            {this.$t('pages.MyGoalsDetailClose.buttonClose')}
          </VButton>

          <VButton isTertiary={true} onClick={() => this.$router.push(this.back)}>
            {this.$t('pages.MyGoalsDetailClose.buttonCancel')}
          </VButton>
        </VContentItem>
      </VPage>
    )
  },
})
