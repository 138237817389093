/* Функции */
import { defineComponent, VNode } from 'vue'

/* Типы */
import { Data } from '@/main/Types'
import { RouteLocation } from 'vue-router'

/* Компоненты */
import VTransition from '@/components/VTransition/VTransition'
import TheUpdateModalPWA from '@/components/TheUpdateModalPWA/TheUpdateModalPWA'

/* Хранилище */
import { mapStores } from 'pinia'
import { LayoutStore } from '@/store/layout'

/* Помощники */
import { isIOs, isMobile } from '@/helpers/common'
import { LayoutList } from '@/layouts'

/* Стили */
import '@/main/Main.scss'

export default defineComponent({
  name: 'MainApp',

  components: { ...LayoutList },

  data(): Data {
    return {
      isIOs: false,
      isMobile: false,
    }
  },

  computed: {
    ...mapStores(LayoutStore),

    /** CSS классы */
    classes(): Record<string, boolean> {
      return {
        'main-app': true,
        'is-ios': this.isIOs,
        'is-mobile': this.isMobile,
      }
    },
  },

  created() {
    this.isIOs = isIOs(window)
    this.isMobile = isMobile(window)
  },

  mounted() {
    // Metrics.initYM()
    // Metrics.initGM()
  },

  render(): VNode {
    const layoutName = this.layoutStore.name
    const component = LayoutList[layoutName]

    return (
      <div class={this.classes}>
        <notifications position={'top center'} />

        <VTransition>
          {/* Layout */}
          <component key={this.layoutStore.name}>
            <router-view>
              {{
                default: ({ Component, route }: { Component: VNode, route: RouteLocation }) => {
                  const componentDynamic = Component
                  const key = `${route.path}-${this.layoutStore.layoutParams?.title}-${this.layoutStore.layoutParams?.isShowLogout}-${this.layoutStore.layoutParams?.isShowBackButton}`

                  return (
                    <VTransition>

                      {componentDynamic
                        ? <componentDynamic key={key} /> : null}
                    </VTransition>
                  )
                },
              }}
            </ router-view>
          </component>
        </VTransition>

        <TheUpdateModalPWA />
      </div>
    )
  },
})
