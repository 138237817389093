/* Промежуточные ПО */
import { AuthMiddleware } from '@/middlewares/Auth'
import { SetTitleMiddleware } from '@/middlewares/SetTitle'
import { SetLayoutParamsMiddleware } from '@/middlewares/LayoutParams'
import { SetLayoutMiddleware } from '@/middlewares/Layout'

/** Обязательные промежуточные ПО */
export const RequiredMiddleware = Object.freeze([
  AuthMiddleware,
  SetTitleMiddleware,
  SetLayoutParamsMiddleware,
  SetLayoutMiddleware,
])
