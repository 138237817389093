/* Функции */
import { defineComponent, VNode } from 'vue'

/* Компоненты */
import VTabButton from '@/components/VTabs/VTabButton/VTabButton'
import VPage from '@/components/VPage/VPage'
import VTabs from '@/components/VTabs/VTabs/VTabs'
import VTransition from '@/components/VTransition/VTransition'
import VContentItem from '@/components/VContent/VContentItem/VContentItem'
import BaseSubscription from '@/components/BaseSubscription/BaseSubscription'
import BasePaymentCard from '@/components/BasePaymentCard/BasePaymentCard'
import VListIcons from '@/components/VListIcons/VListIcons'
import BasePaymentOptionChoice from '@/components/BasePaymentOptionChoice/BasePaymentOptionChoice'
import VButtonFixes from '@/components/VButton/VButtonIcon/VButtonFixes/VButtonFixes'
import VRow from '@/components/VRow/VRow'
import VIcon from '@/components/VIcon/VIcon'
import VTextButton from '@/components/VText/VTextButton/VTextButton'

/* Стили */
import '@/views/Subscriptions/Subscriptions.scss'

/* Классы */
import { RequestAPI } from '@/classes/Request'
import dayjs from 'dayjs'

/* Типы */
import {
  AddPaymentResponse, PaymentMethodsResponse, Plan, PlanResponse, SubscriptionResponse,
} from '@/types/API'
import { RoutePaths } from '@/router/Types'
import { Data, TabValue } from '@/views/Subscriptions/Types'

/* Хранилище */
import { LayoutStore } from '@/store/layout'

const RecommendIdPlan = 2

export default defineComponent({
  name: 'SubscriptionsView',

  data(): Data {
    return {
      tab: TabValue.subscriptions,
      subscriptions: [],
      savedMethods: [],
      isLoadData: false,
      isLoadPlan: false,
      plans: [],
      currentPayment: null,
    }
  },

  computed: {
    /** Выбранный Тариф */
    currentTariff(): Plan['payment_options'][number] | null {
      return this.plans?.[0].payment_options.find((payment) => payment.id === this.currentPayment) ?? null
    },
  },

  methods: {
    /** Установка подписок */
    async setSubscriptions() {
      const response = await RequestAPI.errorHandler<SubscriptionResponse>(RequestAPI.get, '/subscriptions')

      if (response) {
        const { data } = response
        this.subscriptions = data.content.subscriptions
      }
    },

    /** Установка сохраненных платежных данных  */
    async setSavedMethods() {
      const response = await RequestAPI.errorHandler<PaymentMethodsResponse>(RequestAPI.get, '/payments/saved_methods')

      if (response) {
        const { data } = response
        this.savedMethods = data.content.payment_methods
      }
    },

    /**
     * Отмена автопродления
     * @param id - Идентификатор подписки
     */
    async cancel(id: number) {
      LayoutStore().setLoading(true)
      const response = await RequestAPI.errorHandler<SubscriptionResponse>(RequestAPI.delete, `/subscriptions/${id}`)

      if (response) {
        await this.setSubscriptions()
      }

      LayoutStore().setLoading(false)
    },

    /** Добавление метода платежного */
    async addNewPaymentMethod() {
      const response = await RequestAPI.errorHandler<AddPaymentResponse>(RequestAPI.post, '/payments/saved_methods')

      if (!response) {
        return
      }

      const { data } = response

      if (data.content.payment_link) {
        window.location.href = data.content.payment_link
      }
    },

    /** Получение планов */
    async getPlans(): Promise<Plan[]> {
      if (!this.isLoadPlan) {
        LayoutStore().setLoading(true)
        this.plans = []

        const response = await RequestAPI.errorHandler<PlanResponse>(RequestAPI.get, '/plans')

        if (response) {
          const { data } = response
          const isRecommendedSelected = this.subscriptions.find((subscription) => RecommendIdPlan === subscription.plan.id)

          /* Заменить логику, когда станут приходить рекомендованные */
          if (!isRecommendedSelected) {
            this.plans.push(...data.content.plans.filter((plan) => plan.id === RecommendIdPlan))
          }
        }

        this.isLoadPlan = true
        LayoutStore().setLoading(false)
      }

      return this.plans
    },

    /** Смена выбранного тарифа */
    onChecked(value: string | number | null) {
      this.currentPayment = Number(value)
    },

    /** Выбор плана */
    async choicePlan() {
      LayoutStore().setLoading(true)
      const response = await RequestAPI.errorHandler(RequestAPI.post, '/subscriptions', {
        subscription_plan_option_id: this.currentPayment,
        trial: true,
      })

      if (response) {
        this.isLoadPlan = false
        await this.setSubscriptions()
        await this.getPlans()
      }

      LayoutStore().setLoading(false)

      this.tab = TabValue.subscriptions
    },

    /**
     * Возобновить автопродление
     * @param id - Идентификатор подписки
     */
    async setAutoRenew(id: number) {
      LayoutStore().setLoading(true)

      await RequestAPI.errorHandler<SubscriptionResponse>(RequestAPI.put, `/subscriptions/${id}`, {
        subscription: {
          autorenew: true,
        },
      })
      await this.setSubscriptions()

      LayoutStore().setLoading(false)
    },
  },

  watch: {
    tab() {
      if (this.tab === TabValue.offers) {
        this.getPlans()
      }
    },
  },

  async created() {
    LayoutStore().setLoading(true)
    await this.setSubscriptions()
    await this.setSavedMethods()
    LayoutStore().setLoading(false)
    this.isLoadData = true
  },

  render() {
    const choiceList = () => this.plans[0]?.payment_options?.map((option) => (
      <BasePaymentOptionChoice
        price={option.price}
        description={option.description ?? ''}
        modelValue={this.currentPayment}
        onUpdate:checked={this.onChecked}
        value={option.id}
        name={'choice_plan'}
      >
        {option.title}
      </BasePaymentOptionChoice>
    ))

    const offerTab = () => {
      if (!this.isLoadPlan) {
        return null
      }

      if (this.isLoadPlan && !this.plans.length) {
        return <VContentItem>{this.$t('pages.Subscriptions.noOffers')}</VContentItem>
      }

      return <VContentItem>
        <VContentItem>{choiceList()}</VContentItem>

        <VContentItem><VListIcons html={this.plans[0]?.description ?? ''} /></VContentItem>

        <VTransition>
          {
            this.currentPayment ? <VButtonFixes
              onClick={this.choicePlan}
              isFullWidth={true}
            >
              <VRow>
                <VIcon
                  imageName={'pro'}
                />

                <VTextButton
                  colorText={'#fff'}
                  class="plan-detail__text"
                >
                  {this.$t('pages.Plans.button', { payment: this.currentTariff?.title })}
                </VTextButton>
              </VRow>
            </VButtonFixes> : null
          }
        </VTransition>
      </VContentItem>
    }

    const tabSubscriptions = () => {
      const subscriptionItems = this.subscriptions.reduce<VNode[]>((subscriptions, subscription) => {
        if (!subscription.trial) {
          const isShowCancelButton = subscription.autorenew
          const trialFind = this.subscriptions.find((subscriptionFind) => subscription.plan.id === subscriptionFind.plan.id && subscriptionFind.state === 'current' && subscriptionFind.trial === true)
          const date = trialFind ? this.$t('pages.Subscriptions.subscribeItem.trialTo', { date: dayjs(trialFind.ends_at).format('DD MMMM YYYY') }) : this.$t('pages.Subscriptions.subscribeItem.activeTo', { date: dayjs(subscription.ends_at).format('DD MMMM YYYY') })

          subscriptions.push(<BaseSubscription
            isTopButton={true}
            textActiveTo={date}
            description={isShowCancelButton ? this.$t('pages.Subscriptions.subscribeItem.renewAt', { date: dayjs(subscription.starts_at).format('DD MMMM YYYY') }) : ''}
            title={subscription.subscription_plan_option.title}
            textRenewButton={this.$t('pages.Subscriptions.subscribeItem.buttons.renew')}
            textTopButton={isShowCancelButton ? this.$t('pages.Subscriptions.subscribeItem.buttons.cancel') : this.$t('pages.Subscriptions.subscribeItem.buttons.resume')}
            isShowRenewButton={false}
            dateTo={subscription.ends_at}
            onClickTop={() => {
              if (subscription.autorenew) {
                this.cancel(subscription.id)
              } else {
                this.setAutoRenew(subscription.id)
              }
            }}
          />)
        }

        return subscriptions
      }, [])

      if (!subscriptionItems.length) {
        subscriptionItems.push(<BaseSubscription
          isShowRenewButton={true}
          isShowDateEnd={false}
          isTopButton={false}
          title={this.$t('pages.Subscriptions.noSubscriptions')}
          textRenewButton={this.$t('pages.Subscriptions.subscribeItem.buttons.newSubscribe')}
          onClickRenew={() => { this.tab = TabValue.offers }}
        />)
      }

      const paymentMethods = () => {
        if (!this.savedMethods.length) {
          return (
            <BasePaymentCard
              onClick={() => { this.addNewPaymentMethod() }}
              title={this.$t('pages.Subscriptions.paymentMethodItem.title')}
              textButton={this.$t('pages.Subscriptions.paymentMethodItem.buttons.add')}
            />
          )
        }

        const defaultPayments = this.savedMethods.filter((savedMethod) => savedMethod.default)
        const defaultPayment = defaultPayments[0] ?? this.savedMethods[0]

        if (defaultPayment) {
          return (
            <BasePaymentCard
              onClick={() => { this.$router.push(RoutePaths.paymentMethods) }}
              imageName={defaultPayment.card_info?.card_type}
              title={this.$t('pages.Subscriptions.paymentMethodItem.title')}
              textButton={this.$t('pages.Subscriptions.paymentMethodItem.buttons.change')}
              numberCard={defaultPayment.card_info?.last4 ?? ''}
              namePaymentSystem={defaultPayment.card_info?.card_type}
            />
          )
        }
      }

      return (
        <div key={TabValue.subscriptions}>
          {subscriptionItems}

          {paymentMethods()}
        </div>
      )
    }

    const tabContent = () => {
      switch (this.tab) {
        case TabValue.offers:
          return offerTab()

        case TabValue.subscriptions:
          return tabSubscriptions()
        default:
          return null
      }
    }

    return (<VPage>
      <VContentItem isPaddingX={true}>
        <VTabs
          modelValue={this.tab}
          onUpdate:modelValue={(value) => {
            this.tab = value
          }}
        >
          <VTabButton value={TabValue.offers}>{this.$t('pages.Subscriptions.tabs.offers')}</VTabButton>
          <VTabButton value={TabValue.subscriptions}>{this.$t('pages.Subscriptions.tabs.subscriptions')}</VTabButton>
        </VTabs>

        <VTransition>
          {this.isLoadData ? tabContent() : null}
        </VTransition>
      </VContentItem>
    </VPage>)
  },
})
