/* Функции */
import { defineComponent, VNode } from 'vue'

/* Компоненты */
import VPage from '@/components/VPage/VPage'
import TheProfileInfoCard from '@/components/TheProfileInfoCard/TheProfileInfoCard'
import VContentItem from '@/components/VContent/VContentItem/VContentItem'
import BaseButtonMenuInline from '@/components/BaseButtonMenuInline/BaseButtonMenuInline'
import VLabelIcon from '@/components/VLabelIcon/VLabelIcon'

/* Стили */
import '@/views/Profile/Profile.scss'

/* Классы */
import { RequestAPI } from '@/classes/Request'

/* Типы */
import { Level, Profile } from '@/types/API'
import { Maybe } from '@/types'
import { Data, ProfileMenu, RoutePathsProfile } from '@/views/Profile/Types'

/* Хранилище */
import { UserStore } from '@/store/user'
import { LayoutStore } from '@/store/layout'

export default defineComponent({
  name: 'ProfileView',

  data(): Data {
    return {
      profileData: null,
      isLoadData: false,
    }
  },

  computed: {
    firstName(): string {
      return `${this.profileData?.first_name ? `${this.profileData.first_name} ` : ''}${this.profileData?.last_name ?? ''}`
    },
    level(): Maybe<(Level['id'])> {
      return typeof this.profileData?.level.id === 'string' ? Number(this.profileData?.level.id) as 1 | 2 | 3 | undefined : null
    },
    isTelegramActive(): boolean {
      return Boolean(this.profileData?.telegram_id)
    },
  },

  async created() {
    LayoutStore().setLoading(true)

    const response = await RequestAPI.errorHandler<Profile>(RequestAPI.get, '/profile')

    if (response) {
      const userState = UserStore()

      userState.setTelegramId(response.data.content.athlete.telegram_id)
      this.profileData = response.data.content.athlete
    }

    LayoutStore().setLoading(false)
    this.isLoadData = true
  },

  render(): VNode | null {
    const menu = () => ProfileMenu.map((section) => <div class={'profile-menu-section'}>{
      section.map((menuItem) => (
        <BaseButtonMenuInline
          description={menuItem.description}
          link={menuItem.link}
          disabled={menuItem.disabled}
        >
          {{
            default: () => menuItem.title,
            afterTitle: () => {
              switch (menuItem.link) {
                case RoutePathsProfile.telegramBot:
                  return (<VLabelIcon>{this.$t('pages.Profile.menu.telegramBot.label.title', this.isTelegramActive ? 1 : 0)}</VLabelIcon>)
                case RoutePathsProfile.plan:
                  return (<VLabelIcon color='#fff' background='#8B5CF6' imageName="pro">{this.$t('pages.Profile.menu.plan.label.title')}</VLabelIcon>)
              }
            },
          }}
        </BaseButtonMenuInline>
      ))}</div>)

    return <VPage class="profile">
      <VContentItem isPaddingX={true} isShow={this.isLoadData}>
        <TheProfileInfoCard
          difficulty={this.level}
          difficultyText={this.profileData?.level.title ?? ''}
          name={this.firstName}
        />
        {menu()}
      </VContentItem>

    </VPage>
  },
})
