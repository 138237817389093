/* Функции */
import { defineComponent, VNode } from 'vue'

/* Стили */
import '@/layouts/Default/Default.scss'

/* Хранилище */
import { UserStore } from '@/store/user'

export default defineComponent({
  name: 'DefaultLayout',

  created() {
    const userState = UserStore()

    userState.setReferrer(window.location.search.replace('?', ''))
  },

  render(): VNode {
    return (
      <div class="default-layout layout">
        {this.$slots.default?.()}
      </div>
    )
  },
})
