/* Функции */
import { defineComponent, PropType, VNode } from 'vue'

/* Компоненты */
import VTextParagraph from '@/components/VText/VTextParagraph/VTextParagraph'

/* Стили */
import '@/components/VLabel/VLabel.scss'

/* Типы */
import { defaultProps } from '@/components/VText/Types'

export default defineComponent({
  name: 'VLabel',

  props: {
    ...defaultProps({
      defaultOpacity: 1,
      fontWeight: 700,
    }),

    /** Признак второстепенного отображения */
    isSecondary: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    /** Признак третьего отображения */
    isTertiary: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },

  computed: {
    /** Список классов */
    classes() {
      return {
        'label-item': true,
        secondary: this.isSecondary,
        tertiary: this.isTertiary,
      }
    },
  },

  render(): VNode {
    return (
      <VTextParagraph
        opacity={this.opacity}
        fontWeight={this.fontWeight}
        class={this.classes}
      >
        {this.$slots.default?.()}
      </VTextParagraph>
    )
  },
})
