/* Функции */
import { defineComponent, VNode } from 'vue'

/* Компоненты */
import VPage from '@/components/VPage/VPage'
import VContentItem from '@/components/VContent/VContentItem/VContentItem'
import VInputButton from '@/components/VInput/VInputButton/VInputButton'
import VRow from '@/components/VRow/VRow'
import VIcon from '@/components/VIcon/VIcon'
import VTextInputButton from '@/components/VText/VTextInputButton/VTextInputButton'
import VTransition from '@/components/VTransition/VTransition'
import VButton from '@/components/VButton/VButton'

/* Стили */
import '@/views/Payments/PaymentMethods/PaymentMethods.scss'

/* Классы */
import { RequestAPI } from '@/classes/Request'

/* Типы */
import {
  AddDefaultMethodsResponse, AddPaymentResponse, PaymentMethod, PaymentMethodsResponse,
} from '@/types/API'
import { InputType } from '@/components/VInput/VInputButton/Types'
import { LayoutStore } from '@/store/layout'
import { Data } from './Types'

/* Хранилище */

export default defineComponent({
  name: 'PaymentMethodsView',

  data(): Data {
    return {
      savedMethods: [],
      isLoadData: false,
      currentMethods: null,
      defaultID: null,
    }
  },

  methods: {
    /** Установка данных по методам */
    async setSavedMethods() {
      const response = await RequestAPI.errorHandler<PaymentMethodsResponse>(RequestAPI.get, '/payments/saved_methods')

      if (response) {
        const { data } = response
        this.savedMethods = data.content.payment_methods
      }
    },

    /**
     * Текст карты
     * @param method - метод
     * @returns Текст
     */
    getTextCard(method: PaymentMethod) {
      if (method.card_info?.card_type && method.card_info?.last4) {
        return `${method.card_info.card_type} ** ${method.card_info.last4}`
      }

      return method.card_info?.card_type || method.card_info?.last4
    },

    /**
     * Установка по-умолчанию метода оплаты
     * @param id - Идентификатор метода оплаты
     */
    async setDefaultMethod(id?: string | number | null) {
      if (id !== this.defaultID && id !== undefined && id !== null) {
        const response = await RequestAPI.errorHandler<AddDefaultMethodsResponse>(RequestAPI.put, `/payments/saved_methods/${id}`, {
          payment_method: {
            default: true,
          },
        })

        if (response) {
          const { data } = response

          if (!data.content.payment_method.default) {
            return
          }

          this.defaultID = id
        }
      }
    },

    /** Добавление метода оплаты */
    async addNewPaymentMethod() {
      const response = await RequestAPI.errorHandler<AddPaymentResponse>(RequestAPI.post, '/payments/saved_methods')

      if (!response) {
        return
      }

      const { data } = response

      if (data.content.payment_link) {
        window.location.href = data.content.payment_link
      }
    },

    /** Удаление метода оплаты */
    async deleteMethod() {
      if (this.currentMethods !== undefined && this.currentMethods !== null) {
        const response = await RequestAPI.errorHandler<AddDefaultMethodsResponse>(RequestAPI.delete, `/payments/saved_methods/${this.currentMethods}`)

        if (!response) {
          return
        }

        const { data } = response

        if (data && data.content.payment_method.state === 'annulled') {
          this.currentMethods = null
          await this.setSavedMethods()
        }
      }
    },
  },

  computed: {
    /** По-умолчанию метод */
    defaultCard(): PaymentMethod | undefined {
      return this.savedMethods.filter((method) => method.default)?.[0]
    },
  },

  async created() {
    LayoutStore().setLoading(true)

    await this.setSavedMethods()
    if (this.defaultCard?.id !== null && this.defaultCard?.id !== undefined) {
      this.currentMethods = Number(this.defaultCard.id)
      this.defaultID = this.defaultCard.id
    }
    LayoutStore().setLoading(false)

    this.isLoadData = true
  },

  render(): VNode {
    const buttonAction = () => {
      if (this.currentMethods === 'default') {
        return <VRow key={'default'}>
          <VButton onClick={this.addNewPaymentMethod}>{this.$t('pages.PaymentMethods.buttons.add')}</VButton>
        </VRow>
      }

      if (this.currentMethods) {
        return <VRow direction={'column'}>
          <VButton isQuaternary onClick={this.deleteMethod}>{this.$t('pages.PaymentMethods.buttons.delete')}</VButton>
        </VRow>
      }

      return null
    }

    const defaultButton = <VInputButton
      class={'payment-methods-button'}
      name='methods'
      key={'default'}
      type={InputType.radio}
      onUpdate:checked={(value) => {
        this.currentMethods = value
      }}
      value={'default'}
      modelValue={this.currentMethods}
      imageName={'circle'}
      checkedImageName={'success'}
      isIncludeSvgInHtml={false}
    >
      <VRow alignItems={'center'}>
        <VIcon class={'payment-card-icon'} isIncludeSvgInHtml={false} imageName={'pay_default'} />

        <VTextInputButton class={'payment-card-number'}>{this.$t('pages.PaymentMethods.defaultCard')}</VTextInputButton>
      </VRow>
    </VInputButton>

    const buttons = () => {
      const result = this.savedMethods.map((method, index) => <VInputButton
        class={'payment-methods-button'}
        name='methods'
        key={`${method.id}-${index}`}
        type={InputType.radio}
        onUpdate:checked={(value) => {
          this.currentMethods = value
          this.setDefaultMethod(value)
        }}
        value={method.id}
        modelValue={this.currentMethods}
        imageName={'circle'}
        checkedImageName={'success-fill'}
        isIncludeSvgInHtml={false}
      >
        <VRow alignItems={'center'}>
          <VIcon class={'payment-card-icon'} isIncludeSvgInHtml={false} imageName={method.card_info?.card_type} />

          <VTextInputButton class={'payment-card-number'}>{this.getTextCard(method)}</VTextInputButton>
        </VRow>
      </VInputButton>)

      result.push(defaultButton)

      return result
    }

    return (
      <VPage>
        <VContentItem isPaddingX={true}>
          <VTransition>
            {this.isLoadData ? <div class={'payment-methods-content'}>
              {buttons()}

              <VContentItem>
                <VTransition>
                  {buttonAction()}
                </VTransition>
              </VContentItem>
            </div> : null}
          </VTransition>
        </VContentItem>
      </VPage>
    )
  },
})
