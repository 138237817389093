/* Функции */
import {
  defineComponent, PropType, VNode,
} from 'vue'

export default defineComponent({
  name: 'VImg',

  data() {
    return {
      isErrorLoad: false,
    }
  },

  props: {
    /** Путь до изображения. Указывается без хоста */
    src: {
      type: String as PropType<string>,
      required: true,
    },
    /** Путь к изображению при ошибки загрузки. Относительно пути @/assets/img/pictures/ */
    failLoadSrc: {
      type: String as PropType<string | null>,
      default: null,
    },
    /** Альтернативное описание */
    alt: {
      type: String as PropType<string>,
      default: '',
    },
    /** Текст при наведении */
    title: {
      type: String as PropType<string>,
      default: '',
    },
  },

  computed: {
    /** Отображаемый путь */
    targetSrc(): string {
      try {
        if (this.isErrorLoad && this.failLoadSrc) {
          return require(`@/assets/img/pictures/${this.failLoadSrc}`)
        }

        const host = process.env.VUE_APP_IMG_SOURCE
        return `${host}${this.src}`
      } catch (error) {
        console.error(error)
        return ''
      }
    },
  },

  methods: {
    /** Обработчик ошибки */
    onErrorImage() {
      this.isErrorLoad = true
    },
  },

  render(): VNode {
    const {
      alt, title, targetSrc, onErrorImage,
    } = this

    return (
      <img
        ref={'img'}
        class={'img'}
        alt={alt}
        title={title}
        src={targetSrc}
        onError={onErrorImage}
      />
    )
  },
})
