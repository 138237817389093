/* Функции */
import {
  defineComponent, h, PropType, VNode,
} from 'vue'

/* Компоненты */
import VRow from '@/components/VRow/VRow'
import VBannerMobile from '@/components/VBanner/VBannerMobile/VBannerMobile'
import VBannerDesktop from '@/components/VBanner/VBannerDesktop/VBannerDesktop'

/* Стили */
import '@/components/VBanner/VBanner.scss'
import {
  BannerBackgroundColor,
  BannerMode, Data, ShowOn,
} from '@/components/VBanner/Types'

/* Помощники */

export default defineComponent({
  name: 'VBanner',

  emits: {
    close() {
      return true
    },
  },

  props: {
    /** Имя файла */
    imageName: {
      type: String as PropType<string>,
      default: '',
      required: true,
    },

    /** Режим отображения */
    mode: {
      type: String as PropType<BannerMode>,
      default: BannerMode.default,
    },

    /** Режим отображения на устройстве */
    showOn: {
      type: String as PropType<ShowOn>,
      default: ShowOn.mobile,
    },

    /** Имя файла закрытия */
    closeImageName: {
      type: String as PropType<string>,
      default: 'close',
    },

    /** Фон */
    backgroundColor: {
      type: String as PropType<BannerBackgroundColor>,
      default: BannerBackgroundColor.primary,
    },
  },

  data(): Data {
    return {
      isMobile: false,
    }
  },

  render(): VNode {
    const banner = () => {
      switch (this.showOn) {
        case ShowOn.mobile:
          return h(VBannerMobile, {
            ...this.$props,
            onClose: () => this.$emit('close'),
          }, this.$slots)
        case ShowOn.desktop:
          return h(VBannerDesktop, {
            ...this.$props,
            onClose: () => this.$emit('close'),
          }, this.$slots)
      }
    }

    return (
      <VRow class={'banners'}>
        {banner()}
      </VRow>
    )
  },
})
