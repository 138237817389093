/* Функции */
import { defineComponent, PropType, VNode } from 'vue'

/** Типы */
import { defaultProps, defaultStyles } from '@/components/VText/Types'

/* Стили */
import '@/components/VText/VTextSubButton/VTextSubButton.scss'

export default defineComponent({
  name: 'VTextSubButton',

  props: {
    ...defaultProps(),

    /** Тег */
    tag: {
      type: String as PropType<string>,
      default: 'span',
    },
  },

  computed: {
    styles() {
      return {
        ...defaultStyles.call(this),
      }
    },
  },

  render(): VNode {
    const nameComponent = this.tag

    return (
      <nameComponent onClick={this.onClick} class={'text text-sub-button'} style={this.styles}>
        {this.$slots.default?.()}
      </nameComponent>
    )
  },
})
