/* Функции */
import { defineComponent, VNode } from 'vue'

/* Стили */
import '@/components/VPage/VPage.scss'

export default defineComponent({
  name: 'VPage',

  render(): VNode | null {
    return (
      this.$slots.default?.().length ? <div class={'page'}>
        {this.$slots.default?.()}
      </div>
        : null
    )
  },
})
