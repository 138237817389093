/* Функции */
import {
  defineComponent, inject, PropType, VNode, withModifiers,
} from 'vue'

/* Компоненты */
import VTextInputButton from '../VText/VTextInputButton/VTextInputButton'
import VTextSmall from '../VText/VTextSmall/VTextSmall'

/* Стили */
import '@/components/BasePaymentOptionChoice/BasePaymentOptionChoice.scss'

/* Типы */
import { InputType } from '../VInput/VInputButton/Types'

export default defineComponent({
  name: 'BasePaymentOptionChoice',

  emits: {
    /** Событие изменения выбора */
    'update:checked': (value: string | null | number) => typeof value === 'string' || typeof value === 'number' || value === null,
  },

  props: {
    /** Имя */
    name: {
      type: String as PropType<string>,
      required: true,
    },
    /** Тип */
    type: {
      type: String as PropType<InputType>,
      default: InputType.radio,
    },
    /** Описание (подпись) */
    title: {
      type: String as PropType<string>,
      default: '',
    },
    /** Описание (подпись) */
    description: {
      type: String as PropType<string>,
      default: '',
    },
    /** Значение для связывания */
    modelValue: {
      type: [String, null, Number] as PropType<string | null | number>,
      default: null,
    },
    /** Значение */
    value: {
      type: [String, Number] as PropType<string | number>,
      default: '',
    },
    /** Цена */
    price: {
      type: Number as PropType<number>,
    },
    /** Отключение поля */
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    /** Цвет */
    colorChecked: {
      type: String as PropType<string>,
      default: '#48DF80',
    },
  },

  computed: {
    /** Список классов */
    classes() {
      return {
        'payment-option-choice-button': true,
        disabled: this.disabled,
        checked: this.checked,
      }
    },

    /** Выбран ли текущий пункт */
    checked(): boolean {
      return this.value === this.modelValue
    },

    /** Цвет фона при активности */
    backgroundActive() {
      return this.colorValue ?? this.colorChecked
    },

    /** Стили */
    styles() {
      if (this.checked) {
        return {
          background: this.backgroundActive,
        }
      }

      return undefined
    },
  },

  setup() {
    const colorValue = inject<string | null>('colorValue', null)

    return {
      colorValue,
    }
  },

  methods: {
    /** Обработчик изменений */
    onChange() {
      this.$emit('update:checked', this.value)
    },
  },

  render(): VNode {
    return (
      <label class={this.classes} style={this.styles}>
        <input
          class={'payment-option-choice-button__field'}
          type={this.type}
          name={this.name}
          disabled={this.disabled}
          onInput={withModifiers(() => undefined, ['prevent'])}
          onChange={this.onChange}
          checked={this.checked}
        />

        <div class={'payment-option-choice-button__container'}>
          <div class={'payment-option-choice-button__container-left'}>
            <VTextInputButton>{this.$slots.default?.()}</VTextInputButton>

            <VTextSmall>{this.description}</VTextSmall>
          </div>

          <div class={'payment-option-choice-button__container-right'}>
            <div class={'payment-option-choice-button__price'}>
            {this.$t('pages.Plans.PaymentOptionChoice', { price: this.price })}
            </div>
          </div>
        </div>
      </label>)
  },
})
