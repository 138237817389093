/* Функции */
import { defineComponent, VNode } from 'vue'

/* Компоненты */
import VMainMenuItem from '@/components/VMainMenuItem/VMainMenuItem'

/* Стили */
import '@/components/TheMainMenu/TheMainMenu.scss'

/* Компоненты */
import { MenuItems } from '@/components/TheMainMenu/Types'

export default defineComponent({
  name: 'TheMainMenu',

  render(): VNode {
    return (
      <div class={'main-menu'}>
        {
          MenuItems.map(({
            imageName, text, title, path, isIncludeActive,
          }) => <VMainMenuItem
            imageName={imageName}
            text={text}
            titleIcon={title}
            path={path}
            isIncludeActive={isIncludeActive}
          />)
        }
      </div>
    )
  },
})
