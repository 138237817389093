export interface Data {
  queryParams: { isTelegramActive?: string, telegramToken?: string } | null,
  isTelegramActive: boolean
  telegramToken: string | null
  isShowPage: boolean
}

export enum QueryParams {
  /** Перенаправление на страницу при нажатии на закрытие */
  redirectBack = 'redirectBack'
}
