/* Функции */
import { defineComponent, PropType, VNode } from 'vue'

/* Стили */
import '@/components/VModal/VModal.scss'

/* Типы */
import { Data } from '@/components/VModal/Types'

export default defineComponent({
  name: 'VModal',

  emits: {
    /** Событие закрытия */
    onClose(value: boolean) {
      return typeof value === 'boolean'
    },
  },

  data(): Data {
    return {
      /** Признак показа окна */
      isShowPopup: false,
      /** Признак показа фона */
      isShowOverlay: false,
    }
  },

  props: {
    /** Признак показа */
    isShow: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    /** Признак второго варианта отображения */
    isSecondary: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    /** Таймаут показа фона */
    timeoutShowOverlay: {
      type: Number as PropType<number>,
      default: 100,
    },
    /** Таймаут показа окна */
    timeoutShowPopup: {
      type: Number as PropType<number>,
      default: 350,
    },
  },

  computed: {
    /** CSS классы */
    classes(): Record<string, boolean> {
      return {
        modal: true,
        secondary: this.isSecondary,
        'show-overlay': this.isShowOverlay,
        'show-popup': this.isShowPopup,
      }
    },
  },

  methods: {
    /** Обработка закрытия */
    onClose() {
      this.animationHide(() => {
        this.$emit('onClose', false)
      })
    },

    /**
     * Анимация показа
     * @param callback - Обратный вызов по завершении анимации
     */
    animationShow(callback?: () => void) {
      if (this.isShow) {
        setTimeout(() => {
          queueMicrotask(() => {
            this.isShowOverlay = true
          })
        }, this.timeoutShowOverlay)

        queueMicrotask(() => {
          setTimeout(() => {
            this.isShowPopup = true

            if (typeof callback === 'function') callback()
          }, this.timeoutShowPopup)
        })
      }
    },

    /**
     * Анимация скрытия
     * @param callback - Обратный вызов по завершении анимации
     */
    animationHide(callback?: () => void) {
      queueMicrotask(() => {
        this.isShowPopup = false

        setTimeout(() => {
          this.isShowOverlay = false
          setTimeout(() => {
            if (typeof callback === 'function') callback()
          }, this.timeoutShowPopup)
        }, this.timeoutShowOverlay)
      })
    },
  },

  watch: {
    /** Наблюдает за изменением видимости */
    isShow() {
      this.animationShow()
    },
  },

  mounted() {
    this.animationShow()
  },

  render(): VNode {
    return (
      <div class={this.classes}>
        <div class={'modal-overlay'} onClick={this.onClose} />

        <div class={'modal-popup'}>
          <div class={'modal-popup-content'}>
            {this.$slots.default?.()}
          </div>
        </div>
      </div>
    )
  },
})
