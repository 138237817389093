/* Функции */
import {
  defineComponent, h, PropType, VNode,
} from 'vue'

/* Компоненты */
import VRow from '@/components/VRow/VRow'
import VTabButton from '@/components/VTabs/VTabButton/VTabButton'

/* Стили */
import '@/components/VTabs/VTabs/VTabs.scss'

export default defineComponent({
  name: 'VTabs',

  emits: {
    /** Событие обновления значения */
    'update:modelValue': (value: string | number) => typeof value === 'string' || typeof value === 'number',
  },

  data() {
    return {
      isSetFirstValue: false,
    }
  },

  props: {
    modelValue: {
      type: [Number, String] as PropType<number | string>,
      default: 0,
    },
  },

  render(): VNode {
    const component = () => this.$slots.default?.().map((component, index) => {
      const isRenderComponent = (h(VTabButton).type as { name: string }).name === (component.type as { name: string }).name

      if (isRenderComponent) {
        const propsValue = component.props?.value ?? index

        if (index === 0 && !this.isSetFirstValue && typeof this.modelValue === 'number') {
          this.$emit('update:modelValue', propsValue)
          this.isSetFirstValue = true
        }

        return h(component, {
          value: propsValue,
          isActive: this.modelValue === propsValue,
          'onUpdate:modelValue': (value: number | string) => { this.$emit('update:modelValue', value) },
        })
      }

      return null
    })

    return (
      <VRow class={'tabs'} justify={'space-between'}>
        {component()}
      </VRow>
    )
  },
})
