/* Функции */
import {
  defineComponent, PropType, VNode,
} from 'vue'

/** Типы */
import { defaultClasses, defaultProps, defaultStyles } from '@/components/VText/Types'

/* Стили */
import '@/components/VText/VTextParagraph/VTextParagraph.scss'

export default defineComponent({
  name: 'VTextParagraph',

  props: {
    ...defaultProps({
      defaultOpacity: 0.8,
    }),

    /** Тег */
    tag: {
      type: String as PropType<string>,
      default: 'p',
    },
  },

  computed: {
    styles() {
      return {
        ...defaultStyles.call(this),
      }
    },

    classes() {
      return {
        ...defaultClasses.call(this),
        'text-paragraph': true,
      }
    },
  },

  render(): VNode {
    const nameComponent = this.tag

    return (
      <nameComponent onClick={this.onClick} class={this.classes} style={this.styles}>
        {this.$slots.default?.()}
      </nameComponent>
    )
  },
})
