/* Функции */
import { defineComponent, PropType, VNode } from 'vue'

/* Компоненты */
import VRow from '@/components/VRow/VRow'
import VTextSmall from '@/components/VText/VTextSmall/VTextSmall'

/* Типы */
import { Data } from '@/components/VSteps/VStepsNav/Types'

/* Стили */
import '@/components/VSteps/VStepsNav/VStepsNav.scss'

export default defineComponent({
  name: 'VStepsNav',

  data(): Data {
    return {
      /** Выбранный номер шага */
      currentStep: 0,
    }
  },

  props: {
    /** Значение связывания */
    modelValue: {
      type: Number as PropType<number>,
      required: true,
    },

    /** Значение связывания */
    maxStep: {
      type: Number as PropType<number>,
      required: true,
    },
  },

  computed: {
    /** Текст для отображения прогресса шагов */
    showStep() {
      const currentStep = this.currentStep + 1
      return currentStep > this.maxStep ? this.maxStep : currentStep
    },
  },

  watch: {
    modelValue() {
      this.currentStep = this.modelValue
    },
  },

  created() {
    this.currentStep = this.modelValue
  },

  render(): VNode {
    const getStepItems = () => {
      const result: VNode[] = []

      for (let index = 0; index < this.maxStep; index += 1) {
        const classes = {
          'steps-nav__item': true,
          active: index === this.currentStep,
          old: index < this.currentStep,
        }

        result.push(<div class={classes}/>)
      }

      return <div class={'steps-nav__items'}>{result}</div>
    }

    return (
      <div class={'steps-nav'}>
        <VRow alignItems='center'>
          {
            [
              getStepItems(),
              <VTextSmall>{`${this.showStep}/${this.maxStep}`}</VTextSmall>,
            ]
          }
        </VRow>
      </div>
    )
  },
})
