/* Функции */
import {
  defineComponent, h, HTMLAttributes, PropType, VNode,
} from 'vue'

/* Компоненты */
import VTextParagraph from '@/components/VText/VTextParagraph/VTextParagraph'
import VTransition from '@/components/VTransition/VTransition'

/* Стили */
import '@/components/VMoreText/VMoreText.scss'

export default defineComponent({
  name: 'VMoreText',

  data() {
    return {
      isOpen: false,
      isNoHtml: false,
      isOneUpdateVText: false,
    }
  },

  props: {
    /** Текст */
    text: {
      type: String as PropType<string>,
      default: '',
    },

    /** Прозрачность */
    opacity: {
      type: Number as PropType<number>,
      default: 0.8,
    },

    /** Клик на карточку целиком */
    onClick: {
      type: Function as PropType<HTMLAttributes['onClick']>,
    },

    /** Признак блокировки поднятия */
    isStop: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },

  methods: {
    /** Переключение видимости */
    toggle(event: Event) {
      this.isOpen = !this.isOpen

      if (this.isStop) {
        event.stopPropagation()
      }
    },
    /** Метод получения текста разбитого на массив */
    getTexts(text: string) {
      if (this.isNoHtml) {
        const proposalCount = text.match(/\./gi)?.length ?? 0

        if (proposalCount >= 2) {
          const firstPositionPoint = text.search(/\./)
          const firstBlock = `<p>${text.substring(0, firstPositionPoint)}.</p>`
          const endBlock = `<p>${text.substring(firstPositionPoint + 1)}</p>`
          return [firstBlock, endBlock]
        }
      }

      try {
        const searchRegexp = /<p>.+?<\/p>|<ul>.+?<\/ul>|<ol>.+?<\/ol>/gm
        const result = text.match(searchRegexp) ?? []

        return result.map((text) => text.replaceAll(/<p>|<\/p>/gmi, ''))
      } catch (error) {
        console.error('Не удалось обработать текст. Убедитесь, что в входящие данные в слоте являются строкой')
        return ''
      }
    },
  },

  computed: {
    /** Список текста */
    texts() {
      return this.getTexts(this.text)
    },

    /** CSS классы */
    classes() {
      return {
        'text-content': true,
        'no-html-text': this.isNoHtml,
        'open-text': this.isOpen,
      }
    },
  },

  render(): VNode {
    const searchRegexp = /<p>.+?<\/p>/gm

    const texts = this.$slots.default?.().length
      ? this.getTexts((this.$slots.default()[0].children) as unknown as string)
      : this.texts

    const shadowText = () => {
      const result = []

      for (let index = 1; index < texts.length; index += 1) {
        const innerHTML = texts[index]
        result.push(h(VTextParagraph, { innerHTML, opacity: this.opacity }))
      }

      return result
    }

    if (!this.isOneUpdateVText) {
      if (Array.isArray(texts)) {
        this.isNoHtml = !(texts.length > 1)
      } else {
        this.isNoHtml = !searchRegexp.test(texts)
      }
      this.isOneUpdateVText = true
    }

    const tagAnimationButton = this.isNoHtml ? 'span' : VTransition

    const text = <div class={this.classes} onClick={this.onClick}>
      {h(VTextParagraph, { innerHTML: `${texts?.[0] ?? ''} `, tag: 'span', opacity: this.opacity })}

      {texts.length > 1 ? [
        <tagAnimationButton mode={'default'} duration={this.isNoHtml ? 0 : 100} >
          {
            !this.isOpen && <VTextParagraph
              key={'show'}
              class={'text-content__button text-content__button-show'}
              onClick={this.toggle}
              tag={'span'}
              opacity={1}
            >
              {'Подробнее'}
            </VTextParagraph>
          }
        </tagAnimationButton>,

        <tagAnimationButton
          animationName={'slide'}
          mode={'default'}
        >
          {
            this.isOpen && <div class={'text-content__shadow'}>
              {shadowText()}

              <VTextParagraph
                key={'hide'}
                class={'text-content__button text-content__button-hide'}
                onClick={this.toggle}
                opacity={1}
                tag={'span'}
              >
                {'Скрыть'}
              </VTextParagraph>
            </div>
          }
        </tagAnimationButton>,
      ] : null}
    </div>

    return text
  },
})
