/* Функции */
import { defineComponent, PropType, VNode } from 'vue'

/* Компоненты */
import VImg from '@/components/VImg/VImg'
import VTextHeading1 from '@/components/VText/VTextHeading1/VTextHeading1'
import VTextParagraph from '@/components/VText/VTextParagraph/VTextParagraph'
import VRow from '@/components/VRow/VRow'
import BasePlanLabel from '@/components/BasePlanLabel/BasePlanLabel'

/* Стили */
import '@/components/BaseTargetCard/BaseTargetCard.scss'

/* Помощники */
import dayjs from 'dayjs'
import { toCapitalized } from '@/helpers/common'

/* Типы */
import { RouterLink } from 'vue-router'
import { RoutePaths } from '@/router/Types'
import { Level } from '@/types/API'

export default defineComponent({
  name: 'BaseTargetCard',

  props: {
    /** Путь к картинке */
    src: {
      type: String as PropType<string>,
      default: '',
    },
    /** Продолжительность */
    duration: {
      type: Number as PropType<number>,
      required: true,
    },
    /** Подпись */
    subTitle: {
      type: String as PropType<string>,
      default: '',
    },
    /** Заголовок */
    title: {
      type: String as PropType<string>,
      default: '',
    },
    /** Инвентарь */
    equipment: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    /** Идентификатор */
    id: {
      type: [String, Number] as PropType<string | number>,
      required: true,
    },
    /** Сложность */
    difficulty: {
      type: [Number, null] as PropType<(Level['id']) | null>,
      default: null,
    },
    /** Список меток */
    labels: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },

  computed: {
    /** Конечная дата продолжительность */
    startDate() {
      return dayjs(new Date()).format('D MMMM')
    },
    /** Конечная дата продолжительность */
    endDate() {
      return dayjs(new Date().setDate(this.duration)).format('D MMMM')
    },
    /** Отображаемая продолжительность */
    showDuration() {
      return `${this.startDate} - ${this.endDate}`
    },
    /** Отображаемый инвентарь */
    showEquipment() {
      return toCapitalized(this.equipment.join(', ').toLocaleLowerCase())
    },
    /** Отображать ли блок с инвентарем */
    isShowEquipment() {
      return Boolean(this.equipment.length)
    },
    /** Картинки при неудачной загрузке */
    failRandomImage() {
      return `target_default_${Math.floor(Math.random() * 3) + 1}.png`
    },
    /** Ссылка */
    to() {
      return RoutePaths.goalsDetail.replace(':id', String(this.id))
    },
  },

  render(): VNode {
    const {
      title,
      src,
      showDuration,
      failRandomImage,
    } = this

    return (
      <RouterLink
        class={'plan-card__link'}
        to={this.to}
      >
        <VRow
          tag='article'
          direction={'column'}
          justify={'space-between'}
          class={'plan-card'}
        >
          <div class={'plan-card__img-container'}>
            <VImg
              class={'plan-card__img'}
              failLoadSrc={failRandomImage}
              src={src}
              alt={title}
            />

            {
              this.labels.length
                ? <VRow class={'plan-card__labels'} direction={'column'} justify={'flex-end'}>
                  {
                    this.labels.map((label) => <BasePlanLabel
                      position={'left'}
                      label={label}
                    />)
                  }

                </VRow> : null
            }
          </div>

          <VRow
            class={'plan-card-header'}
            alignItems={'center'}
            justify={'space-between'}
            direction={'column'}
          >
            <VTextHeading1 isNotMargin={true} class={'plan-card__title'}>{title}</VTextHeading1>

            <VTextParagraph opacity={1} class={'plan-card__duration'}>{showDuration}</VTextParagraph>
          </VRow>
        </VRow>
      </RouterLink>
    )
  },
})
