/* Типы */
import { NavigationGuard, RouteLocationNormalized } from 'vue-router'
import { RouterItem } from '@/router/Types'

/* Классы */
import i18n from '@/locales/index'

/** Промежуточное ПО установки заголовков на странице */
export const SetTitleMiddleware:
  NavigationGuard = (to: RouteLocationNormalized, from, next) => {
    const { pageTitle } = to.meta as RouterItem['meta']

    if (!pageTitle) {
      document.title = i18n.global.t('common.title')
      next()
      return
    }

    document.title = `${i18n.global.t('common.title')} | ${pageTitle}`

    next()
  }
