/* Функции */
import { defineComponent, VNode, h } from 'vue'

/* Компоненты */
import VPage from '@/components/VPage/VPage'
import VContentItem from '@/components/VContent/VContentItem/VContentItem'
import VTransition from '@/components/VTransition/VTransition'
import VPreloader from '@/components/VPreloader/VPreloader'
import VRow from '@/components/VRow/VRow'
import VTextParagraph from '@/components/VText/VTextParagraph/VTextParagraph'
import VButton from '@/components/VButton/VButton'

/* Классы */
import { RequestAPI } from '@/classes/Request'

/* Стили */
import '@/views/Payments/AfterPayments/AfterPayments.scss'

/* Типы */
import { PaymentMethod, PaymentMethodsResponse } from '@/types/API'
import { RoutePaths } from '@/router/Types'
import { Data, StatePage } from './Types'

export default defineComponent({
  name: 'AfterPaymentsView',

  data(): Data {
    return {
      savedMethodId: null,
      savedMethods: [],
      state: StatePage.pending,
      attemptsCount: 0,
    }
  },

  methods: {
    /** Установка данных по методам */
    async setSavedMethods() {
      const response = await RequestAPI.errorHandler<PaymentMethodsResponse>(RequestAPI.get, '/payments/saved_methods')

      if (response) {
        const { data } = response
        this.savedMethods = data.content.payment_methods
      }
    },

    /** Проверка привязки метода */
    check() {
      if (!this.savedMethods || !this.findPaymentMethod) {
        this.state = StatePage.fail
        return
      }

      if (this.findPaymentMethod.state === 'current') {
        this.state = StatePage.success
        return
      }

      // Меняем счетчик попыток
      this.attemptsCount += 1

      if (this.attemptsCount <= 3) {
        // пробуем еще раз через 20 секунд
        setTimeout(async () => {
          await this.setSavedMethods()
          this.check()
        }, 20000)
      }
    },
  },

  computed: {
    findPaymentMethod(): PaymentMethod | null {
      return this.savedMethods.find((method) => method.id === this.savedMethodId) ?? null
    },
  },

  async created() {
    const { query } = this.$route

    if (query.payment_saved_method_id) {
      this.savedMethodId = Number(query.payment_saved_method_id)
    }

    await this.setSavedMethods()
    this.check()
  },

  render(): VNode {
    const content = () => {
      switch (this.state) {
        case StatePage.pending:
          return <VPreloader isShow={true} key={StatePage.pending}>{this.$t('pages.AfterPayments.pending')}</VPreloader>

        case StatePage.fail:
          return <VRow direction={'column'} key={StatePage.fail}>
            {h(VTextParagraph, { innerHTML: this.$t('pages.AfterPayments.fail'), class: 'after-payments__text' })}

            <VButton isSecondary onClick={this.setSavedMethods}>{this.$t('pages.AfterPayments.buttons.tryAgain')}</VButton>
            <VButton isTertiary onClick={() => { this.$router.replace(RoutePaths.paymentMethods) }}>{this.$t('pages.AfterPayments.buttons.cancel')}</VButton>
          </VRow>

        case StatePage.success:
          return <VRow direction={'column'} key={StatePage.success}>
            <VTextParagraph class={'after-payments__text'}>{this.$t('pages.AfterPayments.success')}</VTextParagraph>

            <VButton onClick={() => { this.$router.replace(RoutePaths.main) }}>{this.$t('pages.AfterPayments.buttons.toSubscription')}</VButton>
          </VRow>
        default:
          return null
      }
    }

    return (
      <VPage class={'after-payments'}>
        <VContentItem isPaddingX={true}>
          <VTransition>
            {content()}
          </VTransition>
        </VContentItem>
      </VPage>
    )
  },
})
