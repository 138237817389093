/* Функции */
import { defineComponent, VNode } from 'vue'

/* Компоненты */
import VButtonFixes from '@/components/VButton/VButtonIcon/VButtonFixes/VButtonFixes'
import VContentItem from '@/components/VContent/VContentItem/VContentItem'
import VPage from '@/components/VPage/VPage'
import VRow from '@/components/VRow/VRow'
import VTextButton from '@/components/VText/VTextButton/VTextButton'
import VTransition from '@/components/VTransition/VTransition'
import VInputArea from '@/components/VInput/VInputArea/VInputArea'
import VTextHeading2 from '@/components/VText/VTextHeading2/VTextHeading2'
import VTextSmall from '@/components/VText/VTextSmall/VTextSmall'
import VInputButton from '@/components/VInput/VInputButton/VInputButton'

/* Стили */
import '@/views/Profile/MyData/MyData.scss'

/* Типы */
import { Data } from '@/views/Profile/MyData/Types'
import { RequestAPI } from '@/classes/Request'
import { Level, Profile } from '@/types/API'
import { InputType } from '@/components/VInput/VInputButton/Types'
import { IconsByLevelSport } from '@/types/constants/common'

/* Хранилище */
import { LayoutStore } from '@/store/layout'

export default defineComponent({
  name: 'MyDataPage',

  data(): Data {
    return {
      age: null,
      weight: null,
      growth: null,
      level: null,
      isLoadData: false,
      data: null,
      isSaved: false,
      startData: {
        age: null,
        weight: null,
        growth: null,
        level: null,
      },
    }
  },

  methods: {
    updateStartData() {
      this.startData = {
        age: this.age,
        weight: this.weight,
        growth: this.growth,
        level: this.level,
      }
    },

    async save() {
      LayoutStore().setLoading(true)
      const {
        age, weight, growth, level,
      } = this

      this.isSaved = true

      await RequestAPI.errorHandler<Profile>(RequestAPI.put, '/profile', {
        athlete: {
          age,
          weight,
          growth,
          level: Number(level),
        },
      })

      this.updateStartData()

      LayoutStore().setLoading(false)
      this.isSaved = false
    },
  },

  computed: {
    isChange(): boolean {
      const {
        age, weight, growth, level, startData,
      } = this

      return startData.age !== age
        || startData.weight !== weight
        || startData.growth !== growth
        || startData.level !== level
        || startData.level !== level
    },
  },

  async created() {
    LayoutStore().setLoading(true)

    const response = await RequestAPI.errorHandler<Profile>(RequestAPI.get, '/profile')

    if (response) {
      this.age = response.data.content.athlete.age
      this.weight = response.data.content.athlete.weight
      this.growth = response.data.content.athlete.growth
      this.level = response.data.content.athlete.level.id

      this.data = response.data.content

      this.updateStartData()
    }

    LayoutStore().setLoading(false)
    this.isLoadData = true
  },

  render(): VNode {
    const levels = () => this.data?.levels.map((level, index) => (<VInputButton
      key={`level-${index}`}
      name='level'
      type={InputType.radio}
      value={level.id}
      onUpdate:checked={(value) => { this.level = value as Level['id'] }}
      modelValue={Number(this.level)}
      imageName={IconsByLevelSport[Number(level.id)]}
    >
      {level.title}
    </VInputButton>))

    return (
      <VPage class="my-data">
        <VContentItem isPaddingX={true} isShow={this.isLoadData}>
          <VRow>
            <VTextHeading2>{this.$t('pages.Profile.myData.titleData')}</VTextHeading2>
          </VRow>

          <VRow>
            <VInputArea
              isSecondary={true}
              key={'valueAge'}
              title={this.$t('pages.Profile.myData.fields.age.label')}
              onUpdate:modelValue={(value) => { this.age = Number(value.value) }}
              modelValue={String(this.age)}
              type={'number'}
              placeholder={this.$t('pages.Profile.myData.fields.age.placeholder', this.age ?? 0)}
              inputmode={'numeric'}
              isHidePlaceholder={false}
              pattern='\d*'
            />
          </VRow>

          <VRow>
            <VInputArea
              isSecondary={true}
              key={'valueWeight'}
              title={this.$t('pages.Profile.myData.fields.weight.label')}
              onUpdate:modelValue={(value) => { this.weight = Number(value.value) }}
              modelValue={String(this.weight)}
              type={'number'}
              inputmode={'numeric'}
              placeholder={this.$t('pages.Profile.myData.fields.weight.placeholder')}
              isHidePlaceholder={false}
              pattern='\d*'
            />
          </VRow>

          <VRow>
            <VInputArea
              isSecondary={true}
              key={'valueGrowth'}
              title={this.$t('pages.Profile.myData.fields.growth.label')}
              onUpdate:modelValue={(value) => { this.growth = Number(value.value) }}
              modelValue={String(this.growth)}
              type={'number'}
              inputmode={'numeric'}
              placeholder={this.$t('pages.Profile.myData.fields.growth.placeholder')}
              isHidePlaceholder={false}
              pattern='\d*'
            />
          </VRow>
        </VContentItem>

        <VContentItem isPaddingX={true} isShow={this.isLoadData}>
          <VRow>
            <VTextHeading2>{this.$t('pages.Profile.myData.titleLevel')}</VTextHeading2>
          </VRow>

          {levels()}

          <VTextSmall textAlign={'left'} class={'text-input__subtext'}>{this.$t('pages.Profile.myData.levelDescription')}</VTextSmall>

        </VContentItem>

        <VTransition>
          {
            this.isChange
              ? <VButtonFixes
                isFullWidth={true}
                isDisabled={this.isSaved}
                onClick={this.save}>
                <VTextButton>{this.$t('pages.Profile.myData.button')}</VTextButton>
              </VButtonFixes>
              : null
          }
        </VTransition>
      </VPage>
    )
  },
})
