/* Функции */
import { defineComponent, PropType, VNode } from 'vue'

/* Компоненты */
import VRow from '@/components/VRow/VRow'
import VTextParagraph from '@/components/VText/VTextParagraph/VTextParagraph'
import VTextSubTitle from '@/components/VText/VTextSubTitle/VTextSubTitle'
import VTextHeading1 from '@/components/VText/VTextHeading1/VTextHeading1'
import VMoreText from '@/components/VMoreText/VMoreText'

/* Стили */
import '@/components/BaseMyTargetCard/BaseMyTargetCard.scss'

/* Типы */
import { RoutePaths } from '@/router/Types'
import { Cycle } from '@/types/API'

/* Классы */
import dayjs from 'dayjs'

export default defineComponent({
  name: 'BaseMyTargetCard',

  props: {
    /** Идентификатор */
    id: {
      type: [String, Number] as PropType<string | number>,
      required: true,
    },
    /** Продолжительность */
    duration: {
      type: Number as PropType<number>,
      required: true,
    },
    /** Заголовок */
    title: {
      type: String as PropType<string>,
      default: '',
    },
    /** Подпись */
    subTitle: {
      type: String as PropType<string>,
      default: '',
    },
    /** Описание */
    description: {
      type: String as PropType<string>,
      default: '',
    },
    /** Циклы */
    cycles: {
      type: Array as PropType<Cycle[]>,
      default: () => [],
    },
    /** Текст перед счетом циклов */
    cyclesTitle: {
      type: String as PropType<string>,
      default: '',
    },
  },

  computed: {
    /** Отображаемая дата */
    showDate(): string {
      const dates: number[] = []

      this.cycles.forEach(({ start_date, end_date }) => {
        dates.push(new Date(start_date).getTime())
        dates.push(new Date(end_date).getTime())
      })
      const maxDate = new Date(Math.max.apply(null, dates))
      const minDate = new Date(Math.min.apply(null, dates))

      return `${dayjs(minDate).format('DD MMMM')} - ${dayjs(maxDate).format('DD MMMM')}`
    },
    /** Отображаемая подпись */
    showSubTitle(): string {
      return this.subTitle ? `[ ${this.subTitle} ]` : ''
    },
    /** Ссылка */
    to(): string {
      return RoutePaths.myGoalsDetail.replace(':id', String(this.id))
    },
    /** Отображаемые циклы */
    showCycle() {
      const completeCount = this.cycles.filter((cycle) => dayjs(cycle.end_date) < dayjs()).length

      return `${this.cyclesTitle} ${completeCount}/${this.cycles.length}`
    },

  },

  methods: {
    onClickCard() {
      this.$router.push(this.to)
    },
  },

  render(): VNode {
    return (
      <div
        class={'my-target-card'}
        onClick={this.onClickCard}
      >
        <VRow direction={'column'}>
          <VTextParagraph opacity={1} class={'my-target-card__text'}>{this.showDate}</VTextParagraph>

          <VTextSubTitle class={'my-target-card__text'}>{this.showSubTitle}</VTextSubTitle>

          <VTextHeading1 class={'my-target-card__text'}>{this.title}</VTextHeading1>

          <VMoreText class={'my-target-card__text'} isStop={true}>{this.description}</VMoreText>
        </VRow>

        <VRow>
          <VTextParagraph opacity={1} class={'my-target-card__cycles'}>{this.showCycle}</VTextParagraph>
        </VRow>
      </div>
    )
  },
})
