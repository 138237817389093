/**
 * Проверяет, пустое ли значение.
 * @param value - значение
 * @returns результат проверки
 */
export function isEmpty(value: any): boolean {
  // Примитивные значения, которые в полях формы признаются пустыми. 0 пустым не признаётся.
  const emptyValues = new Set([undefined, null, '', false])

  // Строки, состоящие из пробелов, считаем пустыми.
  if (value && typeof value.trim === 'function') {
    value = value.trim()
  }

  if (emptyValues.has(value)) {
    return true
  }

  // Массивы в качестве значений могут быть.
  if (Array.isArray(value) && value.length === 0) {
    return true
  }

  return false
}

/**
 * Проверяет, является ли значение числом в человеческом понимании, не в смысле типа.
 * @param value - значение
 * @returns результат проверки
 */
export function coercibleToNumber(value: any): boolean {
  return Number.isFinite(Number(value)) && Number.isFinite(parseFloat(value))
}
