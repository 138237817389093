/* Функции */
import { defineComponent, PropType, VNode } from 'vue'

/* Стили */
import '@/components/VProgressLine/VProgressLine.scss'

/* Типы */
import { Data } from '@/components/VProgressLine/Types'

export default defineComponent({
  name: 'VProgressLine',

  emits: {
    /** Событие завершения таймера отчета */
    timeOut: (value: boolean) => typeof value === 'boolean',
    /** Событие прогресса */
    progress: (value: number) => typeof value === 'number',
  },

  data(): Data {
    return {
      processWidth: 0,
    }
  },

  props: {
    /** Ширина (по-умолчанию в процентах) */
    width: {
      type: Number as PropType<number>,
      default: 0,
    },

    /** Единица измерения ширины (по-умолчанию в процентах) */
    unit: {
      type: String as PropType<string>,
      default: '%',
    },

    /** Сколько кол-во раз выполнять таймер */
    timeRanges: {
      type: Number as PropType<number>,
      default: 100,
    },

    /** Таймер */
    time: {
      type: [Number, null] as PropType<number | null>,
      default: null,
    },

    /** Смягчение таймера */
    transitionValue: {
      type: Number as PropType<number>,
      default: 10,
    },
  },

  computed: {
    /** Стили */
    style() {
      return {
        width: `${this.processWidth}${this.unit}`,
      }
    },
  },

  methods: {
    /**
     * Увеличение прогресса
     * @param value - прогресс
     */
    progressUp(value: number) {
      this.processWidth += value
      this.$emit('progress', this.processWidth)
    },
  },

  watch: {
    /** Наблюдает за шириной и присваивает ширину изз параметров, к локальной */
    width() {
      this.processWidth = this.width
    },
  },

  created() {
    this.processWidth = this.width

    if (this.time && this.unit === '%') {
      let timer: ReturnType<typeof setInterval> | null = null
      const interval = this.time / this.timeRanges

      const progress = () => {
        if (this.processWidth < 100) {
          this.progressUp(1 / this.transitionValue)
        } else if (timer) {
          clearInterval(timer)
          this.$emit('timeOut', true)
        }
      }

      timer = setInterval(progress, interval / this.transitionValue)
    }
  },

  render(): VNode {
    return (
      <div class={'progress-line'}>
        <div class={'progress-line__indicator'} style={this.style} />
      </div>
    )
  },
})
