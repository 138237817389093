/* Функции */
import { defineComponent, PropType, VNode } from 'vue'

/* Стили */
import '@/components/VTabs/VTabButton/VTabButton.scss'

export default defineComponent({
  name: 'VTabButton',

  emits: {
    'update:modelValue': (value: string | number) => typeof value === 'string' || typeof value === 'number',
  },

  props: {
    value: {
      type: [Number, String] as PropType<number | string>,
      default: '',
    },

    isActive: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },

  computed: {
    classes() {
      return {
        'tab-button': true,
        active: this.isActive,
      }
    },
  },

  render(): VNode {
    return (
      <div class={this.classes} onClick={() => {
        this.$emit('update:modelValue', this.value)
      }}>
        {this.$slots.default?.()}
      </div>
    )
  },
})
