/* Функции */
import {
  defineComponent, HTMLAttributes, PropType, VNode,
} from 'vue'

/* Компоненты */

/* Стили */
import '@/components/BasePaymentCard/BasePaymentCard.scss'
import VRow from '../VRow/VRow'
import VButton from '../VButton/VButton'
import VTextParagraph from '../VText/VTextParagraph/VTextParagraph'
import VIcon from '../VIcon/VIcon'
import VTextInputButton from '../VText/VTextInputButton/VTextInputButton'

export default defineComponent({
  name: 'BasePaymentCard',

  props: {
    /** Клик на кнопку отменить */
    onClick: {
      type: Function as PropType<HTMLAttributes['onClick']>,
    },

    /** Текст кнопки  */
    textButton: {
      type: String as PropType<string>,
      default: '',
    },

    /** Заголовок  */
    title: {
      type: String as PropType<string>,
      default: '',
    },

    /** Название платежной системы  */
    namePaymentSystem: {
      type: String as PropType<string>,
      default: '',
    },

    /** Название иконки  */
    imageName: {
      type: String as PropType<string>,
      default: 'pay_default',
    },

    /** Номер карты */
    numberCard: {
      type: String as PropType<string>,
      default: '',
    },
  },

  computed: {
    textCard() {
      if (this.namePaymentSystem && this.numberCard) {
        return `${this.namePaymentSystem} ** ${this.numberCard}`
      }

      return this.namePaymentSystem || this.numberCard
    },
  },

  render(): VNode {
    return (
      <VRow class={'payment-card'} alignItems={'center'} justify={'space-between'}>
        <VRow class={'payment-card-left'} direction={'column'}>
          <VTextParagraph class={'payment-card-title'} opacity={0.5}>{this.title}</VTextParagraph>

          {
            this.numberCard ? <VRow alignItems={'center'}>
              <VIcon class={'payment-card-icon'} imageName={this.imageName} />

              <VTextInputButton class={'payment-card-number'}>{this.textCard}</VTextInputButton>
            </VRow> : null
          }
        </VRow>

        <VRow class={'payment-card-right'}>
          <VButton
            isTertiary
            isFullWidth={false}
            onClick={this.onClick}
          >
            {this.textButton}
          </VButton>
        </VRow>
      </VRow>
    )
  },
})
