/* Функции */
import { defineComponent, VNode } from 'vue'

/* Компоненты */
import VFullScreen from '@/components/VFullScreen/VFullScreen'
import BaseWorkoutCard from '@/components/BaseWorkoutCard/BaseWorkoutCard'
import TheWorkoutCalendar from '@/components/TheWorkoutCalendar/TheWorkoutCalendar'
import VRow from '@/components/VRow/VRow'
import VTransition from '@/components/VTransition/VTransition'

/* Классы */
import { RequestAPI } from '@/classes/Request'

/* Стили */
import '@/views/Main/Main.scss'

/* Помощники */
import dayjs from 'dayjs'

/* Типы */
import { ResponseMain, Workout, WorkoutStates } from '@/types/API'
import {
  Category, Data, EquipmentItem, Goal, WorkoutAdvanced,
} from '@/views/Main/Types'
import { Maybe } from '@/types'
import {
  CurrentAllWorkoutValue, DaysWeeksView, FormatDate, WorkoutByDate,
} from '@/components/TheWorkoutCalendar/Types'
import { RoutePaths } from '@/router/Types'

/* Хранилище */
import { LayoutStore } from '@/store/layout'
import VText from '@/components/VText/VText/VText'

export default defineComponent({
  name: 'MainView',

  data(): Data {
    return {
      workouts: [],
      goals: [],
      equipmentItems: {},
      categories: [],
      currentDate: '',
      currentWeek: 0,
      isGetData: false,
    }
  },

  computed: {
    /** Тренировки по датам */
    workoutsByDates(): Record<string, Workout[]> {
      const startDate = dayjs().weekday(0)
      const limit = startDate.add(DaysWeeksView, 'day')

      return this.workouts.reduce<Record<string, Workout[]>>((result, workout) => {
        const key = dayjs(workout.performed_at)

        if (limit >= key) {
          if (!result[String(key)]) {
            result[String(key)] = []
          }

          result[String(key)].push(workout)
        }

        return result
      }, {})
    },

    /** Доступные даты тренировок */
    availableDates(): string[] {
      const dates = Object.keys(this.workoutsByDates)
      console.log(dates)
      const sortDates = dates.sort((a, b) => dayjs(a).unix() - dayjs(b).unix())

      return sortDates
    },

    /** Ближайшая дата тренировки */
    nextWorkoutDate(): string | null {
      return this.availableDates.find(
        (date) => dayjs().hour(0).minute(0).second(0)
          .millisecond(0)
          .unix() <= dayjs(date).hour(0).minute(0).second(0)
          .millisecond(0)
          .unix(),
      ) ?? null
    },

    /** Текущие тренировки */
    currentWorkoutByDate(): WorkoutAdvanced[] {
      if (this.currentDate === CurrentAllWorkoutValue) {
        const startDate = dayjs().weekday(0)
        const weekData: WorkoutAdvanced[] = []

        for (let index = this.currentWeek * 7; index < 7 * (this.currentWeek + 1); index += 1) {
          const date = startDate.set('day', index + 1).format(FormatDate)

          weekData.push(...this.getCurrentWorkoutByCurrentDate(String(dayjs(date))))
        }

        return weekData
      }

      if (this.currentDate) {
        return this.getCurrentWorkoutByCurrentDate(String(dayjs(this.currentDate)))
      }

      return []
    },

    /** Даты по тренировкам */
    dataForWorkoutCalendar(): WorkoutByDate {
      const result: WorkoutByDate = {}

      for (const key in this.workoutsByDates) {
        if (Object.prototype.hasOwnProperty.call(this.workoutsByDates, key)) {
          const element = this.workoutsByDates[key]

          result[dayjs(key).format(FormatDate)] = {
            all: element.length,
            completed: element.filter((workout) => workout.state === WorkoutStates.completed).length ?? 0,
          }
        }
      }

      return result
    },
  },

  methods: {
    /** Получение данных по тренировкам */
    async getDataWorkout() {
      const startDate = dayjs().weekday(0)
      const data = await RequestAPI.errorHandler<ResponseMain>(RequestAPI.get, `/workouts?starts_at=${startDate.format('YYYY-MM-DD')}`)

      if (data) {
        return data.data.content
      }

      return null
    },

    getCurrentWorkoutByCurrentDate(key: string): WorkoutAdvanced[] {
      return (this.workoutsByDates[key] ?? []).reduce<WorkoutAdvanced[]>((workouts, workout) => {
        const categoryId = Number(workout.category_id)
        const goalId = Number(workout.goal_id)
        const category: Maybe<Category> = this.categories?.find((category) => category.id === categoryId)
        const goal: Maybe<Goal> = this.goals.find((goal) => goal.id === goalId)
        const equipmentItems = workout.equipment_item_ids.reduce<string[]>((equipmentsAcc, equipment) => {
          const findEquipment = this.equipmentItems[equipment]

          if (findEquipment && findEquipment.title) {
            equipmentsAcc.push(findEquipment.title)
          }

          return equipmentsAcc
        }, [])

        workouts.push({
          ...workout,
          category,
          goal,
          equipmentItems,
        })

        return workouts
      }, [])
    },

    /** Установка всех данных */
    async setAllDataWorkout() {
      const data = await this.getDataWorkout()

      if (data) {
        this.categories = data.categories
        this.equipmentItems = data.equipment_items
        this.equipmentItems = data.equipment_items.reduce<Record<EquipmentItem['id'], EquipmentItem>>((equipmentCollections, equipment) => {
          equipmentCollections[equipment.id] = equipment
          return equipmentCollections
        }, {})
        this.workouts = data.workouts
        this.goals = data.goals
      }

      this.isGetData = true
    },
  },

  watch: {
    workoutsByDates() {
      const date = this.nextWorkoutDate ? dayjs(this.nextWorkoutDate) : null
      this.currentDate = date?.format(FormatDate) ?? ''
    },
  },

  async created() {
    LayoutStore().setLoading(true)
    await this.setAllDataWorkout()
    LayoutStore().setLoading(false)
  },

  render(): VNode {
    return (
      <VFullScreen class={'main-view'}>
        <TheWorkoutCalendar
          workoutByDate={this.dataForWorkoutCalendar}
          textAll={this.$t('pages.Main.workouts.textAll')}
          modelValue={this.currentDate ?? ''}
          week={this.currentWeek}
          onUpdate:modelValue={(value) => { this.currentDate = value }}
          onUpdate:week={(value) => { this.currentWeek = value }}
        />

        <VTransition>
          <VRow class={'workout-container'} direction={'column'} key={this.currentDate ?? ''}>
            {
              this.isGetData && this.currentWorkoutByDate.map((workout) => (
                <BaseWorkoutCard
                  key={workout.id}
                  id={workout.id}
                  src={workout.image ?? ''}
                  duration={workout.duration ?? 0}
                  subTitle={workout.category?.title ?? ''}
                  type={workout.goal?.title ?? ''}
                  title={workout.title ?? ''}
                  state={workout.state}
                  durationPostfixText={this.$t('pages.Main.workouts.durationPostfixText')}
                  stateCompletedText={this.$t('pages.Main.workouts.stateCompletedText')}
                  proTextLabel={this.$t('pages.Main.workouts.proTextLabel')}
                  equipment={workout.equipmentItems}
                  onClick={() => {
                    this.$router.push({
                      path: RoutePaths.workoutDetail.replace(':id', String(workout.id)),
                    })
                  }}
                />
              ))
            }

            {this.currentWorkoutByDate.length === 0 && this.isGetData ? <VText class={'main-view__no-text'}>{this.$t('pages.Main.workouts.moWorkout')}</VText> : null}
          </VRow>
        </VTransition>

      </VFullScreen>
    )
  },
})
