/* Функции */
import {
  defineComponent, HTMLAttributes, PropType, VNode,
} from 'vue'

/* Компоненты */
import VRow from '@/components/VRow/VRow'
import VImg from '@/components/VImg/VImg'
import VTextSubTitle from '@/components/VText/VTextSubTitle/VTextSubTitle'
import VTextHeading1 from '@/components/VText/VTextHeading1/VTextHeading1'
import VIcon from '@/components/VIcon/VIcon'
import VTextParagraph from '@/components/VText/VTextParagraph/VTextParagraph'
import VLabel from '@/components/VLabel/VLabel'

/* Стили */
import '@/components/BaseWorkoutCard/BaseWorkoutCard.scss'

/* Помощники */
import { toCapitalized } from '@/helpers/common'

/* Типы */
import { Level, WorkoutStates, WorkoutStatesT } from '@/types/API'
import { IconsByLevelSport } from '@/types/constants/common'

export default defineComponent({
  name: 'BaseWorkoutCard',

  props: {
    /** Путь к картинке */
    src: {
      type: String as PropType<string>,
      default: '',
    },
    /** Продолжительность */
    duration: {
      type: Number as PropType<number>,
      required: true,
    },
    /** Подпись */
    subTitle: {
      type: String as PropType<string>,
      default: '',
    },
    /** Заголовок */
    title: {
      type: String as PropType<string>,
      default: '',
    },
    /** Инвентарь */
    equipment: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    /** Идентификатор */
    id: {
      type: [String, Number] as PropType<string | number>,
      required: true,
    },
    /** Сложность */
    difficulty: {
      type: [Number, null] as PropType<(Level['id']) | null>,
      default: null,
    },
    /** Состояние */
    state: {
      type: String as PropType<WorkoutStatesT>,
      default: WorkoutStates.pending,
    },
    /** Профисианальный */
    isPro: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    /** Постфикс после продолжительности */
    durationPostfixText: {
      type: String as PropType<string>,
      default: '',
    },
    /** Текст состояния */
    stateCompletedText: {
      type: String as PropType<string>,
      default: '',
    },
    /** Иконка состояния */
    iconState: {
      type: String as PropType<string>,
      default: 'completed',
    },
    /** Иконка про версии */
    iconPro: {
      type: String as PropType<string>,
      default: 'pro',
    },
    /** Тип тренировки (основная, к примеру) */
    type: {
      type: String as PropType<string>,
      default: '',
    },
    /** Текст про версии в label  */
    proTextLabel: {
      type: String as PropType<string>,
      default: '',
    },
    /** Иконка про версии в label */
    proIconLabel: {
      type: String as PropType<string>,
      default: '',
    },
    /** Клик на карточку целиком */
    onClick: {
      type: Function as PropType<HTMLAttributes['onClick']>,
    },
    /** Клик на на кнопку действия */
    onClickAction: {
      type: Function as PropType<HTMLAttributes['onClick']>,
    },
  },

  computed: {
    /** Отображаемый инвентарь */
    showEquipment() {
      return toCapitalized(this.equipment.join(', ').toLocaleLowerCase())
    },
    /** Отображать ли блок с инвентарем */
    isShowEquipment() {
      return Boolean(this.equipment.length)
    },
    /** Картинки при неудачной загрузке */
    failRandomImage() {
      return `workout_default_${Math.floor(Math.random() * 3) + 1}.png`
    },
    /** CSS классы */
    classes() {
      return {
        'base-card-workout': true,
        [`state-${this.state}`]: true,
      }
    },
  },

  render(): VNode {
    const {
      title,
      src,
      failRandomImage,
      showEquipment,
      isShowEquipment,
      subTitle,
      difficulty,
      duration,
      durationPostfixText,
      type,
      state,
      iconState,
      proTextLabel,
      isPro,
      stateCompletedText,
      iconPro,
      classes,
    } = this

    const level = (
      difficulty
        ? <VIcon
          class={'base-card-workout__difficulty'}
          imageName={IconsByLevelSport[difficulty]}
        />
        : null
    )

    const equipment = isShowEquipment ? (
      <VRow class={'base-card-workout__equipment'} alignItems={'center'}>
        <VIcon
          class={'base-card-workout__equipment_icon'}
          imageName={'equipment'}
        />

        <VTextParagraph opacity={0.7}>{showEquipment}</VTextParagraph>
      </VRow>
    ) : null

    const proVersion = <VRow class={'base-card-workout__pro-version'}>
      <VIcon
        class={'base-card-workout__pro-version_icon'}
        imageName={iconPro}
      />

      <VTextParagraph opacity={0.7}>{proTextLabel}</VTextParagraph>
    </VRow>

    const stateComponent = state === 'pending' ? null : <VRow class={'base-card-workout__state'} alignItems={'center'}>
      <VIcon
        class={'base-card-workout__state_icon'}
        imageName={iconState}
      />

      <VTextParagraph class={'base-card-workout__state_text'} opacity={1}>{stateCompletedText}</VTextParagraph>
    </VRow>

    const actionComponent = <VRow class={'base-card-workout__action'} onClick={this.onClickAction}>
      <VIcon
        class={'base-card-workout__action_icon'}
        imageName={state !== 'completed' ? 'workout_run' : 'workout_refresh'}
      />
    </VRow>

    const stateComposition = isPro && state !== 'completed' ? proVersion : stateComponent

    return (
      <VRow class={classes} onClick={this.onClick} >
        <VRow class={'base-card-workout__content'} alignItems={'stretch'}>
          <VRow class={'base-card-workout__description'} direction={'column'}>
            <VRow
              class={'base-card-workout__header'}
              alignItems={'center'}
              justify={'space-between'}
            >
              <VRow alignItems={'center'}>
                <VLabel>
                  {`${duration} ${durationPostfixText}`}
                </VLabel>

                <VTextSubTitle fontWeight={400} opacity={1} class={'base-card-workout__type'}>{type}</VTextSubTitle>
              </VRow>

              {level}
            </VRow>

            <VRow class={'base-card-workout__body'} direction={'column'}>
              {subTitle && <VTextSubTitle class={'base-card-workout__subtitle'}>{`[ ${subTitle} ]`}</VTextSubTitle>}

              <VTextHeading1 class={'base-card-workout__title'}>{title}</VTextHeading1>

              {equipment}
            </VRow>
          </VRow>

          <VRow class={'base-card-workout__img-container'}>
            {stateComposition}

            {actionComponent}

            <VImg
              class={'base-card-workout__img'}
              failLoadSrc={failRandomImage}
              src={src}
              alt={' '}
            />
          </VRow>
        </VRow>
      </VRow>
    )
  },
})
