/* Функции */
import { defineComponent, PropType, VNode } from 'vue'

/* Компоненты */
import VRow from '@/components/VRow/VRow'
import VText from '@/components/VText/VText/VText'
import VIcon from '@/components/VIcon/VIcon'

/* Стили */
import '@/components/VBanner/VBannerMobile/VBannerMobile.scss'

/* Типы */
import { BannerBackgroundColor, BannerMode } from '@/components/VBanner/Types'
import { Data } from '@/components/VBanner/VBannerMobile/Types'

export default defineComponent({
  name: 'VBannerMobile',

  emits: {
    close() {
      return true
    },
  },

  data(): Data {
    return {
      isShow: true,
      timeAnimation: 200,
    }
  },

  props: {
    /** Имя файла */
    imageName: {
      type: String as PropType<string>,
      default: '',
      required: true,
    },

    /** Имя файла закрытия */
    closeImageName: {
      type: String as PropType<string>,
      default: 'close',
    },

    /** Режим отображения */
    mode: {
      type: String as PropType<BannerMode>,
      default: BannerMode.default,
    },

    /** Фон */
    backgroundColor: {
      type: String as PropType<BannerBackgroundColor>,
      default: BannerBackgroundColor.primary,
    },
  },

  computed: {
    classes() {
      return {
        banner: true,
        [this.mode]: true,
        banner_mobile: true,
        'is-show': this.isShow,
      }
    },

    styles() {
      return {
        background: this.backgroundColor,
      }
    },
  },

  methods: {
    onClose() {
      this.isShow = false

      setTimeout(() => {
        this.$emit('close')
      }, this.timeAnimation)
    },
  },

  render(): VNode {
    return (
      <VRow
        class={this.classes}
        style={this.styles}
        alignContent={'space-between'}
        justify={'space-between'}
      >
        <VRow class={'banner-col banner__text'} direction={'column'}>
          <VText class={'banner__title'}>{this.$slots.default?.()}</VText>
          <VRow class={'banner__subtitle'}>{this.$slots.subtitle?.()}</VRow>
        </VRow>

        <VRow class={'banner-col banner__right'}>
          <VRow class={'banner__icon'}>
            {this.$slots.icon?.().length
              ? this.$slots.icon?.()
              : <VIcon imageName={this.imageName} />}
          </VRow>

          <VRow class={'banner__close'}>
            <VIcon
              imageName={this.closeImageName}
              onClick={this.onClose}
            />
          </VRow>
        </VRow>
      </VRow>
    )
  },
})
