/* Функции */
import {
  defineComponent, HTMLAttributes, PropType, VNode,
} from 'vue'

/* Типы */
import {
  AlignContent,
  AlignItems,
  Direction,
  JustifyContent,
  Wrap,
} from '@/components/VRow/Types'

/* Стили */
import '@/components/VRow/VRow.scss'

export default defineComponent({
  name: 'VRow',

  props: {
    /** Тег */
    tag: {
      type: String,
      default: 'div',
    },
    /** Распределение пространства между и вокруг элементами контента вдоль поперечной оси */
    alignContent: {
      type: String as PropType<AlignContent>,
      default: 'start',
    },
    /** Распределение пространства между элементами контента */
    justify: {
      type: String as PropType<JustifyContent>,
      default: 'start',
    },
    /** Выравнивает элементы как justify, но перпендикулярно */
    alignItems: {
      type: String as PropType<AlignItems>,
      default: 'start',
    },
    /** Направление */
    direction: {
      type: String as PropType<Direction>,
      default: 'row',
    },
    /** Правило вывода */
    wrap: {
      type: String as PropType<Wrap>,
      default: 'nowrap',
    },
    /** Стили */
    style: {
      type: Object as PropType<Record<string, string | number>>,
      default: () => ({}),
    },
    /** Клик */
    onClick: {
      type: Function as PropType<HTMLAttributes['onClick']>,
    },
  },

  computed: {
    classes() {
      return {
        row: true,
        column: this.direction === 'column',
        wrap: this.wrap === 'wrap',
      }
    },
  },

  render(): VNode {
    const nameComponent = this.tag

    return (
      <nameComponent
        onClick={this.onClick}
        class={this.classes}
        style={{
          'justify-content': this.justify,
          'align-content': this.alignContent,
          'align-items': this.alignItems,
          'flex-direction': this.direction,
          'flex-wrap': this.wrap,
          ...this.style,
        }}>
        {
          this.$slots.default?.()
        }
      </nameComponent>
    )
  },
})
