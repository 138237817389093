import { createApp } from 'vue'
import { createPinia } from 'pinia'
import Main from '@/main/Main'
import '@/registerServiceWorker'
import router from '@/router'
import i18n from '@/locales/index'
import VueSvgInlinePlugin from 'vue-svg-inline-plugin'
import 'vue-svg-inline-plugin/src/polyfills'
import Notifications from '@kyvg/vue3-notification'
import dayjs from 'dayjs'
import ru from 'dayjs/locale/ru'

import weekday from 'dayjs/plugin/weekday'

declare global {
  interface GlobalEventHandlersEventMap {
      'swUpdated': CustomEvent<ServiceWorkerRegistration>;
  }
  interface Navigator {
    standalone: boolean;
  }
}

const pinia = createPinia()
const MainApp = createApp(Main)

dayjs.locale(ru)
dayjs.extend(weekday)
MainApp.use(i18n)
MainApp.use(router)
MainApp.use(pinia)
MainApp.use(VueSvgInlinePlugin, {
  attributes: {
    data: ['src'],
    remove: ['alt'],
  },
})
MainApp.use(Notifications)
MainApp.mount('#app')
