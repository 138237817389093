/* Функции */
import { defineComponent, PropType, VNode } from 'vue'

/* Компоненты */
import VModal from '@/components/VModal/VModal'
import VButton from '@/components/VButton/VButton'
import VRow from '@/components/VRow/VRow'
import VTextInput from '@/components/VInput/VTextInput/VTextInput'
import VTextSmall from '@/components/VText/VTextSmall/VTextSmall'

/* Стили */
import '@/components/TheAuth/TheAuth.scss'

/* Классы */
import { RequestAPI } from '@/classes/Request'
import { CheckTelegram } from '@/classes/CheckTelegram'

/* Хранилище */
import { mapStores } from 'pinia'
import { UserStore } from '@/store/user'

/* Типы */
import { AuthMode, Data } from '@/components/TheAuth/Types'
import { RoutePaths } from '@/router/Types'

export default defineComponent({
  name: 'TheAuth',

  emits: {
    onClose(value: boolean) {
      return typeof value === 'boolean'
    },
  },

  data(): Data {
    return {
      /** Код авторизации */
      code: '',
    }
  },

  props: {
    /** Признак вторичного отображения */
    isSecondaryModal: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    /** Признак показывать ли  */
    isShow: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    /** Маршрут после авторизации */
    afterAuthRoutePath: {
      type: String as PropType<RoutePaths>,
      default: RoutePaths.main,
    },
    /** Показывать ли окно телеграмма */
    isShowTelegramPage: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    /** Ярлык к полю ввода */
    label: {
      type: String as PropType<string>,
      default: '',
    },
    /** Подпись к полю ввода */
    subtext: {
      type: String as PropType<string>,
      default: '',
    },
    /** Тип входа */
    authMode: {
      type: String as PropType<AuthMode>,
      default: AuthMode.phone,
    },
    /** Данные по email */
    email: {
      type: String as PropType<string>,
      default: '',
    },
    /** Данные по телефону */
    phone: {
      type: String as PropType<string>,
      default: '',
    },
  },

  computed: {
    ...mapStores(UserStore),

    /** Признак блокировки кнопки */
    isDisabledButton(): boolean {
      return !this.code.length
    },
  },

  methods: {
    /**
     * Метод отправки данных
     * @param isForceSms - Принудительно отправить по смс
     */
    async send() {
      if (this.isDisabledButton) {
        return
      }

      try {
        const secret = this.code.trim()
        const requestData: { secret: string, force_sms?: boolean, email?: string, phone?: string } = {
          secret,
        }

        switch (this.authMode) {
          case AuthMode.email:
            requestData.email = this.email
            break

          case AuthMode.phone:
            requestData.phone = this.phone
            break
        }

        const response = await RequestAPI.errorHandler(RequestAPI.post, '/athletes/auth', requestData)

        if (response?.data?.response.status === 'ok') {
          const { data } = response

          this.code = ''
          this.userStore.login(data.content.token)

          if (this.isShowTelegramPage) {
            CheckTelegram.checkToRoute(this.afterAuthRoutePath)
          } else {
            this.$router.push({ path: this.afterAuthRoutePath })
          }
        }
      } catch (error) {
        console.error(error)
      }
    },

    /** Вход (повторный запрос) */
    async login() {
      try {
        const requestData: { email?: string, phone?: string, force_sms: true } = {
          force_sms: true,
        }

        if (this.authMode === AuthMode.email) {
          requestData.email = this.email
        }

        if (this.authMode === AuthMode.phone) {
          requestData.phone = this.phone
        }

        const response = await RequestAPI.errorHandler(RequestAPI.post, '/athletes/sign_in', {
          ...requestData,
        })

        if (response?.data?.response.status === 'ok') {
          this.code = ''
        }
      } catch (error) {
        console.error(error)
      }
    },
  },

  render(): VNode {
    const label = () => {
      if (this.label) {
        return this.label
      }

      switch (this.authMode) {
        case AuthMode.phone:
          return this.$t('login.successForm.fields.codePhone.label')
        case AuthMode.email:
          return this.$t('login.successForm.fields.codeEmail.label')
      }
    }

    const subtext = () => {
      if (this.subtext) {
        return this.subtext
      }

      switch (this.authMode) {
        case AuthMode.phone:
          return this.$t('login.successForm.fields.codePhone.subtext')
        case AuthMode.email:
          return this.$t('login.successForm.fields.codeEmail.subtext')
      }
    }
    return (
      <div>
        {
          this.isShow ? <VModal
            isSecondary={this.isSecondaryModal}
            isShow={this.isShow}
            onOnClose={(value) => { this.$emit('onClose', value) }}
          >
            <VRow>
              <VTextInput
                autofocus={true}
                subtextAlign={'left'}
                label={label()}
                onUpdate:modelValue={(value) => { this.code = value }}
                modelValue={this.code}
                subtext={subtext()}
                onEnter={() => this.send()}
                onPaste={() => this.send()}
              />
            </VRow>

            <VButton
              isDisabled={this.isDisabledButton}
              onClick={() => this.send()}
            >
              {this.$t('login.successForm.buttons.send')}
            </VButton>

            {
              this.authMode === AuthMode.phone
                ? <VRow justify={'center'}>
                  <VTextSmall
                    onClick={() => this.login()}
                    cursor={'pointer'}
                  >
                    {this.$t('login.successForm.buttons.sms')}
                  </VTextSmall>
                </VRow>
                : null
            }
          </VModal> : null
        }
      </div>
    )
  },
})
