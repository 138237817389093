/* Функции */
import { defineComponent, VNode } from 'vue'

/* Компоненты */
import VPage from '@/components/VPage/VPage'
import BaseTargetHeader from '@/components/BaseTargetHeader/BaseTargetHeader'
import VContentItem from '@/components/VContent/VContentItem/VContentItem'
import VMoreText from '@/components/VMoreText/VMoreText'
import VSlideUpSlideDown from '@/components/VSlideUpSlideDown/VSlideUpSlideDown'
import BaseTargetCycle from '@/components/BaseTargetCycle/BaseTargetCycle'
import VButtonFixes from '@/components/VButton/VButtonIcon/VButtonFixes/VButtonFixes'

/* Стили */
import '@/views/Goals/GoalsDetail/GoalsDetail.scss'

/* Классы */
import { RequestAPI } from '@/classes/Request'

/* Типы */
import { ResponseGoals } from '@/types/API'
import { Data } from '@/views/Goals/GoalsDetail/Types'
import { RoutePaths } from '@/router/Types'

/* Хранилище */
import { LayoutStore } from '@/store/layout'

export default defineComponent({
  name: 'MyGoalsDetailView',

  data(): Data {
    return {
      target: null,
      category: null,
      isLoadData: false,
    }
  },

  methods: {
    /** Получение данных по плану */
    async getPlans() {
      const response = await RequestAPI.errorHandler<ResponseGoals>(RequestAPI.get, '/goals')

      if (response) {
        return response.data.content
      }

      return null
    },

    /** Установка данных */
    async setData() {
      const data = await this.getPlans()

      if (data) {
        const { goals, goal_categories } = data
        const currentGoal = goals.find(({ id }) => String(id) === String(this.$route.params.id))
        const currentCategory = goal_categories?.find(({ id }) => id === currentGoal?.goal_category_id)

        this.target = currentGoal ?? null
        this.category = currentCategory ?? null
      }
    },

    async setGoal() {
      if (this.target) {
        const data = await RequestAPI.errorHandler<ResponseGoals>(RequestAPI.post, `/goals/${this.target.id}`)

        if (data && data.data?.response?.status === 'ok') {
          this.$router.push(RoutePaths.main)
        }
      }
    },
  },

  async created() {
    LayoutStore().setLoading(true)
    await this.setData()
    LayoutStore().setLoading(false)
    this.isLoadData = true
  },

  render(): VNode | null {
    const buttons = () => {
      if (this.target?.subscription_plans.length) {
        return this.target.subscription_plans.map((label) => (
          <VButtonFixes
            positionIcon={'left'}
            imageName='pro'
            isFivefold
            isFullWidth
            onClick={() => {
              this.$router.push({
                path: RoutePaths.plans.replace(':id', String(label.id)),
                query: {
                  goal_id: String(this.$route.params.id),
                },
              })
            }}
          >{this.$t('pages.GoalsDetailView.buttonPlan', { label: label.label })}</VButtonFixes>))
      }

      return <VButtonFixes onClick={this.setGoal}>{this.$t('pages.GoalsDetailView.button')}</VButtonFixes>
    }
    const cycles = this.target?.cycles.map((cycle, index) => (
      <BaseTargetCycle
        title={cycle.title ?? ''}
        startDate={cycle.start_date}
        endDate={cycle.end_date}
        description={cycle.description ?? ''}
        workoutsCount={cycle.workouts_count ?? 0}
        id={`${this.target?.id}-cycle-${index}`}
      />
    ))

    const page = this.target ? <VPage class={'plan-detail'} key={'GoalsDetailView'}>
      <BaseTargetHeader
        src={this.target?.images.show ?? ''}
        failLoadSrc={''}
        title={this.target?.title ?? ''}
        subTitle={this.category?.title ?? ''}
        cycle={this.target?.cycles.length ?? 0}
        duration={this.target?.duration ?? 0}
        key={`target-detail-${this.target?.id}`}
        labels={this.target.subscription_plans.map((label) => label.label)}
      />

      <VContentItem isPaddingX={true} margin={'20px 0'}>
        <VMoreText opacity={1}>{this.target?.description ?? ''}</VMoreText>

        <VSlideUpSlideDown
          title={this.$t('pages.GoalsDetailView.result')}
          isShow={true}
          id={'result-target'}
        >
          {cycles}
        </VSlideUpSlideDown>
      </VContentItem>

      {buttons()}
    </VPage> : null

    return (
      this.isLoadData
        ? page
        : null
    )
  },
})
