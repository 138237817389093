export type ResponseError = {
  code: number,
  message: string
}

export type Response<T = any> = {
  content: T,
  response: {
    status: string
    errors?: ResponseError[]
  }
}

export type GoalCategory = {
  id: number,
  title: string | null,
  short_title: string | null,
  description: string | null,
}

export type EquipmentItem = {
  id: number,
  title: string | null,
}

export type Images = {
  index: string | null,
  show: string | null,
}

export type Cycle = {
  title: string | null,
  description: string | null,
  start_date: string,
  end_date: string,
  workouts_count: number | null,
}

export type SubscriptionPlanLabel = {
  id: number,
  label: string
}

export type Goal = {
  id: number,
  title: string | null,
  description: string | null,
  goal_category_id: number | string,
  duration: number | null,
  equipment_item_ids: EquipmentItem['id'][]
  images: Images,
  cycles: Cycle[],
  subscription_plans: SubscriptionPlanLabel[]
}

export type ResponseGoals = Response<{
  goal_categories: GoalCategory[],
  equipment_items: EquipmentItem[],
  goals: Goal[]
}>

export enum WorkoutStates {
  completed = 'completed',
  started = 'started',
  pending = 'pending'
}

export type WorkoutStatesT = `${WorkoutStates}`

export type Workout = {
  id: string,
  title: string | null,
  category_id: string | null,
  goal_id: string | null,
  tags: string[],
  equipment_item_ids: EquipmentItem['id'][],
  state: WorkoutStatesT,
  image: string | null,
  performed_at: string
}

export type ResponseMain = Response<{
  categories: Omit<GoalCategory, 'description'>[]
  goals: Omit<Goal, 'description' | 'goal_category_id' | 'duration' | 'equipment_item_ids' | 'images' | 'cycles'>[]
  equipment_items: EquipmentItem[],
  workouts: Workout[]
}>

export type WorkoutStep = {
  audio_url: string | null,
  caption: string | null,
  duration: number,
  exercise_id: number,
  flip: boolean,
  metronome: number,
  play_audio: boolean
  tempo: null,
  title: string | null,
  type: 'exercise' | 'prep',
  video_url: string | null,
  mp4_url: string | null,
  webm_url: string | null,
  thumb_image_url: string | null,
}

export type WorkoutBlock = {
  duration_max: number,
  duration_min: number,
  releats_count: number,
  steps: WorkoutStep[],
  title: string | null,
}

export type WorkoutDetail = Response<{
  blocks: WorkoutBlock[],
  description: string | null,
  duration: number,
  equipment_items: EquipmentItem[],
  id: string,
  image: string | null,
  tags: string[],
  title: string | null,
  state: string
}>

export type Level = {
  id: number | number | number
  title: string
}

export type WorkoutCompany = {
  caption: string | null,
  iid: number,
  title: string | null,
}

export type ProfileAthlete = {
  id: number,
  telegram_token: string | null
  age: number,
  email: string,
  phone: string,
  first_name: string | null
  last_name: string | null
  growth: number,
  level: Level,
  sex: string,
  telegram_id: string | null,
  weight: number,
  workout_company: WorkoutCompany,
  sports: string[]
  equipment_items: EquipmentItem[]
}

export type Profile = Response<{
  athlete: ProfileAthlete,
  equipment_items: EquipmentItem[],
  levels: Level[],
}>

export type ResponseMyGoals = Response<{
  equipment_items: EquipmentItem[],
  goals: Goal[]
  goal_categories: GoalCategory[]
}>

export type ResponseMyGoalDetail = Response<{
  equipment_items: EquipmentItem[],
  goal: Goal,
  goal_categories: GoalCategory[]
}>

export type PaymentOptions = {
  id: number,
  title: string | null,
  description: string | null,
  price: number,
  duration_months: number,
  trial_duration_days: number
}

export type Plan = {
  id: number,
  title: string | null
  label: string | null
  trial_available: boolean
  description: string | null
  payment_options: PaymentOptions[]
}

export type PlanResponse = Response<{
  plans: Plan[]
}>

export type SubscriptionPlan = {
  id: number,
  title: string
}

export type SubscriptionPlanOption = {
  id: number,
  title: string
  description: string
}

export type Subscription = {
  id: number,
  starts_at: string,
  ends_at: string,
  price: number,
  autorenew: boolean,
  state: 'current' | 'pending',
  trial: boolean,
  plan: SubscriptionPlan,
  subscription_plan_option: SubscriptionPlanOption
}

export type SubscriptionResponse = Response<{
  subscriptions: Subscription[]
}>

export type PaymentCardInfo = {
  first6: string,
  last4: string,
  expiry_month: string,
  expiry_year: string,
  card_type: string,
  issuer_name?: string
}

export type PaymentMethod = {
  id: number,
  state: string,
  created_at: string,
  type: string,
  desciption: string,
  default: boolean,
  card_info: PaymentCardInfo | null,
  operator: string
}

export type PaymentMethodsResponse = Response<{
  payment_methods: PaymentMethod[]
}>

export type AddPayment = {
  payment_link: string,
  payment_method: PaymentMethod
}

export type AddPaymentResponse = Response<AddPayment>

export type AddDefaultMethodsResponse = Response<{
  payment_method: PaymentMethod
}>
