/* Функции */
import {
  defineComponent, HTMLAttributes, PropType, VNode,
} from 'vue'

/* Компоненты */
import VRow from '@/components/VRow/VRow'
import VButton from '@/components/VButton/VButton'
import VTextParagraph from '@/components/VText/VTextParagraph/VTextParagraph'
import VPoint from '@/components/VPoint/VPoint'
import VTextHeading1 from '@/components/VText/VTextHeading1/VTextHeading1'

/* Типы */
import { StatePoint } from '@/components/VPoint/Types'

/* Стили */
import '@/components/BaseSubscription/BaseSubscription.scss'

/* Классы */
import dayjs from 'dayjs'

export default defineComponent({
  name: 'BaseSubscription',

  props: {
    /** Заголовок */
    title: {
      type: String as PropType<string>,
      default: '',
    },

    /** Подпись */
    description: {
      type: String as PropType<string>,
      default: '',
    },

    /** Текст активности кнопки продлить */
    textRenewButton: {
      type: String as PropType<string>,
      default: '',
    },

    /** Текст верхней кнопки */
    textTopButton: {
      type: String as PropType<string>,
      default: '',
    },

    /** Текст активности  */
    textActiveTo: {
      type: String as PropType<string>,
      default: '',
    },

    /** Показывать кнопку продлить */
    isShowRenewButton: {
      type: Boolean as PropType<boolean>,
      default: true,
    },

    /** Показывать кнопку сверху */
    isTopButton: {
      type: Boolean as PropType<boolean>,
      default: true,
    },

    /** Показывать дату */
    isShowDateEnd: {
      type: Boolean as PropType<boolean>,
      default: true,
    },

    /** Клик на кнопку сверху */
    onClickTop: {
      type: Function as PropType<HTMLAttributes['onClick']>,
    },

    /** Клик на кнопку продлить */
    onClickRenew: {
      type: Function as PropType<HTMLAttributes['onClick']>,
    },

    /** Действует до */
    dateTo: {
      type: String as PropType<string>,
      default: '',
    },
  },

  computed: {
    state() {
      const date = dayjs(this.dateTo)
      const currentDate = dayjs()
      const diffDays = date.diff(currentDate, 'days')

      if (Math.sign(diffDays) === -1) {
        return StatePoint.danger
      }

      if (diffDays <= 7) {
        return StatePoint.warning
      }

      return StatePoint.success
    },
  },

  render(): VNode {
    return (
      <div class={'subscription'}>
        <VRow direction={'column'}>
          <VRow justify={'space-between'} alignItems={'center'}>
            <VTextHeading1>{this.title}</VTextHeading1>

            {
              this.isTopButton ? <VButton
                tag={'span'}
                isTertiary
                isFullWidth={false}
                onClick={this.onClickTop}
              >
                {this.textTopButton}
              </VButton> : null
            }
          </VRow>

          {
            this.isShowDateEnd ? <VRow class={'subscription-date'} alignItems={'center'}>
              <VPoint state={this.state}/>

              <VTextParagraph>{this.textActiveTo}</VTextParagraph>
            </VRow> : null
          }
        </VRow>

        {
          this.isShowRenewButton ? <VRow class={'subscription-bottom'}>
            <VButton isTertiary onClick={this.onClickRenew}>{this.textRenewButton}</VButton>
          </VRow> : null
        }

        {
          this.description
            ? <VRow class={'subscription-description'}>{this.description}</VRow> : null
        }
      </div>
    )
  },
})
