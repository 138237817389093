/* Функции */
import {
  defineComponent, HTMLAttributes, PropType, VNode,
} from 'vue'

/* Компоненты */
import VRow from '@/components/VRow/VRow'
import VTextParagraph from '@/components/VText/VTextParagraph/VTextParagraph'
import VTextSubTitle from '@/components/VText/VTextSubTitle/VTextSubTitle'
import VIcon from '@/components/VIcon/VIcon'

/* Стили */
import '@/components/TheWorkoutCalendar/TheWorkoutCalendarItem/TheWorkoutCalendarItem.scss'

export default defineComponent({
  name: 'TheWorkoutCalendarItem',

  props: {
    /** Признак блокировки */
    isDisabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    /** Клик на карточку */
    onClick: {
      type: Function as PropType<HTMLAttributes['onClick']>,
    },

    /** День недели */
    day: {
      type: String as PropType<string>,
      default: '',
    },

    date: {
      type: String as PropType<string>,
      default: '',
    },

    /** Количество */
    count: {
      type: Number as PropType<number>,
      default: 0,
    },

    /** Количество пройденных тренировок */
    countCompleted: {
      type: Number as PropType<number>,
      default: 0,
    },

    /** Признак прошли ли тренировку */
    isDone: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    /** Признак в прошлом */
    isPast: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    /** Признак выбранного */
    isCurrent: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },

  computed: {
    classes() {
      return {
        'workout-calendar-item': true,
        'is-done': this.isDone,
        'is-disabled': this.isDisabled,
        'is-past': this.isPast,
        'is-current': this.isCurrent,
      }
    },
  },

  render(): VNode {
    const count = this.count ? this.count : ''
    const showCount = this.countCompleted ? `${this.countCompleted} / ${count}` : count

    return (
      <VRow
        class={this.classes}
        direction={'column'}
        justify={'start'}
        alignContent={'flex-start'}
        onClick={this.isDisabled ? undefined : this.onClick}
      >
        <VTextParagraph opacity={null} fontWeight={600} class={'workout-calendar-item__day'}>{this.day}</VTextParagraph>

        {
          this.isDone
            ? <VIcon class={'workout-calendar-item__icon-done'} imageName={'done_small'} />
            : <VTextSubTitle opacity={null} class={'workout-calendar-item__count'}>
              {showCount}
            </VTextSubTitle>
        }
      </VRow>
    )
  },
})
