import { RoutePaths } from '@/router/Types'

export const MenuItems = Object.freeze([
  {
    title: 'Планы',
    text: 'Планы',
    path: RoutePaths.myGoals,
    imageName: 'plan',
    isIncludeActive: true,
  },

  {
    title: 'Тренировки',
    text: 'Тренировки',
    path: RoutePaths.main,
    imageName: 'workout',
    isIncludeActive: true,
  },

  {
    title: 'Избранное',
    text: 'Избранное',
    path: RoutePaths.like,
    imageName: 'like',
    isIncludeActive: true,
  },

  {
    title: 'Профиль',
    text: 'Профиль',
    path: RoutePaths.profile,
    imageName: 'profile',
    isIncludeActive: true,
  },
])
