/* Функции */
import { defineComponent, VNode } from 'vue'

/* Компоненты */
import VSteps from '@/components/VSteps/VSteps'
import VRow from '@/components/VRow/VRow'
import VIcon from '@/components/VIcon/VIcon'
import VStepsNav from '@/components/VSteps/VStepsNav/VStepsNav'
import VFullScreen from '@/components/VFullScreen/VFullScreen'
import VContent from '@/components/VContent/VContent'
import VTextHeading1 from '@/components/VText/VTextHeading1/VTextHeading1'
import VTextParagraph from '@/components/VText/VTextParagraph/VTextParagraph'
import VButtonIcon from '@/components/VButton/VButtonIcon/VButtonIcon'
import VInputArea from '@/components/VInput/VInputArea/VInputArea'
import VButton from '@/components/VButton/VButton'
import VInputButton from '@/components/VInput/VInputButton/VInputButton'
import VTextInput from '@/components/VInput/VTextInput/VTextInput'
import VContentItem from '@/components/VContent/VContentItem/VContentItem'
import VTextHeading2 from '@/components/VText/VTextHeading2/VTextHeading2'
import VTopBackground from '@/components/VTopBackground/VTopBackground'
import VTransition from '@/components/VTransition/VTransition'
import TheAuth from '@/components/TheAuth/TheAuth'
import VTextSmall from '@/components/VText/VTextSmall/VTextSmall'
import BaseAuthModeToggle from '@/components/BaseAuthModeToggle/BaseAuthModeToggle'

/* Стили */
import '@/views/Quiz/Quiz.scss'

/* Правила валидации */
import { ruleIntegerPositive, validEmail, validPhone } from '@/components/Validation/Rules'

/* Типы */
import { InputType } from '@/components/VInput/VInputButton/Types'
import { RequestAPI } from '@/classes/Request'
import { RoutePaths } from '@/router/Types'
import { IconsByLevelSport, MaskPhone } from '@/types/constants/common'
import {
  Data, DataResponse, HeaderData,
  SendData,
} from '@/views/Quiz/Types'
import { AuthMode } from '@/components/TheAuth/Types'

/* Хранилище */
import { UserStore } from '@/store/user'

export default defineComponent({
  name: 'QuizView',

  data(): Data {
    return {
      authMode: AuthMode.phone,
      currentStep: 0,
      disabledButton: false,
      disabledFields: {
        enterMode: false,
        loseWeigh: false,
        uploadProblem: false,
        keepFit: false,
        improvePerformance: false,
        other: false,
      },
      dataForQuestionnaire: {
        equipment_items: [],
        goals: [],
        levels: [],
        sports: [],
        workout_company: [],
      },
      formData: {},
      isShowCodeModal: false,
    }
  },

  computed: {
    /** Признак является ли регистрацией */
    isRegistration(): boolean {
      return this.currentStep === 5
    },
    /** Заголовок */
    headerTitle(): string {
      return HeaderData[this.currentStep]?.title ?? ''
    },
    /** Описание */
    headerDescriptions(): string {
      return HeaderData[this.currentStep]?.description ?? ''
    },
  },

  methods: {
    /** Переход к следующему шагу */
    onClickNextStep() {
      if (this.isRegistration) {
        this.createAthletes()
        return
      }

      this.currentStep += 1

      this.validationLogic()
    },

    /** Клик назад */
    onClickBack() {
      switch (this.currentStep) {
        case 0:
          this.$router.push(`${RoutePaths.index}?step=2`)
          break

        default:
          this.currentStep -= 1
      }

      this.validationLogic()
    },

    /** Логика валидации. Устанавливает блокировку кнопки перехода к следующему шагу */
    validationLogic() {
      switch (this.currentStep) {
        case 0:
          this.disabledButton = !(
            this.formData.sex
            && Number(this.formData.age) > 0
            && Number(this.formData.age) <= 100
          )
          break

        case 1:
          this.disabledButton = !(
            Number(this.formData.weight) > 0
            && Number(this.formData.growth) > 0
          )
          break

        case 2:
          this.disabledButton = !(typeof (this.formData.level) === 'number')
          break

        case 3:
          this.disabledButton = !(Array.isArray(this.formData.sports)
            && Boolean(this.formData.sports.length))
          break

        case 4:
          this.disabledButton = !(Array.isArray(this.formData.goals)
            && Boolean(this.formData.goals.length))
            || !(typeof (this.formData.workout_company) === 'number')
          break

        case 5:
          if (this.authMode === AuthMode.email) {
            this.disabledButton = !(this.formData.name && this.formData.email && (typeof this.formData.email === 'string' && Boolean(validEmail()(this.formData.email))))
          } else if (this.authMode === AuthMode.phone) {
            this.disabledButton = !(this.formData.name && this.formData.phone && (typeof this.formData.phone === 'string' && Boolean(validPhone()(this.formData.phone))))
          }
          break
      }
    },

    /** Метод получения данных для анкеты */
    async getDataForQuiz(): Promise<DataResponse['content']['form'] | undefined> {
      let result: DataResponse['content']['form'] = {
        equipment_items: [],
        goals: [],
        levels: [],
        sports: [],
        workout_company: [],
      }

      try {
        const response = await RequestAPI.errorHandler<DataResponse>(RequestAPI.get, '/athletes/form')

        if (response?.data?.response.status === 'ok') {
          result = response.data.content.form
        }
      } catch (error) {
        console.error(error)
      }

      return result
    },

    /**
     * Устанавливает значения в динамические поля
     * @param name - Ключ
     * @param item - Значение
     * @param isAutoDelete - Автоматическое удаление значения при совпадении данных
     */
    setValue(
      name: string,
      item: { value: string | null | number, status: boolean } | string | null | number,
      isAutoDelete = true,
    ) {
      let element = this.formData[name]

      if (typeof item !== 'object') {
        if (element === item && !Array.isArray(element)) {
          if (isAutoDelete) {
            this.formData[name] = null
          } else {
            this.formData[name] = item
          }
        } else if (element !== item && !Array.isArray(element)) {
          this.formData[name] = item
        }

        return
      }

      if (!Array.isArray(element)) {
        this.formData[name] = []
        element = this.formData[name]
      }

      if (Array.isArray(element)) {
        if (item && typeof item === 'object' && item.status && typeof item.value === 'number') {
          element.push(item.value)
        } else if (item && typeof item === 'object' && typeof item.value === 'number') {
          this.formData[name] = element.filter((itemIn) => itemIn !== item.value)
        }
      }
    },

    /**
     * Проверяет является ли выбранное значение установленным в данных.
     * Необходимо для проверки и показа активности
     * @param name - Ключ
     * @param item - Значение
     * @returns - Логическое значение
     */
    isCurrentValue(
      name: string,
      item: { value: string | null | number, status: boolean } | string | null | number,
    ): boolean {
      const element = this.formData[name]

      if (Array.isArray(element)) {
        if (typeof item === 'number') {
          return typeof element.find((el) => el === item) === 'number'
        }
      }

      if (typeof item !== 'object') {
        return item === element
      }

      return false
    },

    /** Метод создании атлета (регистрация) */
    async createAthletes() {
      const userState = UserStore()

      const preparationData: SendData = {
        referrer: userState.referrer ?? '',
        sex: this.formData?.sex ? String(this.formData.sex) : '',
        age: Number(this.formData.age),
        growth: Number(this.formData.growth),
        weight: Number(this.formData.weight),
        level: Number(this.formData.level),
        workout_company: Number(this.formData.workout_company),
        equipment_item_ids: Array.isArray(this.formData?.equipment_items) ? this.formData?.equipment_items : [],
        sports: Array.isArray(this.formData?.sports) ? this.formData?.sports : [],
        goals: Array.isArray(this.formData?.goals) ? this.formData?.goals : [],
        name: this.formData?.name ? String(this.formData.name) : '',
      }

      if (this.authMode === AuthMode.email) {
        preparationData.email = this.formData?.email ? String(this.formData.email) : ''
      } else if (this.authMode === AuthMode.phone) {
        preparationData.phone = this.formData?.phone ? String(this.formData.phone) : ''
      }

      try {
        const response = await RequestAPI.errorHandler<DataResponse>(RequestAPI.post, '/athletes', preparationData)

        if (response?.data?.response.status === 'ok') {
          this.isShowCodeModal = true
        }
      } catch (error) {
        console.error(error)
      }
    },
  },

  watch: {
    formStep: {
      handler() {
        this.validationLogic()
      },
      deep: true,
    },
    formData: {
      handler() {
        this.validationLogic()
      },
      deep: true,
    },
  },

  async created() {
    this.validationLogic()
    const dataQuiz = await this.getDataForQuiz()

    this.dataForQuestionnaire.equipment_items = dataQuiz?.equipment_items ?? []
    this.dataForQuestionnaire.sports = dataQuiz?.sports ?? []
    this.dataForQuestionnaire.goals = dataQuiz?.goals ?? []
    this.dataForQuestionnaire.levels = dataQuiz?.levels ?? []
    this.dataForQuestionnaire.workout_company = dataQuiz?.workout_company ?? []
  },

  render(): VNode {
    const headerQuiz = () => (
      <div class={'header-quiz'}>
        <VRow justify='space-between'>
          <VIcon imageName={'arrow_left'} onClick={this.onClickBack} />

          <VStepsNav modelValue={this.currentStep} maxStep={6} />
        </VRow>

        {!this.isRegistration && <VTransition>
          <VRow class={'header-quiz__text'} direction={'column'} key={`header-step-${this.currentStep}`}>
            <VTextHeading1>{this.headerTitle}</VTextHeading1>

            {
              this.headerDescriptions
              && <VTextParagraph>{this.headerDescriptions}</VTextParagraph>
            }
          </VRow>
        </VTransition>
        }
      </div >
    )

    const levels = () => this.dataForQuestionnaire.levels.map((level, index) => (<VInputButton
      key={`level-${index}`}
      name='level'
      type={InputType.radio}
      value={level.id}
      onUpdate:checked={(value) => { this.setValue('level', value) }}
      modelValue={!Array.isArray(this.formData.level) ? this.formData.level : null}
      imageName={IconsByLevelSport[level.id]}
    >
      {level.title}
    </VInputButton>))

    const equipmentItems = () => this.dataForQuestionnaire.equipment_items
      .map(({ id, title }, index) => (
        <VInputButton
          key={`equipment-items-${index}`}
          name=''
          type={InputType.checkbox}
          value={id}
          isCheckboxValue
          onUpdate:checkboxValue={
            (value) => {
              this.setValue('equipment_items', value)
            }
          }
          modelValue={this.isCurrentValue('equipment_items', id)}
          isShowImage={false}
          isInline={true}
        >
          {title}
        </VInputButton>
      ))

    const sports = () => this.dataForQuestionnaire.sports.map(({ id, title, caption }, index) => (
      <VInputButton
        key={`sport-${index}`}
        name='sport'
        value={id}
        type={InputType.checkbox}
        isCheckboxValue
        onUpdate:checkboxValue={
          (value) => {
            this.setValue('sports', value)
          }
        }
        modelValue={this.isCurrentValue('sports', id)}
        description={caption ?? ''}
        isShowImage={false}
      >
        {title}
      </VInputButton>
    ))

    const goals = () => this.dataForQuestionnaire.goals.map(({ id, title }, index) => {
      const { goals } = this.formData
      let isDisabled = false

      if (Array.isArray(goals) && goals.length === 2) {
        isDisabled = typeof goals.find((currentId) => currentId === id) !== 'number'
      }

      return <VInputButton
        key={`goals-${index}`}
        name='goals'
        type={InputType.checkbox}
        onUpdate:checkboxValue={(value) => {
          this.setValue('goals', value)
        }}
        disabled={isDisabled}
        value={id}
        isCheckboxValue
        modelValue={this.isCurrentValue('goals', id)}
        imageName={'circle'}
        checkedImageName={'success'}
        isIncludeSvgInHtml={false}
      >
        {title}
      </VInputButton>
    })

    const workoutCompany = () => this.dataForQuestionnaire.workout_company.map(
      ({ id, title }, index) => <VInputButton
        key={`workoutCompany-${index}`}
        name='workoutCompany'
        type={InputType.radio}
        onUpdate:checked={(value) => {
          this.setValue('workout_company', value)
        }}
        value={id}
        modelValue={
          !Array.isArray(this.formData.workout_company) ? this.formData.workout_company : null
        }
        isShowImage={false}
        isInline={true}
      >
        {title}
      </VInputButton>,
    )

    const validationNumber = (name: string, { value, event }: { value: string, event: Event }) => {
      const target = event.target as HTMLInputElement
      let resultValue = value

      if (target) {
        const replaceValue = value.replace(/[e+-]/gi, '')
        resultValue = String(Number(replaceValue))
        target.value = resultValue
      }

      this.setValue(name, resultValue)
    }

    const steps = () => {
      switch (this.currentStep) {
        case 0:
          return (
            <div class={'step'} key={'step-0'}>
              <VRow key={'row-0-1'} justify='space-between' class={'floor-select'}>
                <VButtonIcon
                  name={'floor'}
                  value={'Мужчина'}
                  imageName='men'
                  modelValue={typeof this.formData.sex === 'string' ? this.formData.sex : null}
                  onUpdate:checked={(value) => { this.setValue('sex', value) }}
                >
                  {'Мужской'}
                </VButtonIcon>

                <VButtonIcon
                  name={'floor'}
                  value={'Женщина'}
                  imageName='woman'
                  modelValue={typeof this.formData.sex === 'string' ? this.formData.sex : null}
                  onUpdate:checked={(value) => { this.setValue('sex', value) }}
                >
                  {'Женский'}
                </VButtonIcon>
              </VRow>

              <VRow key={'row-0-2'} justify='space-between' class={'floor-select'}>
                <VInputArea
                  key={'valueAge'}
                  title={'Возраст'}
                  onUpdate:modelValue={(value) => { validationNumber('age', value) }}
                  modelValue={typeof this.formData.age === 'string' ? this.formData.age : ''}
                  placeholder={'Сколько вам лет?'}
                  type={'number'}
                  inputmode={'numeric'}
                  pattern='\d*'
                />
              </VRow>
            </div>
          )
        case 1:
          return (
            <div class={'step'} key={'step-1'}>
              <VRow key={'row-1-1'}>
                <VInputArea
                  title={'Рост'}
                  key={'growth'}
                  onUpdate:modelValue={(value) => { validationNumber('growth', value) }}
                  rules={[ruleIntegerPositive]}
                  modelValue={typeof this.formData.growth === 'string' ? this.formData.growth : ''}
                  placeholder={'см'}
                  type={'number'}
                  inputmode={'numeric'}
                  pattern='\d*'
                />
              </VRow>

              <VRow>
                <VInputArea
                  title={'Вес'}
                  key={'weight'}
                  onUpdate:modelValue={(value) => { validationNumber('weight', value) }}
                  modelValue={typeof this.formData.weight === 'string' ? this.formData.weight : ''}
                  placeholder={'кг'}
                  type={'number'}
                  inputmode={'numeric'}
                  pattern='\d*'
                />
              </VRow>
            </div>
          )

        case 2:
          return (
            <div class={'step'} key={'step-2'}>
              {levels()}

              <VContentItem key={'content-item-2-1'}>
                <VRow key={'row-2-1'} direction={'column'} >
                  <VTextHeading2>
                    {'Какое оборудование добавить к тренировкам?'}
                  </VTextHeading2>

                  <VTextParagraph>
                    {'Можно выбрать несколько видов.'}
                  </VTextParagraph>
                </VRow>

                <VRow key={'row-2-2'} wrap={'wrap'}>
                  {equipmentItems()}
                </VRow>
              </VContentItem>
            </div>
          )

        case 3:
          return (
            <div class={'step'} key={'step-3'}>
              <VRow key={'row-3-1'} direction={'column'}>
                {sports()}
              </VRow>,
            </div>
          )

        case 4:
          return (
            <div class={'step'} key={'step-4'}>
              <VRow key={'row-4-3'} direction={'column'} >
                {goals()}
              </VRow>

              <VRow key={'row-4-1'} direction={'column'} >
                <VTextHeading2>
                  {'В каком формате Вы тренируетесь или планируете?'}
                </VTextHeading2>
              </VRow>

              {workoutCompany()}
            </div>
          )

        case 5:
          return (
            <div class={'step'} key={'step-5'}>
              <TheAuth
                isSecondaryModal={true}
                afterAuthRoutePath={RoutePaths.goals}
                isShow={this.isShowCodeModal}
                isShowTelegramPage={false}
                onOnClose={(value) => { this.isShowCodeModal = value }}
                authMode={this.authMode}
                email={this.formData?.email ? String(this.formData?.email) : ''}
                phone={this.formData?.phone ? String(this.formData?.phone) : ''}
              />

              <div key={'registration'} class={'registration'}>
                <VTopBackground imageName='registration' zIndex={0} />

                <VRow>
                  <VTextHeading1>{this.headerTitle}</VTextHeading1>
                </VRow>

                <BaseAuthModeToggle
                  phoneText={this.$t('login.toggle.phone')}
                  emailText={this.$t('login.toggle.email')}
                  onUpdate:modelValue={(value) => {
                    this.authMode = value
                    this.validationLogic()
                  }}
                />

                <VRow>
                  <VTextInput
                    label={'Ваше имя'}
                    autofocus={true}
                    onEnter={() => { if (!this.disabledButton) { this.onClickNextStep() } }}
                    onUpdate:modelValue={(value) => { this.setValue('name', value) }}
                    modelValue={typeof this.formData.name === 'string' ? this.formData.name : ''}
                  />
                </VRow>

                <VRow>
                  {
                    this.authMode === AuthMode.phone
                      ? <VTextInput
                        key={'phone'}
                        subtextAlign={'left'}
                        label={this.$t('login.fields.phone.label')}
                        onUpdate:modelValue={(value) => { this.setValue('phone', value, false) }}
                        modelValue={typeof this.formData.phone === 'string' ? this.formData.phone : ''}
                        mask={MaskPhone}
                        onEnter={() => { if (!this.disabledButton) { this.onClickNextStep() } }}
                        subtext={this.$t('login.fields.phone.subtext')}
                      />
                      : <VTextInput
                        key={'email'}
                        subtextAlign={'left'}
                        label={this.$t('login.fields.email.label')}
                        onUpdate:modelValue={(value) => { this.setValue('email', value) }}
                        onEnter={() => { if (!this.disabledButton) { this.onClickNextStep() } }}
                        modelValue={typeof this.formData.email === 'string' ? this.formData.email : ''}
                        subtext={this.$t('login.fields.email.subtext')}
                      />
                  }

                </VRow>
              </div>
            </div>
          )

        default:
          return null
      }
    }

    return (
      <VFullScreen class={'quiz-view'}>
        <VSteps
          modelValue={this.currentStep}
          isGoToStartAfterReboot={true}
          onUpdate:modelValue={((step) => { this.currentStep = step })}
        >
          <VContent>
            {{
              top: () => [
                headerQuiz(),

                <VTransition>
                  {steps()}
                </VTransition>,
              ],

              bottom: () => [
                <VButton
                  isDisabled={this.disabledButton}
                  onClick={this.onClickNextStep}
                  imageName={this.isRegistration ? '' : 'arrow_right'}
                >
                  {this.isRegistration ? 'Зарегистрироваться' : 'Продолжить'}
                </VButton>,

                this.isRegistration ? <VRow justify={'center'}>
                  <VTextSmall
                    onClick={() => {
                      this.$router.push(RoutePaths.login)
                    }}
                    cursor={'pointer'}
                  >
                    {'У меня уже есть учетная запись'}
                  </VTextSmall>
                </VRow> : null,
              ],
            }}
          </VContent>
        </VSteps>
      </VFullScreen>
    )
  },
})
