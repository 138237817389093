import { coercibleToNumber, isEmpty } from '@/helpers/utils'
import { ValidationResult, ValidationRule } from './Validation'

export const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const phonePattern = /^(\+7|7|8)?[\s-]?\(?[489][0-9]{2}\)?[\s-]?[0-9]{3}[\s-]?[0-9]{2}[\s-]?[0-9]{2}$/

/**
 * Возвращает правило, которое проверяет, что значение непустое, с указанным текстом ошибки.
 * @param errorText - текст ошибки
 * @returns правило
 */
export function genRuleRequired(errorText: string): ValidationRule {
  return (value) => !isEmpty(value) || errorText
}

/**
 * Проверка формата строки по регулярному выражению
 * [не указывать флаг g регулярном выражении]
 * @param regexp  - регулярное выражение для проверки строки
 * @param message - сообщение в случае ошибки
 */
export const validateStringFormat = (regexp: RegExp, message: string | boolean = ''): (val: string) => ValidationResult => (val: string): ValidationResult => {
  if (!val) {
    return true
  }

  const isValid = regexp.test(val)

  return !isValid && message ? message : isValid
}

/**
 * Проверяет, что в поле введено число.
 * @param value - значение
 * @returns true, если проверка пройдена, иначе текст ошибки
 */
export const ruleNumber: ValidationRule = (value) => {
  if (isEmpty(value)) {
    return true
  }
  return coercibleToNumber(value) || 'Значение должно быть числом'
}

/**
 * Проверяет, что в поле введено положительное число.
 * @param value - значение
 * @returns true, если проверка пройдена, иначе текст ошибки
 */
export const rulePositiveNumber: ValidationRule = (value) => {
  if (isEmpty(value)) {
    return true
  }

  if (!coercibleToNumber(value)) {
    return 'Значение должно быть числом'
  }

  value = parseFloat(value)

  if (value <= 0) {
    return 'Значение должно быть положительным числом'
  }

  return true
}

/**
 * Проверяет, что в поле введено целое число.
 * @param value - значение
 * @returns true, если проверка пройдена, иначе текст ошибки
 */
export const ruleInteger: ValidationRule = (value) => {
  const result = ruleNumber(value)

  if (result !== true) {
    return result
  }

  value = parseFloat(value)

  if (!Number.isInteger(value)) {
    return 'Значение должно быть целым числом'
  }

  return true
}

/**
 * Проверяет, что в поле введены только цифры.
 * @param value - значение
 * @returns true, если проверка пройдена, иначе текст ошибки
 */
export const ruleIntegerNonnegative: ValidationRule = (value) => {
  if (isEmpty(value)) {
    return true
  }

  value = String(value)

  return /^\d+$/.test(value) || 'Значение должно быть неотрицательным целым числом'
}

/**
 * Проверяет, что в поле введено натуральное число.
 * @param value - значение
 * @returns true, если проверка пройдена, иначе текст ошибки
 */
export const ruleIntegerPositive: ValidationRule = (value) => {
  const result = ruleIntegerNonnegative(value)

  if (result !== true) {
    return result
  }

  value = parseInt(value, 10)

  if (value < 0) {
    return 'Значение должно быть целым положительным числом'
  }

  return true
}

/** Правило валидации электронной почты. */
export const validEmail = ():
  ((value: any) => ValidationResult) => validateStringFormat(emailPattern, false)

/** Правило валидации номера телефона. */
export const validPhone = (): ((value: any) => ValidationResult) => validateStringFormat(phonePattern, false)
