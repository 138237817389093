/* Функции */
import { defineComponent, PropType, VNode } from 'vue'

/* Стили */
import '@/components/VPoint/VPoint.scss'

/* Типы */
import { StatePoint } from './Types'

export default defineComponent({
  name: 'VPoint',

  props: {
    /** Состояние */
    state: {
      type: String as PropType<StatePoint>,
      default: StatePoint.success,
    },
  },

  computed: {
    /** Классы */
    classes() {
      return {
        point: true,
        [this.state]: true,
      }
    },
  },

  render(): VNode {
    return (<span class={this.classes} />)
  },
})
