import router from '@/router'

import { UserStore } from '@/store/user'
import { RequestAPI } from '@/classes/Request'
import { Profile } from '@/types/API'
import { RoutePathsProfile } from '@/views/Profile/Types'
import { QueryParams } from '@/views/Profile/TelegramBot/Types'
import { RoutePaths } from '@/router/Types'

export class CheckTelegram {
  /**
   * Проверка телеграмма перед переходом к маршруту
   * @param path - маршрут
   */
  public static async checkToRoute(path: RoutePathsProfile | RoutePaths) {
    const userState = UserStore()

    if (!UserStore().isAuth()) {
      return
    }

    Boolean(userState.telegramBotId)

    if (userState.isActiveTelegram || Boolean(userState.telegramBotId())) {
      router.push(path)
      return
    }

    const response = await RequestAPI.errorHandler<Profile>(RequestAPI.get, '/profile')

    if (response) {
      userState.setTelegramId(response.data.content.athlete.telegram_id)
    }

    if (!userState.isActiveTelegram) {
      router.push(`${RoutePathsProfile.telegramBot}?${QueryParams.redirectBack}=${path}`)
    }
  }
}
