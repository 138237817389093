export enum UserLocalStorageNames {
  /** Жизнь токена */
  Expires = 'expiresToken',
  /** Идентификатор телеграмма */
  Token = 'token',
  /** Идентификатор телеграмма */
  TelegramBotId = 'telegramBotId',
  /** Кол-во показов баннеров на главной странице (тренировки). Необходимо, чтобы чередовать баннеры */
  CountShowTelegramBanners = 'countShowTelegramBanners',
  /** Баннеры телеграм закрыты по токену (указан токен пользователя) */
  TelegramBannersIsClosedByToken = 'telegramBannersIsClosedByToken'
}
