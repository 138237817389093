/* Функции */
import {
  defineComponent, PropType, VNode,
} from 'vue'

/** Типы */
import { defaultClasses, defaultProps, defaultStyles } from '@/components/VText/Types'

/* Стили */
import '@/components/VText/VTextHeading1/VTextHeading1.scss'

export default defineComponent({
  name: 'VTextHeading1',

  props: {
    ...defaultProps(),

    /** Тег */
    tag: {
      type: String as PropType<string>,
      default: 'h1',
    },
  },

  computed: {
    styles() {
      return {
        ...defaultStyles.call(this),
      }
    },

    classes() {
      return {
        ...defaultClasses.call(this),
        'text-h1': true,
      }
    },
  },

  render(): VNode {
    const nameComponent = this.tag

    return (
      <nameComponent onClick={this.onClick} class={this.classes} style={this.styles}>
        {this.$slots.default?.()}
      </nameComponent>
    )
  },
})
