/* Функции */
import { defineComponent, PropType, VNode } from 'vue'

/* Компоненты */
import VIcon from '@/components/VIcon/VIcon'
import VRow from '@/components/VRow/VRow'
import VTextHeading2 from '@/components/VText/VTextHeading2/VTextHeading2'

/* Стили */
import '@/components/VSlideUpSlideDown/VSlideUpSlideDown.scss'
import VTransition from '../VTransition/VTransition'

export default defineComponent({
  name: 'VSlideUpSlideDown',

  emits: {
    show(value: boolean) {
      return typeof value === 'boolean'
    },
  },

  data() {
    return {
      isOpen: false,
    }
  },

  props: {
    title: {
      type: String as PropType<string>,
      default: '',
    },
    isShow: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    id: {
      type: String as PropType<string>,
      required: true,
    },
  },

  computed: {
    classes() {
      return {
        'slide-up-slide-down': true,
        'is-open': this.isOpen,
      }
    },
  },

  methods: {
    toggle() {
      this.isOpen = !this.isOpen
      this.$emit('show', this.isOpen)
    },
  },

  watch: {
    isShow() {
      this.isOpen = this.isShow
    },
  },

  created() {
    this.isOpen = this.isShow
  },

  render(): VNode {
    return (
      <div class={this.classes} key={`${this.id}`}>
        <VRow
          class={'slide-up-slide-down__header'}
          onClick={this.toggle}
          justify={'space-between'}
          alignItems={'center'}
        >
          {this.$slots.title?.().length ? this.$slots.title() : <VTextHeading2 class={'slide-up-slide-down__title'}>{this.title}</VTextHeading2>}

          <VIcon class={'slide-up-slide-down__icon'} imageName={'arrow_bottom'} />
        </VRow>

        <div class={'slide-up-slide-down__container'}>
          <VTransition animationName={'slide'} mode={'default'}>
            {
              this.isOpen ? <div class={'slide-up-slide-down__container'} key={`${this.id}__container`}>{this.$slots.default?.()}</div> : null
            }
          </VTransition>
        </div>
      </div>
    )
  },
})
