import { RouterItemMeta } from '@/router/Types'

/**
 * Установить доступ к параметрам макета без авторизации
 * @param meta - Метаданные маршрута
 */
export const SetLayoutParamsAccessWithoutAuth = (meta: RouterItemMeta) => {
  if (!meta.layoutParams) {
    meta.layoutParams = {}
  }

  meta.layoutParams.isShowBackButton = false
  meta.layoutParams.isShowMenu = false
}
