/* Функции */
import {
  defineComponent, PropType, Transition, TransitionProps, VNode,
} from 'vue'

/* Типы */
import { TransitionDuration } from '@/types/animations'
import { AnimationName, AnimationNameValues } from '@/components/VTransition/Types'

/* Стили */
import '@/components/VTransition/VTransition.scss'

export default defineComponent({
  name: 'VTransition',

  props: {
    /** Имя анимации */
    animationName: {
      type: String as PropType<AnimationNameValues>,
      default: AnimationName.Fade,
    },
    /** Режим анимации */
    mode: {
      type: String as PropType<TransitionProps['mode']>,
      default: 'out-in',
    },
    /** Время анимации */
    duration: {
      type: Number as PropType<number>,
      default: TransitionDuration.base,
    },
  },

  render(): VNode {
    const { animationName, mode, duration } = this
    return (
      <Transition
        name={animationName}
        mode={mode}
        duration={duration}
      >
        {this.$slots.default?.()?.length ? this.$slots.default?.() : null}
      </Transition>
    )
  },
})
